import React from 'react';
import { Typography, Link } from '@mui/material';

const CookieInfo = () => {
  return (
    <>
      <Typography variant="h3">Cookie Information</Typography>
     <br/> <Typography>
       <b> What types of cookies do we use?</b>
        
      </Typography><br/>
      <Typography>
        
        We use four different types of cookies to improve your website experience: strictly necessary cookies, functional cookies, performance cookies, and behavioral/advertising third-party cookies.
      </Typography><br/>
      <Typography>
        <b>1. Strictly necessary cookies</b><br/>
        These cookies are essential for the operation of our websites. Without these cookies, parts of our websites would not function. For example, we use cookies so that we can more easily recognize already registered visitors and enable them to access premium content on our websites. Other examples are here session or shopping cart cookies, which are necessary for the proper functioning of the respective website.
      </Typography><br/>
      <Typography>
        <b>2. Analytical cookies</b><br/>
        With the help of these cookies, we monitor the performance of our websites and how users interact with them. These cookies give us information on providing better products and help us in detecting areas that need maintenance. Also, third parties such as Google Analytics can place cookies. However, the data collected will be controlled by us and should only help us better understand this website and help with its optimization.
      </Typography><br/>
      <Typography>
        <b>3. Functional cookies</b><br/>
        Functional cookies are used to store your preferences on our websites. An example would be storing your unique user ID (ID).
      </Typography><br/>
      <Typography>
        <b>4. Behavioral/advertising third-party cookies</b><br/>
        These cookies are used to display individually tailored advertising which we believe is relevant to you. In addition, some of our partners use these cookies on our websites to display an individually tailored advertisement for you when visiting other websites.
      </Typography>
      
      <Typography>
        Online advertising behavior is a method of providing ads tailored to your interests on the websites you visit. The selection is made by compiling common interests based on previous online activities. When visiting a website that matches these interests, the appropriate advertising will be displayed to you.
      </Typography>

      <Typography>
        For more information on our Cookie Policy, please <Link href="/cookiepolicy">click here</Link>.
      </Typography><br/>
    </>
  );
};

export default CookieInfo;