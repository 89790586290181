import { DatePicker, LocalizationProvider } from "@mui/lab";
import { Box, Button, Card, CardContent, CardHeader, Container, FormControl, Grid, Input, InputLabel, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";

import { useState } from "react";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { CreateUser, CreateUserCloud, UpdateUser } from "../services/graphQlService";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';
import PricingModal from "../components/PricingModal";
import { InfoOutlined } from "@mui/icons-material";
import TagManager from "react-gtm-module";

const RegistrationForm = (props) => {
  const navigate = useNavigate();
  console.log('props', props)
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [birthdate, setBirthdate] = useState(null);
    const [plan, setPlan] = useState("");
    const [referral, setReferral] = useState("");
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [snackbarOpen, setsnackbarOpen] = useState(false);
    const [snackbarmessage, setsnackbarmessage] = useState('');
    const [pricingModalOpen, setPricingModalOpen] = useState(false);
  
    const handleSubmit = async () => {
      const userData = {
        name,
        birthdate,
        plan,
        referral,
      };
      /* userData.birthdate = Date.now() */
      if(!userData.name || !userData.birthdate || !userData.plan) {
        setsnackbarmessage('Please fill out all fields')
        setsnackbarOpen(true)
        return
      }

      let userId = props.user.username
      console.log(props.user)
      let email = props.user.attributes.email
      let finalObj = {id: userId, userId: userId, email:email, name: userData.name, birthdate: String(userData.birthdate), subscriptionLevel: userData.plan, referral: userData.referral, /* subscriptionLevelUpdated: String(Date.now()) */}
      // Pass userData object to the next function
      
      /* let updateUser = await CreateUser(finalObj) */
      finalObj.referal = props.referal
      console.log('finalObj', finalObj)
      setsnackbarOpen(true)
      setsnackbarmessage('Registration sent successfully!')
      let updateUser = await CreateUserCloud(finalObj)
      

      ReactGA.event({
        category: "engagement",
        label: finalObj.id,
        action: "sign_up",
      });
      /* if(window.dataLayer){
        window.dataLayer.push({
          category: "engagement",
          event: "Sign_Up",
        })
      } */
      /* TagManager.dataLayer({
        dataLayer: {
          event: 'Sign_Up',
          category: 'engagement',
          label: finalObj.id
        }
      }) */
     /*  window.dataLayer({
        event: 'Sign_Up',
        category: 'engagement',
        label: finalObj.id
      }) */
      /* 
      TagManager.dataLayer({
        event: 'Sign_Up',
        category: 'engagement',
        label: finalObj.id
      })
      console.log(updateUser); */
      navigate("/editor", { replace: true })
      props.updateReg(true)
    };

    const handleClosePricing = () => {
      setPricingModalOpen(false)
    }
  
    return (
      <Container  maxWidth="sm" >
        <Card>
          <CardHeader title="Registration Form" />
       {/*    <CardContent>
        <Typography variant="h7" align="center" gutterBottom style={{marginBottom:'1em'}}>
          Please fill out the following form to register for our service.
        </Typography>
        </CardContent> */}
          <CardContent>
            
        
        <TextField
          fullWidth
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ marginBottom: "1em" }}
        />
      
        <FormControl  variant="outlined" style={{ marginBottom: "1em" }}>

          <InputLabel htmlFor="outlined-required">Birthdate</InputLabel>
           {/*  <TextField
          fullWidth
          label="Birthdate (dd/mm/yyyy)"
          value={birthdate}
          onChange={(e) => setBirthdate(e.target.value)}
          style={{ marginBottom: "1em" }}
        /> */}
        <Input 
              type="date"
                required
                style={{ marginLeft: "1em" }}
                id="outlined-required"
                label="Birthdate"
                value={birthdate}
                onChange={e => setBirthdate(e.nativeEvent.target.value)}
                autoComplete="off"
              />
        </FormControl>
        {/* <TextField
          fullWidth
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginBottom: "1em" }}
        /> */}
        {/* <FormControl fullWidth variant="outlined" style={{ marginBottom: "1em" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Birthdate"
            value={birthdate}
            onChange={(newValue) => {
              setBirthdate(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} fullWidth style={{ marginBottom: "1em" }} />
            )}
          />
        </LocalizationProvider>
        </FormControl> */}
        <Grid container spacing={2} style={{ marginBottom: "1em" }} alignItems={"center"} alignContent={"center"}>
          <Grid item xs={12} sm={1}>
            
            <InfoOutlined style={{color:"blue"}} onClick={() => setPricingModalOpen(true)} />
            </Grid>
          <Grid item xs={12} sm={11}>
        <FormControl fullWidth variant="outlined" style={{ marginBottom: "1em" }}>
          <Select
            value={plan}
            onChange={(e) => setPlan(e.target.value)}
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select a Plan
            </MenuItem>
            <MenuItem value={"free"}>Free</MenuItem>
            <MenuItem value={"solo"}>Solo {/* (9$/mt) */}</MenuItem>
            <MenuItem value={"lift"}>Lift {/* (29$/mt) */}</MenuItem>
            <MenuItem value={"fly"}>Fly {/* (49$/mt) */}</MenuItem>
          </Select>
        </FormControl>
        </Grid>
        </Grid>
        <TextField
          fullWidth
          label="How did you find us?"
          value={referral}
          onChange={(e) => setReferral(e.target.value)}
          style={{ marginBottom: "1em" }}
        />
        <Box display="flex" justifyContent="center">
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
        </CardContent>
        </Card>
        <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setsnackbarOpen(false)} message={snackbarmessage} />
        <PricingModal open={pricingModalOpen} onClose={handleClosePricing} />
      </Container>
    );
  };
  
  export default RegistrationForm /* This component used 1080 tokens in total. Creation costs on GPT4: 0.05004 $ and on GPT3.5Turbo 0.00216 $ */