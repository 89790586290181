import { Autocomplete, Box, Button, Card, CardContent, CardHeader, Checkbox, Chip, FormControlLabel, Menu, MenuItem, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";

const StyledCard = styled(Card)({
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgb(17, 25, 39)",
    boxShadow: "rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px",
    borderRadius: "20px",
    overflow: "hidden",
    backgroundImage: "none",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    fontSize: "1rem",
    fontWeight: "400",
    lineHeight: "1.5",
    fontFamily: "'Inter',-apple-system,BlinkMacSystemFont,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji'",
  });

  const DropdownButton = styled(Button)({
    WebkitTextSizeAdjust: '100%',
    WebkitFontSmoothing: 'antialiased',
    fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    fontSize: '0.875rem',
    lineHeight: '1.75',
    minWidth: '64px',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'inherit',
    borderRadius: '12px',
    textTransform: 'none',
    padding: '9px 16px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    WebkitTapHighlightColor: 'transparent',
    backgroundColor: 'transparent',
    outline: '0px',
    border: '0px currentcolor',
    margin: '0px',
    cursor: 'pointer',
    userSelect: 'none',
    verticalAlign: 'middle',
    appearance: 'none',
    textDecoration: 'none',
    fontWeight: 600
  });
  
  const DropdownModal = (props) => {
      /* console.log(props) */
    const [anchorEl, setAnchorEl] = useState(null);
  let filters = props.props.filters[0].split(",")
  /* console.log(filters) */
  let key = props.props.key
    const handleClick = (event) => {
        
        console.log(event.currentTarget.innerText)
      setAnchorEl(event.currentTarget);
      
    };

    const handleClickFilter = (event) => {
        console.log(event.currentTarget.innerText)
        handleClose()
    }
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <div>
        <DropdownButton type="button" onClick={handleClick}>
          {key} 
        </DropdownButton>
        {filters.length > 0 && <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ marginTop: '1em' }}
        >
            
        {filters.map(el => {
            return(
            <MenuItem onClick={handleClickFilter}>{el}</MenuItem>
            )
        }
        )
    }
          
       
        </Menu>}
      </div>
    );
  };
  
  const FilterWithChips = (props) => {
    const [checked, setChecked] = useState(false);
    const [excludedTags, setExcludedTags] = useState([])
    const [includedTags, setIncludedTags] = useState([])
    const [displayedTagsNo, setdisplayedTagsNo] = useState(7)

    const tagsToFilter = props.tags.map(el => el.title)

    const handleCheckChange = (event) => {
      setChecked(event.target.checked);
    };

    const handleShowMoreTags = () => {
        setdisplayedTagsNo(displayedTagsNo + 7)
    }

    const handleShowLessTags = () => {
        setdisplayedTagsNo(7)
    }

   /*  useEffect(() => {
      let init = [{title: 'hoover', count:3}, {title: 'Card', count: 4}]
      setIncludedTags(init)
      onInclude(init[0])
      
      
    }, []) */

    const ShowMoreOrLessTags = () => {
        console.log(props.tags.length)
        console.log(displayedTagsNo)
        return(
            <>
        {props.tags.length > displayedTagsNo && <Button onClick={handleShowMoreTags}>Show more</Button>}
        {props.tags.length < displayedTagsNo && <Button onClick={handleShowLessTags}>Show less</Button>}
              </>
        )
    }
    

    const onDeleteIncluded = (event) => {
        console.log(event)
        let posToExclude = includedTags.findIndex(el => el.title === event.title)
        let tag = includedTags[posToExclude]
        /* console.log(posToExclude) */
        console.log(includedTags)
        let newInclu = includedTags.filter(el => el.title !== event.title)
        console.log(newInclu)
        
        let updatedTagList = [tag, ...props.tags]
        /* console.log(updatedTagList) */
        console.log(updatedTagList, newInclu)
        props.updateLibrary({updatedTagList: updatedTagList, included: [...newInclu]})
        setIncludedTags(newInclu)
    }

    const onInclude = (event) => {
        console.log({event})
        console.log(props.tags)
        let posToInclude = props.tags.findIndex(el => el.title === event.title)
        /* console.log(posToInclude) */
        let entry = props.tags[posToInclude]
        /* console.log(entry) */
        let newTags = [...includedTags, entry]
        let updatedTagList = props.tags.filter(el => el.title !== event.title)
        console.log(updatedTagList, newTags)
        props.updateLibrary({updatedTagList: updatedTagList, included: newTags})
        setIncludedTags(newTags)
    }
  
    const chipsData = [
      { label: "Type", value: "Freelance" },
      { label: "Type", value: "Internship" },
      { label: "Level", value: "Novice" },
      { label: "Location", value: "Asia" },
      { label: "Role", value: "Web Developer" },
    ];
  
    const buttonsData = [{key: "Startup", filters: ['pricing, checkout, leadform']}, {key: "Level", filters: ['Beginner, Advanced, Expert']},{key: "Role", filters: ['Student, Solo, Developer']}];
  
    const chipElements = props.tags.filter((c, index) => index < displayedTagsNo).map((chip, index) => (
      <Chip
        key={index}
        label={
          <Box>
            <span>{chip.title}</span>: {chip.count}
          </Box>
        }
        variant="outlined"
        onClick={() => {onInclude({title: chip.title})}}
        /* onDelete={() => {onDelete({title: chip.title})}} */
        style={{ marginRight: "1em", marginBottom: "1em" }}
      />
    ));
    
    const chipElementsIncluded =  includedTags.map((chip, index) => (
      <Chip
        key={index}
        label={
          <Box>
            <span>{chip.title}</span>: {chip.count}
          </Box>
        }
        variant="outlined"
        
        onDelete={() => {onDeleteIncluded({title: chip.title})}}
        style={{ marginRight: "1em", marginBottom: "1em" }}
      />
    ));
  
    const buttonElements = buttonsData.map((button) => (
      <>
     
      <DropdownModal props={button} />
      </>
    ));

    const handleTagSelect = (event, component) => {
        console.log(event)
        if(event != null){
        onInclude({title: event})
        }
    }
    
 
  
  
  
    return (
      <StyledCard>
        <CardHeader title="Tag Selection" />
        <CardContent>
          <Box display="flex" flexDirection="column">
            <Box display="flex" width={300}>
            <Autocomplete
            style={{width: '100%', margin:"1em"}}
        /* id={`${cardIndex}-component-select`} */
        options={tagsToFilter}
        getOptionLabel={(option) => option}
        /* value={includedTags || null} */
        onChange={(event, component) =>
          handleTagSelect(component)
        }
        renderInput={(params) => (
          <TextField {...params} label="Search tag" variant="outlined" />
        )}
    />
            </Box>
            {includedTags && includedTags.length > 0 && <Card display="flex" style={{backgroundColor: 'lightgreen', padding:'1em', margin:'1em'}}>
                <Typography variant="h6" style={{margin: '1em'}} >Included</Typography>
              {includedTags.length > 0 && chipElementsIncluded}
            </Card>}
            <Box display="flex" flexWrap="wrap">
              {chipElements}
            </Box>
            <ShowMoreOrLessTags />
            
          
          {/*   <Card display="flex" flexWrap="wrap" style={{backgroundColor: 'red', padding:'1em', margin:'1em'}}>
                <Typography variant="h6" style={{margin: '1em'}}>Excluded</Typography>
              {chipElementsExcluded}
            </Card> */}
           
         {/*    <Box display="flex" alignItems="center">
              {buttonElements}
              <Box flexGrow={1} />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckChange}
                    color="primary"
                  />
                }
                label="In network"
                style={{ marginBottom: "1em" }}
              />
            </Box> */}
          </Box>
        </CardContent>
      </StyledCard>
    );
  };
  
  export default FilterWithChips