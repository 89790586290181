import React, { useState } from "react";
import { Stepper, Step, StepLabel, Button, TextField } from "@mui/material";
import styled from "@emotion/styled";
import CodeMirror from "@uiw/react-codemirror";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const CodeEditorContainer = styled("div")`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;

const PreviewContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const LivePreview = styled("div")`
  flex-grow: 1;
  border: 1px solid #ccc;
`;

const StepThree = () => {
  const [commands, setCommands] = useState("");

  const handleCommandsChange = (event) => {
    setCommands(event.target.value);
  };

  const handleSend = () => {
    // Implement the send button logic here
  };

  return (
    <CodeEditorContainer>
      <CodeMirror />
      <PreviewContainer>
        <LivePreview />
        <TextField value={commands} onChange={handleCommandsChange} />
        <Button onClick={handleSend}>Send</Button>
      </PreviewContainer>
    </CodeEditorContainer>
  );
};

const ThreeStepProcess = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Container>
      <Stepper activeStep={activeStep}>
        <Step>
          <StepLabel>Select Framework</StepLabel>
        </Step>
        <Step>
          <StepLabel>Enter HTML Code</StepLabel>
        </Step>
        <Step>
          <StepLabel>Adjust Component</StepLabel>
        </Step>
      </Stepper>
      {activeStep === 0 && <div>Step 1 Content</div>}
      {activeStep === 1 && <div>Step 2 Content</div>}
      {activeStep === 2 && <StepThree />}
      <Button disabled={activeStep === 0} onClick={handleBack}>
        Back
      </Button>
      <Button onClick={handleNext}>
        {activeStep === 2 ? "Finish" : "Next"}
      </Button>
    </Container>
  );
};

export default ThreeStepProcess;