

import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';


const CountDownTimer = () => {
  const [time, setTime] = useState(99);

  useEffect(() => {
    let timer;
    if (time > 0) {
      timer = setTimeout(() => setTime(time - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [time]);

  const StyledTypography = styled(Typography)({
    marginTop: '1em',
  });

  return (
    <Box>
      <StyledTypography variant="h9" align="center">
         {time}s
      </StyledTypography>
    </Box>
  );
};


export default CountDownTimer