

import { ScrollView, Text } from "@aws-amplify/ui-react";
import { Card, Grid, Modal } from "@mui/material";



export function Impressum(props) {

    return(
        <>
        
        
        <ScrollView style={{height: window.innerHeight-100,  width: "100%" /* , backgroundColor: "black" */}}>
        <Card style={{margin:"1rem"}} >
        <Grid container justifyContent={'center'}>
<Grid item xs={12} md={6}>
<img  src="https://reactevolveimg.s3.eu-central-1.amazonaws.com/imprint1.jpg"></img>
        </Grid>
</Grid>
        
        
        
            <Text style={{color: "Black", fontSize:25, marginBottom: "1rem"}}> Legal Notice</Text>
            
<Text style={{color: "Black", fontSize:20}}>Copyright</Text>
<Text style={{color: "Black", fontSize:12, margin: "1rem"}}>
Our websites are protected by copyright. Any use for commercial or political purposes, in whole, in part or in a modified form, requires the prior written consent of SchooIT sole proprietorship. Printing, copying or downloading files for private use only is permitted.
</Text>
<br/>
<Text style={{color: "Black", fontSize:20}}>Warranty and liability</Text>
<Text style={{color: "Black", fontSize:12, margin: "1rem"}}>
The information on our website is created with the utmost care.
At the same time, SchooIT endeavors to ensure that the information on the website is up to date. Despite careful compilation of the websites, no liability can be assumed for the correctness and completeness of the information provided. The content provided on the website is used at the sole risk of the user. We reserve the right to make changes at any time.
</Text>
<br/>


<br/>
<Text style={{color: "Black", fontSize:20}}>Privacy Policy</Text>
<Text style={{color: "Black", fontSize:12, margin: "1rem"}}>
It is a matter of course for us that we contribute responsibly
handle your personal data. We would therefore like to inform you that your personal data will be stored in automated files and processed and used accordingly, in compliance with the applicable national and European data protection regulations, to process your order. Your files will only be stored by us and processed for advertising purposes within our company if you give your express consent to this. For this purpose, you have the opportunity to give your declaration of consent at all points on our website where personal data is accessed. You can revoke your consent at any later time by sending us an e-mail or post. In general, emendo does not require any personal data in order to be able to use the website. This means that the website can be accessed without separate registration.
</Text>
<br/>
<Text style={{color: "Black", fontSize:20}}>No transfer to third parties</Text>

<Text style={{color: "Black", fontSize:12, margin: "1rem"}}>
SchooIT assures you that your data will be handled confidential. A transfer to third parties, for example through sale, rental, exchange does not take place.
</Text>

<Text style={{color: "Black", fontSize:12, margin: "1rem"}}>
                Platform of the EU Commission for online dispute resolution:
                http://ec.europa.eu/consumers/odr/.
                </Text>           
                <br/>
            <br/>
            <Text style={{color: "Black", fontSize:20}}>Social Media and other online presence</Text>
            
        <Text style={{color: "Black", fontSize:12, margin: "1rem"}}>
        This imprint also applies to the following social media sites and online profiles:
      
      https://twitter.com/ReactEvolve
      </Text>
         <br/>

            
            <Text style={{color: "Black", fontSize:20}}>Copyright and Trademark Rights</Text>
            <Text style={{color: "Black", fontSize:12, margin: "1rem"}}>
            All content presented on this website, such as texts, photographs, graphics, brands and trademarks are protected by the respective industrial property rights (copyrights, trademark rights). The use, duplication, etc. are subject to our rights or the rights of the respective authors or rights managers.

          </Text>

                </Card>
                </ScrollView>
         
                </>
    )
}