

import React from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
} from '@mui/material';
import styled from '@emotion/styled';
import { width } from '@mui/system';
import ReactGa from 'react-ga4';

const MobileVersionMarketing = () => {

    const mobileButtonClick = () => {
        alert('Please switch to a desktop or laptop device to use ReactEvolve™ AISuite');
    
        ReactGa.event({
            category: 'Mobile Version',
            action: 'Twitter Button Clicked',
        })
        /* if(window.dataLayer){
          window.dataLayer.push({
            category: "engagement",
            event: "TwitterButtonMobileVersionClicked",
            label: 'Mobile Version',
          })
        } */
        }; 

        
            
            
          

    const TopSection = () => {
      


const StyledContainer = styled(Container)({
    marginBottom: '1em',
  /* padding: '16px 24px', */
  
  
  
});

const StyledBox = styled(Box)({
  marginTop: '1em',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign:'center'
});

const StyledGrid = styled(Grid)({
    
});

const StyledTypography = styled(Typography)({
  textAlign: 'center',
  color: '#fff'
});

const ReproduceAsMobileFriendlyBanner = () => {
  const headerText = 'Change The Game';
  const subHeaderText =
    'Fast-track your React Development by using ReactEvolve™ AISuite';
  const buttonText = 'Follow Us On Twitter';
  const arrowDownImageUrl =
    'https://mui.com/static/themes/onepirate/productHeroArrowDown.png';
  const backgroundImgUrl =
    'https://reactevolveimg.s3.eu-central-1.amazonaws.com/background2169.jpg';

  return (
    <StyledContainer>
      
      <StyledGrid container direction="column">
        <Grid item xs={12}>
          <StyledBox
            style={{
              backgroundImage: `url(${backgroundImgUrl})`,
              backgroundColor: '#000',
              borderRadius: '10px',
              color: '#fff',
              padding: '20px',
              opacity: '.99'
            }}
          >
            <StyledTypography variant="h4" style={{ fontWeight: '500' }}>
              {headerText}
            </StyledTypography>
            <StyledTypography variant="h5" style={{ color: '#ff0' }}>
              {subHeaderText}
            </StyledTypography>
            <StyledBox>
              <img
                src={arrowDownImageUrl}
                alt="arrow down"
                style={{ width: '20px' }}
              />
            </StyledBox>
            <StyledBox>
              <Button href={'https://twitter.com/ReactEvolve'} variant="contained" color="secondary" size="large" onClick={mobileButtonClick}>
                {buttonText}
              </Button>
            </StyledBox>
            <Typography variant="body2" align="center" style={{color:'white', fontSize: "1em"}}>
            <b>Desktop or Laptop required, no mobile version available</b>
            </Typography>
          </StyledBox>
        </Grid>
      </StyledGrid>
      {/* <StyledBox>
      <img
          src={backgroundImgUrl}
          alt="increase priority"
          style={{ display: 'flex', width: '100%', height:'200px', marginBottom:'1em', marginTop:'1em', borderRadius: '10px',}}
        />
      </StyledBox> */}
    </StyledContainer>
  );
};


return(
    <ReproduceAsMobileFriendlyBanner />
)
    }
    const MiddleSection = () => {
      


const StyledContainer = styled(Container)({
  
  width: '100%',
    marginBottom: '1em',
  paddingLeft: 16,
  paddingRight: 16,
});

const StyledCard = styled(Card)({
  marginTop: '1em',
  marginBottom: '1em',
});

const ReproduceAsMobileFriendly = () => {
  const title = 'Empowering React Developers with AI';
  const description = `ReactEvolve™ is an innovative company that aims to revolutionize the way React developers code by providing them with cutting-edge AI tools. Our vision is to create a future where AI-powered tools seamlessly integrate with the developers' workflow, inspiring them to work more efficiently and accurately while saving time.

  At ReactEvolve™, our mission is to empower both aspiring and experienced React developers by offering them AI tools that streamline their coding process, reduce errors, and ultimately, help them develop high-quality applications faster. With the ReactEvolve™ AiSuite, we harness the power of artificial intelligence to transform the way developers work and redefine the limits of what they can achieve.`;

  const cardData = [
    {
      title: 'BuildAI',
      description: `ReactEvolve™ Builder is an AI-powered tool designed to inspire and assist React developers in creating robust and efficient code. By understanding the developer's intent and the desired output, ReactEvolve™ Builder can suggest optimized components and variable structures, significantly reducing the time spent on manual coding.`,
    },
    // ...
  ];

  return (
    <StyledContainer>
      <Box>
        <br />
        <Typography variant="h4" align="center" gutterBottom>
          ReactEvolve™ AISuite
        </Typography>
        <Grid container spacing={4}>
          {cardData.map((card, index) => (
            <Grid
              item
              container
              direction="column"
              xs={12}
              sm={4}
              key={index}
            >
              <Box>
                <StyledCard>
                  <CardContent>
                    <Typography variant="h5">{card.title}</Typography>
                    <Typography variant="body1">{card.description}</Typography>
                  </CardContent>
                </StyledCard>
              </Box>
            </Grid>
          ))}
        </Grid>
        
        <Typography variant="h4" align="center" gutterBottom>
          {title}
        </Typography>
        <Card>
        <CardContent>
          <Typography variant="body1" gutterBottom>
            {description}
          </Typography>
        </CardContent>
        </Card>
      </Box>
    </StyledContainer>
  );
};


return(<ReproduceAsMobileFriendly />)
    }
    const BottomSection = () => {
  

const StyledBox = styled(Box)({
  
  
  backgroundColor: '#000',
  borderRadius: '10px',
  color: '#fff',
  padding: '20px',
  opacity: '.75',
});

const ReproduceAsMobileFriendlyEndOfPage = () => {
  const backgroundSrc = 'background.png';
  const arrowDownSrc = 'https://mui.com/static/themes/onepirate/productHeroArrowDown.png';

  return (
    <>
      
      <Container maxWidth="lg" className="MuiContainer-root MuiContainer-maxWidthLg css-1qsxih2">
        {/* <Box className="MuiBox-root css-0">
          <img src={backgroundSrc} alt="increase priority" style={{ display: 'flex', width: '100%' , height:'200px', marginBottom:'1em', marginTop:'1em', borderRadius: '10px'}} />
        </Box> */}
        <Grid container direction="column" className="MuiGrid-root MuiGrid-container MuiGrid-direction-xs-column css-28ln3p">
          <Grid item xs={12} className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 css-15j76c0">
            <StyledBox 
                style={{
                  backgroundImage: `url(${backgroundSrc})`,
                  backgroundColor: '#000',
                  borderRadius: '10px',
                  color: '#fff',
                  padding: '20px',
                  opacity: '.95'
                }}
            >
              <Typography variant="h4" align="center" style={{ color: '#fff', fontWeight: 700 }}>
                Speed up your creativity
              </Typography>
              <Typography variant="h6" align="center" style={{ color: '#ff0' }}>
                Reduce redundant coding tasks by using ReactEvolve™ Library &amp; AISuite
              </Typography>
              <Box className="MuiBox-root css-3oibxi">
                <Button href={'https://twitter.com/ReactEvolve'} variant="contained" size="large" color="secondary" onClick={mobileButtonClick}>
                  Desktop or Laptop required
                </Button>
              </Box>
              <Typography style={{fontSize: "1em"}} variant="body2" align="center" className="MuiTypography-root MuiTypography-body2 MuiTypography-alignCenter css-62dh81">
                No mobile version available
                <Box className="MuiBox-root css-4sywta">
                  <img src={arrowDownSrc} alt="arrow down" style={{ width: '20px', transform: 'rotate(180deg)' }} />
                </Box>
              </Typography>
            </StyledBox>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};


return(<ReproduceAsMobileFriendlyEndOfPage />)
    }

    return(
        <Container style={{marginBottom:"1em"}}>
            <TopSection />
            <MiddleSection />
            <BottomSection />
        </Container>
    )
}

export default MobileVersionMarketing
