import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material'
import { Check as CheckIcon, CheckCircle as CheckCircleIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@mui/icons-material'
import styled from "@emotion/styled";

const Container = styled(Paper)`
  background-color: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  padding: 16px;
  margin-bottom: 16px;
  border-top: 6px solid green;
`;

const SampleComponent = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item md={2}>
          <Box display="flex" flexDirection="column">
            <img
              alt="Anbieter Logo"
              src="https://cdn.zahnzusatzversicherungen-vergleich.com/content/anbieterlogos/Logo_Barmenia.png"
            />
            <Box display="flex" flexDirection="column" alignItems="center">
              <CircularProgress
                variant="determinate"
                value={99}
                color="primary"
                size="100%"
                thickness={6}
              />
              <Typography variant="h5">1,0</Typography>
            </Box>
            <Box>
              <Typography variant="h5">Sehr gut</Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item md={10}>
          <Grid container spacing={2}>
            <Grid item md={9}>
              <Typography variant="h6">
                Barmenia - Mehr Zahn 100 + ZV Bonus
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Chip label="Bester Tarif" variant="outlined" />
            </Grid>
          </Grid>
          <Box display="flex" flexDirection="row">
            <Grid container spacing={2}>
              <Grid item md={5}>
                {/* Add component structure and logic here */}
              </Grid>
              <Grid item md={7}>
                {/* Add component structure and logic here */}
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" flexDirection="row">
            <Grid container spacing={2}>
              <Grid item md={4}>
                {/* Add component structure and logic here */}
              </Grid>
              <Grid item md={4}>
                <Button variant="outlined" color="primary" fullWidth>
                  Tarifdetails
                </Button>
              </Grid>
              <Grid item md={4}>
                <Button variant="contained" color="primary" fullWidth>
                  Angebot erstellen
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SampleComponent;