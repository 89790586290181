import React, { useEffect, useState } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import NewEditor from "./NewEditor";
import { CreateNewComponent, CreateSalesPitch } from "../../services/graphQlService";
import Test from "./test";
import ComponentTreeBuilder from "./componentTreeBuilder";

const frameworks = ["Material-UI", "Next.js", "Other"];

const StepperComplete = (props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedFramework, setSelectedFramework] = useState("");
  const [code, setCode] = useState(props.code || "");
  const [newAnswer, setNewAnswer] = useState(props.code || "");
  const [currentCommand, setCurrentCommand] = useState({});
  const [requestFailed, setRequestFailed] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChange = (event) => {
    setSelectedFramework(event.target.value);
  };

  const handleCodeChange = (newCode) => {
    setCode(newCode);
    console.log(newCode);
  };

  const handleCommandChange = async(newCommand) => {
    
    console.log(newCommand.selectedOption);
    if(newCommand.text){
      let currentCode = code
      let currentRender = ""
     if(newCommand.selectedOption != "Combined Components" && code){
      let posRender = code.indexOf("render")
      console.log(posRender)
      if(posRender > -1){
       currentCode = code.substring(0, posRender)
       let remainder = code.substring(posRender, code.length)
       let posEndRender = remainder.indexOf(")")
       currentRender = remainder.substring(posRender, posEndRender)
      }
     }
     if(newCommand.codeIteration){
      currentCode = newCommand.codeIteration
      }
     
      let obj = {currentCode: currentCode, componentName: newCommand.componentName, adjustment: newCommand.adjustment, cssContent:newCommand.cssContent, newCommand: newCommand.text, currentRender: currentRender, selectedOption: newCommand.selectedOption, htmlContent: newCommand.htmlContent, size: newCommand.size}
      setCurrentCommand(newCommand);
      console.log(obj);
      let rescode = await CreateNewComponent(obj)
      
        if(rescode === "NO CREDITS"){
          setRequestFailed(true)
          setRequestMessage("You have no credits left. Please purchase more credits.")
        }
        console.log(rescode)
        
        if(rescode.value.data.onUpdateComponents.newComponent){
          console.log(rescode.value.data.onUpdateComponents.newComponent)
        setCode(rescode.value.data.onUpdateComponents.newComponent)
        setNewAnswer(rescode.value.data.onUpdateComponents.newComponent)
       /*  if(activeStep == 1){
          handleNext()
        } */
        }

    }
    
  };

  const handleUpdateCode = (newCode) => {
    console.log(newCode);
    
  }

 

  return (
    <Box sx={{ width: "100%" }}>
     {/*  <Stepper activeStep={activeStep} alternativeLabel>
      {props.variant === "Editor" &&  <Step>
          <StepLabel>ComponentAI</StepLabel>
        </Step>}
        {props.variant === "Builder" &&  <Step>
          <StepLabel>Component StackerAI</StepLabel>
        </Step>}
      </Stepper> */}
      <div>
       {/*  {activeStep === 3 ? (
          <div>
            <Typography>Step Process completed</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div> */}
            {props.variant === "Editor" && 
            <>
              <NewEditor requestFailed={requestFailed} user={props.user} code={code} newAnswer={newAnswer} onCommandChange={handleCommandChange} onCodeChange={handleCodeChange} onCodeUpdate={handleUpdateCode} />
              {/* <FormControl fullWidth variant="outlined">
                <InputLabel id="framework-select-label">Framework</InputLabel>
                <Select
                  labelId="framework-select-label"
                  id="framework-select"
                  value={selectedFramework}
                  onChange={handleChange}
                  label="Framework"
                >
                  {frameworks.map((framework, index) => (
                    <MenuItem key={index} value={framework}>
                      {framework}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              </>
            }
            {props.variant === "Builder" && 
              <ComponentTreeBuilder user={props.user} onCommandChange={handleCommandChange} onCodeChange={handleCodeChange} />
            }
           {/*  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === 2 ? "Finish" : "Next"}
              </Button>
            </Box>
            
          </div> */}
        
      </div>
    </Box>
  );
};

export default StepperComplete;