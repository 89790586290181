import React, { useContext, useEffect, useState } from "react";
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";

import {Card, CardContent, CardHeader, CardMedia, CircularProgress, Grid, ListItem, TableRow, Typography, Select, Modal, MenuItem, FormControl, Dialog, DialogContent, Button} from '@mui/material'
import ReactGA from "react-ga4";
import {  UserLoggedIN } from "../services/graphQlService";



import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { Image, View } from "@aws-amplify/ui-react";
import ChangingProgressProvider from "../services/ChangingProgressProvider";
import TagManager from "react-gtm-module";


    // This values are the props in the UI
    let amount = 0;
    /* let amountIsSet = true */
    let actions
    const style = {"layout":"vertical"};
    const currency = "CHF";
    const progressValues = Array.from(Array(100).keys())


// Custom component to wrap the PayPalButtons and handle currency changes
const ButtonWrapper = ( {currency, showSpinner, user}: {currency:any, showSpinner:any, user:string} ) => {
    console.log(user)
    
    // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
    // This is the main reason to wrap the PayPalButtons in a new component
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const [spinnerState, setSpinnerState] = useState(false)
    
    let progress = 0
    

     function updateCredits(){
        setSpinnerState(true)
        
        
                setTimeout(function () {
                  console.log('succces, user gets forwarded')
                  window.location.reload()
                  setSpinnerState(false)
                  
                }, 40000)
                
                
                /* setSpinnerState(false) */
                /* history.replace("/profil") */
      }

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
    }, [currency, showSpinner]);

    
    return (<>
            {/* { (spinnerState && isPending) && <div className="spinner" /> } */}
            {/* <Button onClick={() => {window.location.href = "/profile"}}>Start Spinner</Button> */}
            <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[amount, currency, style]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: currency,
                                        value: amount,
                                        
                                    },
                                    "reference_id": user
                                },
                            ],

                        })
                        .then((orderId) => {
                            // Your code here after create the order
                            return orderId;
                        });
                }}
                onApprove={function (data, actions:any) {
                    
                    return actions.order.capture().then(function () {
                        // Your code here after capture the order
                        console.log(data, actions)
                        updateCredits()
               
                        ReactGA.event({
                            category: "ecommerce",
                            label: "Success " + amount + " " + currency,
                            action: "purchase",
                          });
                          /* if(window.dataLayer){
                            window.dataLayer.push({
                              category: "ecommerce",
                              event: "purchase",
                              label: "Success " + amount + " " + currency,
                            })
                          } */
                          /* TagManager.dataLayer({
                            category: "ecommerce",
                            label: "Success " + amount + " " + currency,
                            event: "purchase",
                          }); */
                    });
                
                }}
            />
            
            
           
                     <Modal open={spinnerState} onClose={() => {setSpinnerState(false)}} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>   
                     <Card>
                            <CardHeader title="Loading Credits" />
                
                <div style={{maxWidth:"50%", maxHeight: 200, position: 'relative', left: '50%', top: '25%',
                        transform: 'translate(-50%, -50%)', marginTop:'7em', marginBottom:"0em"}}>
                            
                                
                                    {/* <CircularProgressbar value={progress} text={`${progress}%`} />   */}
                                    {/* <View textAlign="center" padding={0}>
                                    <Image
                                    alt="Sauberness logo"
                                    height={200}
                                    src="https://reactevolveimg.s3.eu-central-1.amazonaws.com/ReactEvolveAi.png"
                                    />
                                    </View> */}
                            
                            
                                   
                            <Typography style={{marginBottom:"1em"}}>Loading Credits... <br/>this process can take up to 40 Seconds. </Typography>
                            
       
                              <ChangingProgressProvider values={progressValues}>
                                    {(percentage:any) => (
                            <CircularProgressbar value={percentage} text={`${percentage}%`} />
                            
                            )}
                        </ChangingProgressProvider>
                        </div> 
                      
                 
                          
                          <CardContent>
                              
                          
                          <Typography style={{marginBottom:'1em'}}>Thank you for your patience. </Typography>
                          </CardContent>
                         
                          </Card>   
                         
                      </Modal>
                                 

                    
                      
        </>
    );
}



export default function PaypalButtonApp(props) {


    const [user1, setUser1] = useState('')
    const [amountIsSet, setamountIsSet] = useState(true)
    amount = props.amount

    

   

   /*  useEffect(() => {
        console.log(props.modalState)
        if(!props.modalState){
            setamountIsSet(false)
            amount = ''
            setUser1('')
            console.log('modal closed')
        }
    }, [props.modalState]) */

    useEffect(() => {
        changeAmount(props.amount)
    }, [])
    

    async function changeAmount(e){
        /* setamountIsSet(false) */
        console.log(e)
        
        amount = e
        const uid = await UserLoggedIN()
        
        console.log(uid)
        setUser1(uid.username)
        setamountIsSet(true)
        
    }

	return (
        < >
             <div id="paypalSelection" style={{
                                      minWidth: "100%",
                                      minHeight: "200"}}>
            <Grid container alignItems={'center'} justifyContent={'center'} alignContent={'center'}>
                <Grid item  alignItems={'center'}>
                <TableRow >
                
                 <Typography style={{paddingBottom: "1em"}}>
                     Selected amount [$]: {amount}
                 </Typography> 
                 
              
    </TableRow>
                
               
                 <TableRow >
                { amountIsSet && <TableRow >
            <PayPalScriptProvider 
                options={{
                    /* "client-id": "AdbK31wXzR-S_Z9mXPFWIJAjr3-2Pu3DPN7Yo-Yl64VgtlNCQAsuAwdp8UtQMYeEHHjqZIkyzKGLlSgy", */
                    "client-id": "AZlV_-abN4jsZrqb5HbhTTZechhfCjD2-k_h2QZp5Qzhj8CJs1oQbhWVckQlMUCmtjvtMC3YkIDiFpWe",
                    components: "buttons",
                    currency: "CHF",
                    locale:"de_CH",
                    /* "data-client-token": user1, */
                }}
            >
               
				<ButtonWrapper 
                    currency={currency}
                    showSpinner={false}
                    user={user1}
                    
                />
			</PayPalScriptProvider>
            </TableRow>}
                </TableRow>
                
                
                 <TableRow >
                    <Typography>
                     {/* 1 $ ~ 3 Credits ~ 3 Component */}
                  {/*    Current Price: 0.2 CHF/Component 
                     (+2% compared to last week) */}
                    </Typography>
                </TableRow>
                    </Grid>
            </Grid>
            </div>
    
        </>
       
		
	);
}