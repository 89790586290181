import CheckCircle from "@mui/icons-material/CheckCircle";
import AddCircle from "@mui/icons-material/AddCircle";
import SearchIcon from "@mui/icons-material/Search";

/* import CircularProgress from "@mui/material/CircularProgress"; */
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import Carousel from 'react-material-ui-carousel';
import VolumeUp   from '@mui/icons-material/VolumeUp';
import VolumeOff   from '@mui/icons-material/VolumeOff';
import { motion } from "framer-motion";
import { useDrag } from '@use-gesture/react'
import { WidthProvider, Responsive, GridLayout } from 'react-grid-layout'
/* import InfoIcon from '@mui/icons-material/Info'; */

import {
  useSpringRef,
  animated,
  useTransition,
  useSpring,
} from "@react-spring/web"

import React, { useEffect, useRef, useState } from "react";
import AceEditor from "react-ace";
import styled from "@emotion/styled";

import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';

/* import {
  Chip,
  Radio,
  Container,
  Slider,
  Switch,
  Divider,
  Tooltip,
  Menu,
  CardHeader,
  Typography,
  Box,
  Link,
  Grid,
  CardActions,
  Avatar,
  IconButton,
  FormControl,
  TableContainer,
  MenuItem,
  InputLabel,
  Badge,
  Select,
  Collapse, ToggleButton, ToggleButtonGroup,
  Checkbox, FormControlLabel,
  Paper,
  Modal,
  TextField, Button, Table, TableBody, TableCell, TableHead, TableRow,  Step, Stepper, StepLabel,AppBar, Toolbar, Card, CardContent, InputAdornment, ListItem, ListItemIcon, ListItemText, List, Accordion, AccordionSummary, AccordionDetails, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar 
} from '@mui/material'; */

import {
  Accordion,
AccordionActions,
AccordionDetails,
AccordionSummary,
Alert,
AlertTitle,
AppBar,
Autocomplete,
Avatar,
AvatarGroup,
Backdrop,
Badge,
BottomNavigation,
BottomNavigationAction,
Box,
Breadcrumbs,
Button,
ButtonBase,
ButtonGroup,
Card,
CardActionArea,
CardActions,
CardContent,
CardHeader,
CardMedia,
Checkbox,
Chip,
CircularProgress,
Collapse,
Container,
CssBaseline,
Dialog,
DialogActions,
DialogContent,
DialogContentText,
DialogTitle,
Divider,
Drawer,
Fab,
Fade,
FilledInput,
FormControl,
FormControlLabel,
FormGroup,
FormHelperText,
FormLabel,
GlobalStyles,
Grid,
Grow,
Hidden,
Icon,
IconButton,
ImageList,
ImageListItem,
ImageListItemBar,
Input,
InputAdornment,
InputBase,
InputLabel,
LinearProgress,
Link,
List,
ListItem,
ListItemAvatar,
ListItemButton,
ListItemIcon,
ListItemSecondaryAction,
ListItemText,
ListSubheader,


Menu,
MenuItem,
MenuList,
MobileStepper,
Modal,
NativeSelect,
OutlinedInput,
Pagination,
PaginationItem,
Paper,
Popover,
Popper,
Radio,
RadioGroup,
Rating,
ScopedCssBaseline,
Select,
Skeleton,
Slide,
Slider,
Snackbar,
SnackbarContent,
SpeedDial,
SpeedDialAction,
SpeedDialIcon,
Stack,
Step,
StepButton,
StepConnector,
StepContent,
StepIcon,
StepLabel,
Stepper,
SvgIcon,
SwipeableDrawer,
Switch,
Tab,

Table,
TableBody,
TableCell,
TableContainer,
TableFooter,
TableHead,
TablePagination,
TableRow,
TableSortLabel,


Tabs,
TabScrollButton,
TextField,
ToggleButton,
ToggleButtonGroup,
Toolbar,
Tooltip,
Typography,
Zoom } from '@mui/material';
import {
  TreeView,
  TreeItem,
  Masonry,
  TabContext,
  TabPanel,
  TabList,
  Timeline,
TimelineConnector,
TimelineContent,
TimelineDot,
TimelineItem,
TimelineOppositeContent,
LoadingButton,
TimelineSeparator,
} from '@mui/lab';

/* import Autocomplete from '@mui/material/Autocomplete'; */
import Confetti from 'react-confetti';
/* import {CardMedia} from '@mui/material'; */
import {LocalizationProvider, DatePicker} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import ReactGA from 'react-ga4';
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
import { css } from '@emotion/react';
import ace from "ace-builds/src-noconflict/ace";
import { FieldGroupIconButton } from "@aws-amplify/ui-react";
import { ArrowBackIos, ArrowForwardIos, Label } from "@mui/icons-material";
import { SplineCurve } from "three";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";

import { AddToMyComponents, ListComponents, UpdateComponent, deleteComponent, GetLibraryPublic } from "../services/graphQlService";
import TagSelector from "./TagSelector";
import FilterWithChips from "./Filter";
import TagManager from "react-gtm-module";

ace.config.set("useWorker", false);

const StyledCard = styled(Card)({
  backgroundColor: "rgb(255, 255, 255)",
  color: "rgb(17, 25, 39)",
  boxShadow: "rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px",
  borderRadius: "20px",
  overflow: "hidden",
  backgroundImage: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: "1.5",
  fontFamily: "'Inter',-apple-system,BlinkMacSystemFont,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji'",
  "&:hover": {
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
});

const ComponentLibrary = (props) => {
  console.log(props)
  const [code, setCode] = useState("");
  const [preview, setPreview] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [rowState, setRowState] = useState(null);
  const [rowStateRef, setRowStateRef] = useState([]);
  const [selectedTags, setSelectedTags] = useState([])
  const [filteredComponents, setFilteredComponents] = useState([])
  const [paginationStart, setPaginationStart] = useState(0)
  const [paginationEnd, setPaginationEnd] = useState(5)
  const [currentPage, setCurrentPage] = useState(1);
  const [tagCloud, setTagCloud] = useState([])
  const [included, setSetIncluded] = useState([])
  const [viewCounterStart, setViewCounterStart] = useState(0)
  const [itemsShown, setItemsShown] = useState(10)
  const [componentListInit , setComponentListInit] = useState(null)
  const [isPublic, setIsPublic] = useState(false)
  const [codePreviewedNo, setCodePreviewedNo] = useState(0)
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [spinner, setSpinner] = useState(false)

  let codePreviewedCounter = 0

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const extractTagList = (componentsList) => {
    let tagList = []
    let tagListRef = []
    componentsList.forEach((component, index) => {
      if(component.tags){
        /* console.log(component.tags) */
        component.tags.forEach((tag, index) => {
         
          let tagTitle = tag.split('=')[1]
          tagTitle = tagTitle.split('}')[0]
          tagTitle = tagTitle.toLowerCase()
          /* console.log(tagTitle) */
          if(!tagListRef.includes(tagTitle)){
            tagList.push({title: tagTitle, count: 1})
            tagListRef.push(tagTitle)
          } else {
            let posTag = tagList.findIndex(tag => tag.title === tagTitle)
            /* console.log(posTag) */
            if(posTag > -1){
            tagList[posTag].count = tagList[posTag].count + 1
            }
          }

        })
      }
      })
      tagList.sort((a, b) => (a.count < b.count) ? 1 : -1)
      /* console.log(tagList) */
      console.log(tagList)
      console.log(tagListRef)
      return tagList
    }



  const loadComponents = async () => {
    console.log(props)
    setSpinner(true)
    const components = await ListComponents()
    console.log(components)
    let componentsList = []
    if(components){
      console.log(components)
      componentsList = components.data.listComponents.items
      setSpinner(false)
    } else {
      const publicRead = await GetLibraryPublic()
      console.log(publicRead)
      let items = JSON.parse(publicRead.data.listComponentsPublic)
      componentsList = items.data.listComponents.items
      console.log(componentsList)
      setIsPublic(true)
      setSpinner(false)
    }
     
    
  

    componentsList.forEach((component,index) => {
        component['code'] = component.newComponent
        component['title'] = component.componentName
        
    })
    console.log(componentsList)
    /* componentsList.sort((a, b) => (Number(new Date(b.createdAt)) - Number(new Date(a.createdAt)))) */
    componentsList.sort((a, b) => (Number(b.systemRank) - Number(a.systemRank)))
    /* let rows = [];
    for (let i = 0; i < componentsList.length; i += 3) {
        rows.push(componentsList.slice(i, i + 3));
      } */

      /* let filteredItems = []
      let filteredItemsName = ['BannerImage', 'ImageChemical', 'BannerNew'] */
   
      componentsList = componentsList.filter((component, index) =>  /* !filteredItems.includes(index) && !filteredItemsName.includes(component.componentName) && */ component.newComponent)
      /* let componentListStart10 =  componentsList.slice(0, 10) */
      let tagList = extractTagList(componentsList)
      console.log({tagList})
      setTagCloud(tagList)
      setRowStateRef(componentsList)
      componentsList = componentsList.filter((component, index) => index < itemsShown)
      if(!componentListInit){

        setComponentListInit(componentsList)
      }
      /* componentsList = componentsList.slice(paginationStart, paginationEnd) */
      setRowState(componentsList.slice())
      /* console.log(rowState) */
  }

  const components = [
    { title: "Component 1", code: `const ChecklistContainer = styled(Paper)({
        padding: '16px',
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto'
      });
      
      const marketingItems = [
        'Identify target audience',
        'Create unique selling proposition',
        'Develop marketing strategy',
        'Set marketing goals',
        'Plan marketing budget',
        'Design marketing materials',
        'Launch marketing campaigns',
        'Track marketing performance',
        'Analyze marketing data',
        'Optimize marketing efforts'
      ];
      
      const ProductMarketingChecklist = () => {
        const [checkedItems, setCheckedItems] = React.useState(Array(10).fill(false));
      
        const handleCheckboxChange = (index) => (event) => {
          const newCheckedItems = [...checkedItems];
          newCheckedItems[index] = event.target.checked;
          setCheckedItems(newCheckedItems);
        };
      
        return (
          <ChecklistContainer>
            <Typography variant="h6" gutterBottom>
              Product Marketing Checklist
            </Typography>
            {marketingItems.map((item, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={checkedItems[index]}
                    onChange={handleCheckboxChange(index)}
                  />
                }
                label={item}
              />
            ))}
          </ChecklistContainer>
        );
      };
      
      render(ProductMarketingChecklist)` },

  ];

/*   function suppressErrorsAndWarnings() {
    console.error = function () {};
    console.warn = function () {};
  } */

  const checkStates = () => {
      console.log({rowState})
      if(rowState){
        console.log(rowState.length)
      }
      
      console.log({tagCloud})
      console.log({included})
    }

    

  useEffect(() => {
    loadComponents()
    /* checkStates() */

    }, [])

    useEffect(() => {
      if(codePreviewedNo === 3){
        console.log('three code clicks')
        ReactGA.event({
          category: 'engagement',
          action: 'CodePreviewedThreeTimes',
        });
        /* if(window.dataLayer){
          window.dataLayer.push({
            category: 'engagement',
            event: 'CodePreviewedThreeTimes',
          })
        } */
        /* TagManager.dataLayer({
          category: 'engagement',
            event: 'CodePreviewedThreeTimes',
        }) */
      } else if(codePreviewedNo > 3){
        /* setSnackBarMessage('You have previewed the code '+codePreviewedNo+' times. Login to see the full code.') */
        setSnackBarMessage(`Hello there! 😊
        We noticed that you've previewed our codes `+ codePreviewedNo +` times, and we're truly delighted to see your interest! We believe our platform has a lot to offer, and we're confident that by registering, you will unlock an even better experience! 🚀
      Once you're all set up, feel free to reach out if you have any questions. We're here to help! 🙌`)
        setSnackBarOpen(true)

      }

    }, [codePreviewedNo])


    const handleCloseSnackbar = () => {
      setSnackBarOpen(false)
    }

    const handleTagDelete = (tagToDelete) => () => {

      setSelectedTags((prevState) => prevState.filter((tag) => tag.title !== tagToDelete.title));
      /* setRowState(rowStateRef) */
      setFilteredComponents([])
      /* let filteredComponentsLocal = [...filteredComponents]
      let currentRowState = [...rowState]
      rowState.map((component, index) => {
      let componentTags = component.tags
      if(componentTags){
          componentTags = componentTags.map(tagC => {
            console.log(tagC)
            console.log(tagToDelete.title)
            let tagc = tagC.split('=')[1].split('}')[0]
            
            console.log(tagc)
            if(tagc === tagToDelete.title){
              console.log('found')
              currentRowState.splice(index)
            }
          })
      }
      
    }) */
    setRowState(rowStateRef)
    };
  


   const handleTagClick = (tag) => {
    console.log(tag)
   
    /* setShownItems(30) */
    let tags = [...selectedTags]
    tags.push(tag)
    setSelectedTags(tags)
    let filteredComponentsLocal = [...filteredComponents]
    rowState.filter(component => {
      let componentTags = component.tags
      if(componentTags){
          componentTags = componentTags.map(tagC => {
            console.log(tagC)
            console.log(tag.title)
            let tagc = tagC.split('=')[1].split('}')[0]
            
            console.log(tagc)
            if(tagc === tag.title){
              console.log('found')
              filteredComponentsLocal.push(component)
            }
          })
        
       
      
      }
      /* let found = componentTags.find(componentTag => componentTag.title === tag.title) */
    })
    console.log(filteredComponentsLocal)
    let combinedFilteredComponents = [...filteredComponentsLocal]
    setFilteredComponents(combinedFilteredComponents)
    setRowState(combinedFilteredComponents)
   }



const RenderActions1 = (component) => {
  component = component.component
  
  let currentTags = []
  if(component.tags){
    component.tags.forEach(tag => {
      let title = tag.split('=')[1].split('}')[0]
      currentTags.push({title})
  })
  }

  if(isPublic){
  let posRender = component.newComponent.indexOf('render(')
  component.newComponent = component.newComponent.slice(0, posRender)
  component.command = component.command.slice(0, 400)+'... Please login to see the full command.'
  }
  
  const [visi, setVisi] = useState(component.isPublic ? true : false);
  const [tags, setTags] = useState(currentTags || []);
  const [rank, setRank] = useState(component.systemRank || 0);
  const [open, setOpen] = useState(false);

  const handleCancel = () => {
    setOpen(false)
    console.log(codePreviewedNo+1)
    setCodePreviewedNo(codePreviewedNo+1)
  }

  const onLoad = (editor) => {
    const mode = editor.getSession().getMode();
  
    if (mode.$worker) {
      mode.$worker.send("setOptions", [
        {
          asi: false,
        },
      ]);
    }
        // Suppress errors by hiding the gutter and line numbers
        editor.renderer.setShowGutter(true);
        editor.renderer.setOption('showLineNumbers', true);
    
        // Configure the editor to ignore certain types of errors
        const session = editor.getSession();
        session.setUseWorker(false);
        /* session.setMode('ace/mode/react');  <-- causing error in aceditor*/
        session.setOptions({
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
        });
  };

  const handleCodeSnippetOpen = () => {
    setOpen(true)
    codePreviewedCounter++
    console.log(codePreviewedCounter)
/*     setCodePreviewedNo(codePreviewedNo + 1)
    console.log(codePreviewedNo) */
  }

  const handleUpdateParent = (data) => {
    console.log('updateTags')
    console.log(data)
    setTags(data)
  }

  const handleAddToMyComponents = async () => {
    let res = await AddToMyComponents(component)
    console.log(res)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('handleSubmit')
    const formData = new FormData(e.target);
    /* const visibility = formData.get('visiblity') */
    /* console.log(visibility) */
    /* console.log(visi) */

    /* const price = formData.get('price') */
    let componentData = {
      id: component.id,
      tags: tags,
      isPublic: visi,
      systemRank: rank,
      /* price: price */
    }
    console.log(componentData)
    let res = await UpdateComponent(componentData)
    console.log(res)
    
    
  }


    return (
      <CardContent>
      <Typography variant="h7" component="h4" style={{color:'lightgray'}}>
          Name
          </Typography>
      <Typography variant="h5" component="h2">
          {component.componentName}
          </Typography>
          <br/>
         {component.type && 
         <>
         <Typography variant="h7" component="h4" style={{color:'lightgray'}}>
          ReactEvolve™
          </Typography>
          <Typography variant="body2" component="p">
          {component.type === 'AdjustmentOnly' ? 'Adjustment': component.type}
          </Typography>
          </>}
          
          <br/>
          <Typography variant="h7" component="h4" style={{color:'lightgray'}}>
          Tags
          </Typography>
          {props.user === component.userId ? 
          <><TagSelector tags={tags} updateParent={handleUpdateParent} /></>
          :
          <>
          {tags && tags.map((tag, index) => {
            
            return (
              <Chip
              label={tag.title}
              key={index}
              /* onClick={() => handleTagClick(tag)} */
              color="primary"
              variant="outlined"
              style={{marginRight: '1em'}}
            />
          )
          })}

          </>
          }
          <br/>
          <Typography variant="h7" component="h4" style={{color:'lightgray'}}>
          Command
          </Typography>
          <Typography variant="body2" component="p">
          {component.command}
          </Typography>
          <br/>
          <Typography variant="h7" component="h4" style={{color:'lightgray'}}>
          Actions
          </Typography>
          {props.user === component.userId ?  
          <form onSubmit={handleSubmit}>
          <Grid container>
          
           <Grid item>
             {/* <TextField style={{marginBottom: '1em', marginLeft:'1em'}} id="outlined-basic" name="price" label="Set price" variant="outlined" /> */}
          <br/>
          <Button style={{marginBottom: '1em', marginLeft:'1em'}} type="submit" variant="contained" >Update</Button>
         {props.isAdmin && <Button style={{marginBottom: '1em', marginLeft:'1em'}} variant="contained" onClick={() => handleDeleteComponent(component)}>Delete</Button>}
           
           </Grid>
          
           <Grid item style={{marginLeft:"1em"}}>
          {/*  <FormControl fullWidth variant="outlined" style={{ marginBottom: "1em" }}>
            <Select
            value={visi}
            name={'visibility'}
            onChange={(e) => setVisi(e.target.value)}
            displayEmpty
            >
            <MenuItem value="" disabled>
              Visibility
            </MenuItem>
            <MenuItem value={"Private"}>Private</MenuItem>
            <MenuItem value={"Public"}>Public</MenuItem>
            </Select>
            </FormControl> */}
            <FormControl>
            <MenuItem value="" disabled>
              Share with community?
            </MenuItem>
              <Switch checked={visi} onChange={() => setVisi(!visi)}  name="price" />
            </FormControl>
            {props.isAdmin && 
            <FormControl>
            <MenuItem value="" disabled>
              Add rank
            </MenuItem>
              <TextField value={rank} onChange={(e) => setRank(e.target.value)}  name="rank" />
            </FormControl>}
           </Grid>
          {/*  <Grid item>
          <Button style={{marginBottom: '1em', marginLeft:'1em'}} variant="contained" >Buy</Button>

           </Grid> */}
          
         {/*   <Grid item style={{marginBottom: '1em', marginLeft:'1em'}}>
           <TagSelector tags={tags} updateParent={handleUpdateParent} />
           </Grid> */}
          
           </Grid>
            </form>
            :
            <form onSubmit={handleSubmit}>
            <Grid container>
            
             <Grid item>
            <br/>
            {!isPublic ?
            <Button style={{marginBottom: '1em', marginLeft:'1em'}} onClick={handleAddToMyComponents} variant="contained" >Save to my component</Button>
            :
            <>
            <Button style={{marginBottom: '1em', marginLeft:'1em'}} onClick={handleCodeSnippetOpen} variant="contained" >Show Code</Button>
            <Dialog  open={open} onClose={handleCancel}>
              <DialogTitle style={{ textAlign: 'center' }} >{"Code Snippet"}</DialogTitle>
              <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <AceEditor
              mode="javascript"
              value={component.newComponent}
              // onChange={handleCodeChange} 
              name="code-editor"
              // editorProps={{ $blockScrolling: true }} 
              width="600px"
              height="1000px"
              onLoad={onLoad}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                tabSize: 3,
                /* showLineNumbers: true,  */
              }}
            />

              </DialogContent>
              <DialogActions style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="h7" component="h4" style={{color:'lightgray'}}>
                Export the full component code as a registered user
                </Typography>
                <Button onClick={handleCancel} color="primary" autoFocus>
                  Cancel
                </Button>
               
              </DialogActions>
            </Dialog>
            </>
            }
           {/*  {component.price && <Button style={{marginBottom: '1em', marginLeft:'1em'}} variant="contained" >Buy</Button>} */}
             
             </Grid>
            
             
             <Grid item>
           
  
             </Grid>
            
           {/*   <Grid item style={{marginBottom: '1em', marginLeft:'1em'}}>
             <TagSelector tags={tags} updateParent={handleUpdateParent} />
             </Grid> */}
            
             </Grid>
              </form>
          }

      </CardContent>
    );
}

const updateComponents = (currentPage, filteredC) => {
 
  
  
  let filteredLength = filteredC.length
  console.log({filteredLength})
  let componentsList = [...filteredC]
  componentsList.sort((a, b) => (Number(b.systemRank) - Number(a.systemRank)))

 
   
 /*  if(filteredLength > 10 && included.length === 0) {
   componentsList = filteredC.filter((component, index) => index < itemsShown)
  } */
 /*  if(included.length < 1 && filteredLength > 5) {
  componentsList = componentsList.slice(paginationStart, paginationEnd)
  } */
  
  console.log({componentsList})
  let uniqueIds = []
  componentsList = componentsList.filter(component => {
    if(!uniqueIds.includes(component.id)) {
      uniqueIds.push(component.id)
      return component
    }
  })
 /*  if(included && included.length === 0){
    console.log({componentListInit})
    componentsList = componentListInit
  } */
  if(componentsList.length > 0) {
  setRowState(componentsList.slice())
  }
}

const load10More = () => {
 
  let b = itemsShown
  console.log({b})
  b = b + 10
  console.log({b})
  setItemsShown(b)
  ReactGA.event({
    category: 'engagement',
    action: 'PublicLoadMore'
  });
  /* if(window.dataLayer){
    window.dataLayer.push({
      category: 'engagement',
      event: 'PublicLoadMore'
    })
  } */
  /* TagManager.dataLayer({
    category: 'engagement',
    event: 'PublicLoadMore'
  }) */
}

const handleLoadMore = () => {
  if(itemsShown < rowStateRef.length) {
  load10More()
  updateComponents(0, rowStateRef)
  }
}

const handleDeleteComponent = async (c) => {
        console.log({c});
        let obj = {}
        obj['id'] = c.id
        let rowStateNew = rowState.filter(component => component.id !== c.id)
        setRowState(rowStateNew)
        
        
        let deleteCompRes = await deleteComponent(obj)
        console.log({deleteCompRes});        
      }

      const Pagination = () => {
        
        const [arrayWithPageNums, setarrayWithPageNums] = useState([])
        /* const [currentPage, setCurrentPage] = useState(1) */
        console.log({viewCounterStart})
      
      
        const initPages = () => {
          let rowStateRefLength = rowStateRef.length
          console.log({rowStateRefLength})
          let pagesAmount = Math.ceil(rowStateRefLength/5)
          console.log({pagesAmount})
          let arrayWithPageNums = []
      
          for(let i = 1; i < pagesAmount; i++){
            arrayWithPageNums.push(i)
          }
          //create an array with page numbers

          console.log({arrayWithPageNums})
          setarrayWithPageNums(arrayWithPageNums)
        }
      
        useEffect(() => {
          initPages()
        }, [])
      
        const handlePrevClick = () => {
          if (currentPage > 1) {
            
            setCurrentPage(currentPage - 1);
            setViewCounterStart(viewCounterStart - 5)
            updateComponents(currentPage-1)
          }
        };
      
        const handleNextClick = () => {
          console.log(arrayWithPageNums[arrayWithPageNums.length-1])
          if (currentPage < arrayWithPageNums[arrayWithPageNums.length-1]) {
            updateComponents(currentPage+1)
            setCurrentPage(currentPage + 1);
            setViewCounterStart(viewCounterStart + 5)
          }
        };
      
        const handlePageClick = (pageNum) => {
          setCurrentPage(pageNum);
          setViewCounterStart((pageNum-1)*5)
          updateComponents(pageNum)
        };
      
        const updateComponents = (currentPage) => {
         console.log({currentPage})
          console.log({viewCounterStart})
          setPaginationStart(viewCounterStart)
          setPaginationEnd(viewCounterStart+5)
          /* let componentsList = rowStateRef.filter((component, index) => (viewCounterStart < index && index <= viewCounterStart+5 )) */
         
          console.log({componentsList})
          setRowState(componentsList)
        }
      
       
      
        return (
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              onClick={handlePrevClick}
              disabled={currentPage === 1}
              style={{ marginRight: '1em' }}
            >
              <ArrowBackIos />
            </IconButton>
            {[...arrayWithPageNums].map((pageNum) => (
              <Typography
                key={pageNum}
                variant="body1"
                onClick={() => handlePageClick(pageNum)}
                style={{
                  cursor: 'pointer',
                  marginRight: pageNum === arrayWithPageNums[arrayWithPageNums.length-1] ? '1em' : '0.5em',
                  fontWeight: currentPage === pageNum ? 'bold' : 'normal',
                }}
              >
                {pageNum}
              </Typography>
            ))}
            
            <IconButton
              onClick={handleNextClick}
              disabled={currentPage === arrayWithPageNums[arrayWithPageNums.length-1]}
              style={{ marginLeft: '1em' }}
            >
              <ArrowForwardIos />
              
            </IconButton>
          </Box>
        );
      };

const handleTagChange = async (obj) => {
    console.log({obj})
    
    let tagList = obj.updatedTagList
    console.log({tagList})
    let includedLocal = obj.included
    console.log({includedLocal})
    console.log({tagCloud})

    let tagsToBeFiltered = includedLocal.map(tag => tag.title)


    
    console.log(tagsToBeFiltered)
    tagList = tagList.sort((a, b) => b.count - a.count)

   /*  if(tagsToBeFiltered.length < 1 && included.length < 1 && tagList.length > tagCloud.length){
      setTagCloud(tagList)
    } */

    setTagCloud(tagList.slice())
   setSetIncluded(includedLocal.slice())
    
    /* console.log({rowState}) */
    
    let selectedComponents = []
    let filteredComponents = rowStateRef.map(component => { 
      //console.log({component})
      //console.log({tagsToBeFiltered})
      if(component.tags && tagsToBeFiltered.length > 0){
        /* console.log(component.tags) */
        let tags = component.tags.map(tag => tag.split('=')[1].split('}')[0])
        //console.log({tags})
        
        tags.forEach(tag => {
          /* console.log({tag}) */
          if(tagsToBeFiltered.includes(tag.toLowerCase())){
            selectedComponents.push(component)
          }
        })
        /* let filtered = tags.filter(tag => tagsToBeFiltered.includes(tag))
        console.log({filtered})
        if(filtered.length > 0){

          selectedComponents.push(component)
        } */
      }
    })

    console.log({tagsToBeFiltered})
    console.log({selectedComponents})
    selectedComponents.sort((a,b) => a.systemRank - b.systemRank)
    
    if(includedLocal.length === 0){
      /* setSetIncluded([]) */
      /* updateComponents(0, []) */
      console.log('sett 0')
      updateComponents(0, componentListInit )
    } else {
      updateComponents(0, selectedComponents)

    }    
}



const navigate = useNavigate()
const handleRefresh = () => {
  setRowState([])
  loadComponents()
  navigate('/library', {reload: true})
}



  return (
    <Container style={{padding:"1em"}}>
      {/* <Typography style={{margin:"1em"}} variant="h4" component="h1" gutterBottom>
        Community Library
      </Typography> */}
  <Grid container spacing={3} style={{marginBottom: '1em'}} alignItems={'center'} >
        <Grid item xs={12} md={10}>
          <Card style={{margin:'1em', padding: '1em'}}>

      {tagCloud && tagCloud.length > 0 && <FilterWithChips tags={tagCloud} updateLibrary={handleTagChange} />}
      </Card>
      </Grid>
      <Grid item xs={12} md={2} justifyContent={'center'} alignContent={'center'} >
      <CardContent >
        <Typography variant="h7" component="h4" style={{color:'blue'}}>
        {rowState ? <>
          {rowState.length}
          {included.length === 0 && rowState.length < rowStateRef.length ? "+" : ""}
          {" components found"}

          {included.length > 1 &&
            <Typography variant="h8" component="h6" style={{color:'lightgray'}}>
            Dublicates are filtered
            </Typography>
          }
          </> : <>
          Please select
          {spinner && <CircularProgress size={20} style={{marginLeft: '1em'}} />}
          </>}
        </Typography>
       {/*  {itemsShown < rowStateRef.length && included.length < 1 && <Button onClick={handleRefresh} variant="contained" color="primary" style={{marginBottom: '1em'}}>Refresh</Button>} */}
      </CardContent>
      </Grid>
      </Grid>
     {/*  
      {selectedTags && selectedTags.length > 0 && <Card style={{margin:'1em', padding: '1em'}}>
      
      <Typography variant="h7" component="h4" style={{color:'lightgray'}}>
          Selected Tags
          </Typography>
          <CardContent>
      {selectedTags &&  selectedTags.map(tag => {
            
            return (
              <Chip
              label={tag.title}
              
              onDelete={handleTagDelete(tag)}
              color="primary"
              variant="outlined"
              style={{marginRight: '1em'}}
            />
          )})}
          </CardContent>
      </Card>} */}
      
      

      
       
      {rowState && rowState?.map((component, index) => {
        
        return (
            <Grid item key={index} xs={12} md={4} key={component.id} style={{ marginTop: '1em' }}>
              
                  <StyledCard>
                  <Grid style={{/* border: '1px solid black',  */ height: '100%', marginLeft:'1em', marginTop:'0.5em', padding:'0.25em', borderRadius: '10px', marginBottom: '1em'}} >
                  {component.id}
                     

                     <Grid item style={{ flexGrow: 2 }} style={{backgroundColor: '#ececfb', /* border: '1px solid black', */ height: '100%', marginLeft:'1em', marginTop:'0.5em', padding:'1.75em', borderRadius: '10px'}} >
                      
            <LiveProvider 
              
            code={component.code} scope={
          {
            Accordion,
AccordionActions,
AccordionDetails,
AccordionSummary,
Alert,
AlertTitle,
AppBar,
Autocomplete,
Avatar,
AvatarGroup,
Backdrop,
Badge,
BottomNavigation,
BottomNavigationAction,
Box,
Breadcrumbs,
Button,
ButtonBase,
ButtonGroup,
Card,
CardActionArea,
CardActions,
CardContent,
CardHeader,
CardMedia,
Checkbox,
Chip,
CircularProgress,
Collapse,
Container,
CssBaseline,
Dialog,
DialogActions,
DialogContent,
DialogContentText,
DialogTitle,
Divider,
Drawer,
Fab,
Fade,
FilledInput,
FormControl,
FormControlLabel,
FormGroup,
FormHelperText,
FormLabel,
GlobalStyles,
Grid,
Grow,
Hidden,
Icon,
IconButton,
ImageList,
ImageListItem,
ImageListItemBar,
Input,
InputAdornment,
InputBase,
InputLabel,
LinearProgress,
Link,
List,
ListItem,
ListItemAvatar,
ListItemButton,
ListItemIcon,
ListItemSecondaryAction,
ListItemText,
ListSubheader,
LoadingButton,
Masonry,
Menu,
MenuItem,
MenuList,
MobileStepper,
Modal,
NativeSelect,
OutlinedInput,
Pagination,
PaginationItem,
Paper,
Popover,
Popper,
Radio,
RadioGroup,
Rating,
ScopedCssBaseline,
Select,
Skeleton,
Slide,
Slider,
Snackbar,
SnackbarContent,
SpeedDial,
SpeedDialAction,
SpeedDialIcon,
Stack,
Step,
StepButton,
StepConnector,
StepContent,
StepIcon,
StepLabel,
Stepper,
SvgIcon,
SwipeableDrawer,
Switch,
Tab,
TabContext,
Table,
TableBody,
TableCell,
TableContainer,
TableFooter,
TableHead,
TablePagination,
TableRow,
TableSortLabel,
TabList,
TabPanel,
Tabs,
TabScrollButton,
TextField,
Timeline,
TimelineConnector,
TimelineContent,
TimelineDot,
TimelineItem,
TimelineOppositeContent,
TimelineSeparator,
ToggleButton,
ToggleButtonGroup,
Toolbar,
Tooltip,
TreeItem,
TreeView,
Typography,
Zoom,
useState,
React,
Confetti,
styled,
AddCircle,
SearchIcon,
InfoOutlinedIcon,
ArrowUpward,
ArrowDownward,
useDrag,
useSpringRef,
animated,
useTransition,
useSpring,
motion,
VolumeUp,
Carousel,
ArrowBackIos,
ArrowForwardIos,
GridLayout,
WidthProvider,
Responsive,
AddCircleIcon,
DeleteIcon,
AceEditor,
AttachMoneyIcon,
CreditCardIcon,
LocalizationProvider,
DatePicker,
AdapterDateFns,
MenuIcon,
ExpandMoreIcon,
LivePreview,
LiveProvider,
useEffect,
css,
CheckCircle,
             }} noInline>
              <LivePreview />
              {/* <LiveError /> */}
            </LiveProvider>
           
                      </Grid>

                      
                      <RenderActions1 key={index} component={component} />
                      </Grid>
                      </StyledCard>
              </Grid>
        )
  })}
          
      
            {/* {itemsShown < rowStateRef.length && included.length < 1 && <StyledCard>
              
             <CardContent style={{display: 'flex', justifyContent: 'center'}}>
              <Button onClick={handleLoadMore} variant="contained" color="primary" style={{marginBottom: '1em'}}>Load More</Button>
             </CardContent>
              </StyledCard>} */}
      <CardContent>
             
        {/* <CardHeader title="Pagination" /> */}
        {/* {rowState.length > 20 && included.length < 1 && <Pagination/>} */}
      </CardContent>
      <Snackbar open={snackBarOpen} message={snackBarMessage} autoHideDuration={20000} onClose={handleCloseSnackbar} />
    </Container>
  );
};

export default ComponentLibrary;
