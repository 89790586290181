import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useState } from "react";
import { CreateFeedback } from "../services/graphQlService";

const StyledCard = styled(Card)({
    margin: '1em',
    padding: '1em'
  });



  
  const Corporate2 = (props) => {
    const [feedbackSent, setFeedbackSent] = useState(false);

    const submitFeedback = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData.entries());
        data.userId = props.user.id
        console.log(data);
        await CreateFeedback(data);
        setFeedbackSent(true);
        };

    return (
        <>
        {feedbackSent ? 
        <StyledCard>
        <Typography variant="h6" style={{ marginBottom: '1em' }}>Thank you for your feedback!</Typography>
        <Typography variant="subtitle1" style={{ marginBottom: '1em' }}>We try to review user feedback frequently.</Typography>
        <Button variant="contained" color="primary" onClick={() => setFeedbackSent(false)}>Send another feedback</Button>
        </StyledCard>
       :
      <StyledCard>
        <Typography variant="h6" style={{ marginBottom: '1em' }}>Ask a question or give feedback</Typography>
        <Typography variant="subtitle1" style={{ marginBottom: '1em' }}>We appreciate your valued feedback.</Typography>
        <Grid container spacing={1}>
          {/* <Grid item xs={12} md={6}>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              style={{ marginBottom: '1em' }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              style={{ marginBottom: '1em' }}
            />
          </Grid> */}
          <form onSubmit={submitFeedback} style={{width:"100%"}}>
          <Grid item xs={12}>
            <TextField
              label="Message"
              variant="outlined"
              fullWidth
              name="feedback"
              multiline
              rows={4}
              style={{ marginBottom: '1em' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit" >Submit</Button>
          </Grid>
            </form>
        </Grid>
      </StyledCard>}
      </>
    );
  };
  
  export default Corporate2 