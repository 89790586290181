/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPersona = /* GraphQL */ `
  mutation CreatePersona($event: String) {
    createPersona(event: $event)
  }
`;
export const registerUser = /* GraphQL */ `
  mutation RegisterUser($event: String) {
    registerUser(event: $event)
  }
`;
export const updateUserPlan = /* GraphQL */ `
  mutation UpdateUserPlan($event: String) {
    updateUserPlan(event: $event)
  }
`;
export const createSalesPitchFunction = /* GraphQL */ `
  mutation CreateSalesPitchFunction($event: String) {
    createSalesPitchFunction(event: $event)
  }
`;
export const createSalesPitchLongFunction = /* GraphQL */ `
  mutation CreateSalesPitchLongFunction($input: MessageInput!) {
    createSalesPitchLongFunction(input: $input) {
      id
      salesPitch {
        id
        userId
        persona
        prompt
        createdAt
        updatedAt
        __typename
      }
      status
      result
      shortDescription
      fiveQuestionsOpenConversation
      fiveArguments
      fiveQuestionsPains
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createComponentFunction = /* GraphQL */ `
  mutation CreateComponentFunction($event: String) {
    createComponentFunction(event: $event)
  }
`;
export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSalesPitchRequest = /* GraphQL */ `
  mutation CreateSalesPitchRequest(
    $input: CreateSalesPitchRequestInput!
    $condition: ModelSalesPitchRequestConditionInput
  ) {
    createSalesPitchRequest(input: $input, condition: $condition) {
      id
      userId
      persona
      prompt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSalesPitchRequest = /* GraphQL */ `
  mutation UpdateSalesPitchRequest(
    $input: UpdateSalesPitchRequestInput!
    $condition: ModelSalesPitchRequestConditionInput
  ) {
    updateSalesPitchRequest(input: $input, condition: $condition) {
      id
      userId
      persona
      prompt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSalesPitchRequest = /* GraphQL */ `
  mutation DeleteSalesPitchRequest(
    $input: DeleteSalesPitchRequestInput!
    $condition: ModelSalesPitchRequestConditionInput
  ) {
    deleteSalesPitchRequest(input: $input, condition: $condition) {
      id
      userId
      persona
      prompt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSalesPitchResponse = /* GraphQL */ `
  mutation CreateSalesPitchResponse(
    $input: CreateSalesPitchResponseInput!
    $condition: ModelSalesPitchResponseConditionInput
  ) {
    createSalesPitchResponse(input: $input, condition: $condition) {
      id
      salesPitch {
        id
        userId
        persona
        prompt
        createdAt
        updatedAt
        __typename
      }
      status
      result
      shortDescription
      fiveQuestionsOpenConversation
      fiveArguments
      fiveQuestionsPains
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSalesPitchResponse = /* GraphQL */ `
  mutation UpdateSalesPitchResponse(
    $input: UpdateSalesPitchResponseInput!
    $condition: ModelSalesPitchResponseConditionInput
  ) {
    updateSalesPitchResponse(input: $input, condition: $condition) {
      id
      salesPitch {
        id
        userId
        persona
        prompt
        createdAt
        updatedAt
        __typename
      }
      status
      result
      shortDescription
      fiveQuestionsOpenConversation
      fiveArguments
      fiveQuestionsPains
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSalesPitchResponse = /* GraphQL */ `
  mutation DeleteSalesPitchResponse(
    $input: DeleteSalesPitchResponseInput!
    $condition: ModelSalesPitchResponseConditionInput
  ) {
    deleteSalesPitchResponse(input: $input, condition: $condition) {
      id
      salesPitch {
        id
        userId
        persona
        prompt
        createdAt
        updatedAt
        __typename
      }
      status
      result
      shortDescription
      fiveQuestionsOpenConversation
      fiveArguments
      fiveQuestionsPains
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createComponents = /* GraphQL */ `
  mutation CreateComponents(
    $input: CreateComponentsInput!
    $condition: ModelComponentsConditionInput
  ) {
    createComponents(input: $input, condition: $condition) {
      id
      userId
      componentName
      command
      currentCode
      isAdjust
      html
      css
      fullPromptMessages
      status
      openAiFullResponse
      aiResponseFull
      newImports
      newComponent
      isFavorite
      upvotes
      downvotes
      price
      usage
      purchases
      visibility
      isPublic
      responseReceived
      tags
      systemFavorite
      systemRank
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateComponents = /* GraphQL */ `
  mutation UpdateComponents(
    $input: UpdateComponentsInput!
    $condition: ModelComponentsConditionInput
  ) {
    updateComponents(input: $input, condition: $condition) {
      id
      userId
      componentName
      command
      currentCode
      isAdjust
      html
      css
      fullPromptMessages
      status
      openAiFullResponse
      aiResponseFull
      newImports
      newComponent
      isFavorite
      upvotes
      downvotes
      price
      usage
      purchases
      visibility
      isPublic
      responseReceived
      tags
      systemFavorite
      systemRank
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteComponents = /* GraphQL */ `
  mutation DeleteComponents(
    $input: DeleteComponentsInput!
    $condition: ModelComponentsConditionInput
  ) {
    deleteComponents(input: $input, condition: $condition) {
      id
      userId
      componentName
      command
      currentCode
      isAdjust
      html
      css
      fullPromptMessages
      status
      openAiFullResponse
      aiResponseFull
      newImports
      newComponent
      isFavorite
      upvotes
      downvotes
      price
      usage
      purchases
      visibility
      isPublic
      responseReceived
      tags
      systemFavorite
      systemRank
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      userId
      name
      credits
      lastPlanChange
      subscriptionLevel
      subscriptionLevelUpdated
      customCompoenentsLeft
      selectedPlan
      maxOwnComponents
      includedCredits
      email
      dateOfPlanSelection
      paymentHistory
      referral
      referredUsers
      birthdate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      userId
      name
      credits
      lastPlanChange
      subscriptionLevel
      subscriptionLevelUpdated
      customCompoenentsLeft
      selectedPlan
      maxOwnComponents
      includedCredits
      email
      dateOfPlanSelection
      paymentHistory
      referral
      referredUsers
      birthdate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      userId
      name
      credits
      lastPlanChange
      subscriptionLevel
      subscriptionLevelUpdated
      customCompoenentsLeft
      selectedPlan
      maxOwnComponents
      includedCredits
      email
      dateOfPlanSelection
      paymentHistory
      referral
      referredUsers
      birthdate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFeedback = /* GraphQL */ `
  mutation CreateFeedback(
    $input: CreateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    createFeedback(input: $input, condition: $condition) {
      id
      userId
      feedback
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFeedback = /* GraphQL */ `
  mutation UpdateFeedback(
    $input: UpdateFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    updateFeedback(input: $input, condition: $condition) {
      id
      userId
      feedback
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFeedback = /* GraphQL */ `
  mutation DeleteFeedback(
    $input: DeleteFeedbackInput!
    $condition: ModelFeedbackConditionInput
  ) {
    deleteFeedback(input: $input, condition: $condition) {
      id
      userId
      feedback
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReferal = /* GraphQL */ `
  mutation CreateReferal(
    $input: CreateReferalInput!
    $condition: ModelReferalConditionInput
  ) {
    createReferal(input: $input, condition: $condition) {
      id
      user
      referredUsers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReferal = /* GraphQL */ `
  mutation UpdateReferal(
    $input: UpdateReferalInput!
    $condition: ModelReferalConditionInput
  ) {
    updateReferal(input: $input, condition: $condition) {
      id
      user
      referredUsers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReferal = /* GraphQL */ `
  mutation DeleteReferal(
    $input: DeleteReferalInput!
    $condition: ModelReferalConditionInput
  ) {
    deleteReferal(input: $input, condition: $condition) {
      id
      user
      referredUsers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listComponentsPublic = /* GraphQL */ `
  mutation ListComponentsPublic($event: String) {
    listComponentsPublic(event: $event)
  }
`;
