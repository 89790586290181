/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const startGeneration = /* GraphQL */ `
  query StartGeneration($input: MessageInput!) {
    startGeneration(input: $input) {
      id
      salesPitch {
        id
        userId
        persona
        prompt
        createdAt
        updatedAt
        __typename
      }
      status
      result
      shortDescription
      fiveQuestionsOpenConversation
      fiveArguments
      fiveQuestionsPains
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSalesPitchRequest = /* GraphQL */ `
  query GetSalesPitchRequest($id: ID!) {
    getSalesPitchRequest(id: $id) {
      id
      userId
      persona
      prompt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSalesPitchRequests = /* GraphQL */ `
  query ListSalesPitchRequests(
    $filter: ModelSalesPitchRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalesPitchRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        persona
        prompt
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSalesPitchResponse = /* GraphQL */ `
  query GetSalesPitchResponse($id: ID!) {
    getSalesPitchResponse(id: $id) {
      id
      salesPitch {
        id
        userId
        persona
        prompt
        createdAt
        updatedAt
        __typename
      }
      status
      result
      shortDescription
      fiveQuestionsOpenConversation
      fiveArguments
      fiveQuestionsPains
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSalesPitchResponses = /* GraphQL */ `
  query ListSalesPitchResponses(
    $filter: ModelSalesPitchResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSalesPitchResponses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        salesPitch {
          id
          userId
          persona
          prompt
          createdAt
          updatedAt
          __typename
        }
        status
        result
        shortDescription
        fiveQuestionsOpenConversation
        fiveArguments
        fiveQuestionsPains
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComponents = /* GraphQL */ `
  query GetComponents($id: ID!) {
    getComponents(id: $id) {
      id
      userId
      componentName
      command
      currentCode
      isAdjust
      html
      css
      fullPromptMessages
      status
      openAiFullResponse
      aiResponseFull
      newImports
      newComponent
      isFavorite
      upvotes
      downvotes
      price
      usage
      purchases
      visibility
      isPublic
      responseReceived
      tags
      systemFavorite
      systemRank
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listComponents = /* GraphQL */ `
  query ListComponents(
    $filter: ModelComponentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComponents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        componentName
        command
        currentCode
        isAdjust
        html
        css
        fullPromptMessages
        status
        openAiFullResponse
        aiResponseFull
        newImports
        newComponent
        isFavorite
        upvotes
        downvotes
        price
        usage
        purchases
        visibility
        isPublic
        responseReceived
        tags
        systemFavorite
        systemRank
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      userId
      name
      credits
      lastPlanChange
      subscriptionLevel
      subscriptionLevelUpdated
      customCompoenentsLeft
      selectedPlan
      maxOwnComponents
      includedCredits
      email
      dateOfPlanSelection
      paymentHistory
      referral
      referredUsers
      birthdate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        name
        credits
        lastPlanChange
        subscriptionLevel
        subscriptionLevelUpdated
        customCompoenentsLeft
        selectedPlan
        maxOwnComponents
        includedCredits
        email
        dateOfPlanSelection
        paymentHistory
        referral
        referredUsers
        birthdate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFeedback = /* GraphQL */ `
  query GetFeedback($id: ID!) {
    getFeedback(id: $id) {
      id
      userId
      feedback
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listFeedbacks = /* GraphQL */ `
  query ListFeedbacks(
    $filter: ModelFeedbackFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeedbacks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        feedback
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReferal = /* GraphQL */ `
  query GetReferal($id: ID!) {
    getReferal(id: $id) {
      id
      user
      referredUsers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReferals = /* GraphQL */ `
  query ListReferals(
    $filter: ModelReferalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user
        referredUsers
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
