import { API, Auth, graphqlOperation } from "aws-amplify"
import { createPersona, createSalesPitch, createSalesPitchLongFunction, createSalesPitchFunction, listComponentsPublic } from './../graphql/mutations'
import {listComponents } from './../graphql/queries'
import * as queries from './../graphql/queries'
import * as mutations from './../graphql/mutations'

import { onSalesPitchResponse, onUpdateSalesPitchResponse, onUpdateComponents } from "../graphql/subscriptions"

/* export const RateItemCloud = async (obj:any) =>  {
    try {
     
     let job = obj
     let jobString = JSON.stringify(job)
     console.log(jobString)
      let success = await API.graphql(graphqlOperation(rateItemCloud, {event:jobString}))
      return success
    } catch (err) {
      console.log('error RateItemCloud:', err)
    }
  } */
export const UpdateCode = async (obj:any) =>  {
    try {
     
     let job = {}
     job.newComponent = obj.newComponent
     job.id = obj.id
     job.componentName = obj.componentName
     /* let jobString = JSON.stringify(job) */
     
      let success = await API.graphql(graphqlOperation(mutations.updateComponents, {input:job}))
      return success
    } catch (err) {
      console.log('error UpdateCode:', err)
    }
  }
export const UpdateComponent = async (obj:any) =>  {
    try {
      let success = await API.graphql(graphqlOperation(mutations.updateComponents, {input:obj}))
      return success
    } catch (err) {
      console.log('error UpdateCode:', err)
    }
  }

export const UpdateUser = async (obj:any) =>  {
    try {
      let success = await API.graphql(graphqlOperation(mutations.updateUser, {input:obj}))
      return success
    } catch (err) {
      console.log('error UpdateUser:', err)
    }
  }
export const CreateUser = async (obj:any) =>  {
    try {
      let success = await API.graphql(graphqlOperation(mutations.createUser, {input:obj}))
      return success
    } catch (err) {
      console.log('error CreateUser:', err)
    }
  }
export const CreateFeedback = async (obj:any) =>  {
    try {
      let success = await API.graphql(graphqlOperation(mutations.createFeedback, {input:obj}))
      return success
    } catch (err) {
      console.log('error CreateFeedback:', err)
    }
  }
export const GetReferal = async (obj:any) =>  {
    try {
      let success = await API.graphql(graphqlOperation(queries.getReferal, {input:obj}))
      return success
    } catch (err) {
      console.log('error GetReferal:', err)
    }
  }
export const CreateUserCloud = async (obj:any) =>  {
  let stringifyObj = JSON.stringify(obj)
    try {
      let success = await API.graphql(graphqlOperation(mutations.registerUser, {event:stringifyObj}))
      return success
    } catch (err) {
      console.log('error CreateUser:', err)
    }
  }
export const deleteComponent = async (obj:any) =>  {
    try {
     
     let job = {}
     
     job.id = obj.id
     /* let jobString = JSON.stringify(job) */
     
      let success = await API.graphql(graphqlOperation(mutations.deleteComponents, {input:job}))
      return success
    } catch (err) {
      console.log('error UpdateCode:', err)
    }
  }
export const GetUser = async (id:any) =>  {
    try {
     
 
      let success = await API.graphql(graphqlOperation(queries.getUser, {id:id}))
      return success
    } catch (err) {
      console.log('error GetUser:', err)
    }
  }

  export const UpdateUserPlan = async (id, currentPlan, newPlan) =>  {
    try {
      let obj = {id: id, currentPlan: currentPlan, newPlan: newPlan}
      let stringifiedObj = JSON.stringify(obj)
      console.log('UpdateUserPlan', obj)
      let success = await API.graphql(graphqlOperation(mutations.updateUserPlan, {event: stringifiedObj}))
      console.log(success)
      if(success && success.data && success.data.updateUserPlan){
        let response = JSON.parse(success.data.updateUserPlan)
       
        return response
        
      }
    }
    catch (err) {
      console.log('error UpdateUserPlan:', err)
      return false
    }
  }
/* export const RateItemCloudPublic = async (obj:any) =>  {
    try {
     
     let job = obj
     let jobString = JSON.stringify(job)
     console.log(jobString)
      let success = await API.graphql(
          {
            query: mutations.rateItemCloud,
            authMode: 'AWS_IAM',
            variables: {
                event: jobString
            },
          }
        
      )
      return success
    } catch (err) {
      console.log('error RateItemCloud:', err)
    }
  } */
/* export const RateItemDirect = async (obj:any) =>  {
  
    try {
      let success = await API.graphql(
        {
          query: mutations.createUservotes,
          authMode: 'AWS_IAM',
          variables: {
              input: obj
          },
        }
      )
      return success
    } catch (err) {
      console.log('error RateItemDirect:', err)
    }
  } */
/* export const SubscribeEmailList = async (obj:any) =>  {
  
    try {
      let success = await API.graphql(
        {
          query: mutations.createPersona,
          authMode: 'AWS_IAM',
          variables: {
              input: obj
          },
        }
      )
      return success
    } catch (err) {
      console.log('error SubscribeEmailList:', err)
    }
  } */
/* export const SendContactMessage = async (obj:any) =>  {
  
    try {
      let success = await API.graphql(
        {
          query: mutations.createContactMessages,
          authMode: 'AWS_IAM',
          variables: {
              input: obj
          },
        }
      )
      return success
    } catch (err) {
      console.log('error SendContactMessage:', err)
    }
  } */

export const CreatePersona = async (obj:any) =>  {
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = obj
     let jobString = JSON.stringify(job)
     console.log(jobString)
      let success = await API.graphql(graphqlOperation(createPersona, {event:jobString}))
      /* let success = await API.graphql(
        {
          query: createPersona,
          authMode: 'AWS_IAM',
          variables: {
            event: jobString
          },
        }
      ) */
      return success
    } catch (err) {
      console.log('error CreatePersona:', err)
    }
  }
  export const CreateNewComponent = async (obj: any) => {
    let subId = null;
    try {
      let job = obj;
      let jobString = JSON.stringify(job);
      /* let input = { text: "engineer male 30 chemical" }; */
      let input = { text: jobString};
      console.log(jobString);
      let sendPitchResponse = await API.graphql(
        graphqlOperation(createSalesPitchFunction, { event: input.text })
        /* graphqlOperation(createSalesPitchLongFunction, { input: input }) */
      );
      console.log("sendPitchResponse:", sendPitchResponse);
      if (sendPitchResponse.data.createSalesPitchFunction) {
        let response = JSON.parse(
          sendPitchResponse.data.createSalesPitchFunction
        );
        console.log("response:", response);
        if(response.status === "NO CREDITS"){
          return response.status
        }
        
        subId = response[0].body.id;
      }
      console.log("subId:", subId);
    } catch (err) {
      console.log("error createSalesPitch:", err);
    }
  

      let resAi =  await SubscribeToCreateNewComponent(subId);
      return resAi
    };  

    export const SubscribeToCreateNewComponent = async (id: any) => {
      let subId = id;
        try {
          let response = await new Promise((resolve, reject) => {
            const subscription = API.graphql(
              graphqlOperation(onUpdateComponents, { id: subId })
            ).subscribe({
              next: (response) => {
                console.log("Received response:", response);
                subscription.unsubscribe(); // Unsubscribe when response is received
                resolve(response);
              },
              error: (error) => {
                console.log("Error in subscription:", error);
                reject(error);
              },
            });
    
            // Set a timeout to reject the promise if no response is received within 10 seconds
            setTimeout(() => {
              subscription.unsubscribe();
              reject(new Error("Timeout - No response received"));
            }, 600000);
          });
          return response;
        } catch (err) {
          console.log("error onSalesPitchResponse:", err);
        }
      
    };
  export const CreateSalesPitch = async (obj: any) => {
    let subId = null;
    try {
      let job = obj;
      let jobString = JSON.stringify(job);
      /* let input = { text: "engineer male 30 chemical" }; */
      let input = { text: jobString};
      console.log(jobString);
      let sendPitchResponse = await API.graphql(
        graphqlOperation(createSalesPitchFunction, { event: jobString })
        //graphqlOperation(createSalesPitchLongFunction, { input: input })
      );
      console.log("sendPitchResponse:", sendPitchResponse);
      if (sendPitchResponse.data.createSalesPitchFunction) {
        let response = JSON.parse(
          sendPitchResponse.data.createSalesPitchFunction
        );
        console.log("response:", response);
        console.log(response);
        subId = response.body.id;
      }
      console.log("subId:", subId);
    } catch (err) {
      console.log("error createSalesPitch:", err);
    }
  
  let resAi =  await SubscribeToSalesPitchResponse(subId);
  return resAi
    };  

    export const SubscribeToSalesPitchResponse = async (id: any) => {
      let subId = id;
        try {
          let response = await new Promise((resolve, reject) => {
            const subscription = API.graphql(
              graphqlOperation(onUpdateSalesPitchResponse, { id: subId })
            ).subscribe({
              next: (response) => {
                console.log("Received response:", response);
                subscription.unsubscribe(); // Unsubscribe when response is received
                resolve(response);
              },
              error: (error) => {
                console.log("Error in subscription:", error);
                reject(error);
              },
            });
    
            // Set a timeout to reject the promise if no response is received within 10 seconds
            setTimeout(() => {
              subscription.unsubscribe();
              reject(new Error("Timeout - No response received"));
            }, 300000);
          });
          return response;
        } catch (err) {
          console.log("error onSalesPitchResponse:", err);
        }
      
    };

  export const ListComponents1 = async () =>  {
    try {
      let success = await API.graphql(
        {
          query: queries.listComponents,
          authMode: 'User_Pool',
        }
      )
     
      
      return success
    } catch (err) {
      console.log('error ListItems:', err)
    }
  }

  export const ListComponents = async () =>  {
    let user = await UserLoggedIN()
    console.log(user)
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = {}
     
     
      let success = await API.graphql(graphqlOperation(queries.listComponents, {filter:{isPublic:{eq:true}}}))  //, userId: {ne: user.username}
      let next = success.data.listComponents.nextToken
        if(next == null){
          return success
        }
      let items = success.data.listComponents.items
      console.log(success)
      while (next) {
        let success = await API.graphql(graphqlOperation(queries.listComponents, {event:"", nextToken: next}))
        next = success.data.listComponents.nextToken
        items = items.concat(success.data.listComponents.items)
      }
      success.data.listComponents.items = items
      
      console.log(items)
      return success
    } catch (err) {
      console.log('error ListComponents :', err)
    }
  }
  export const ListMyComponents = async (userId1) =>  {
    console.log(userId1)
    try {
     /* let job = {Duration: "PT20", Price: 99, uidCreator: uid} */
     let job = {}
     
     
      let success = await API.graphql(graphqlOperation(listComponents, {filter:{userId: {eq: userId1}}}))
      console.log(success)
      let next = success.data.listComponents.nextToken
        if(next == null){
          return success
        }
      let items = success.data.listComponents.items
      console.log(success)
      while (next) {
        let success = await API.graphql(graphqlOperation(listComponents, { filter:{userId: {eq: userId1}}, nextToken: next}))
        next = success.data.listComponents.nextToken
        items = items.concat(success.data.listComponents.items)
      }
      success.data.listComponents.items = items
      
      console.log(items)
      return success
    } catch (err) {
      console.log('error ListMyComponents:', err)
    }
  }

  export const AddToMyComponents = async (c) =>  {
    console.log(c)
    let user = await UserLoggedIN()
    console.log(user)
    try {
     let job = {"userId":user.username , "newComponent":c.newComponent, "command": "This component has been inspired from an existing community component", "newImports":c.newImports, "componentName": c.componentName}
      let res = await API.graphql(graphqlOperation(mutations.createComponents, {input: job}))
      return res
    } catch (err) {
      console.log('error creating vendor:', err)
    }
  }

  export const GetLibraryPublic = async () =>  {
    try {
     
     
      let success = await API.graphql(
        {
          query: mutations.listComponentsPublic,
          authMode: 'AWS_IAM',
          variables: {
            input: {
              event: ""
            }
          }
        }
      )
      /* let success = await API.graphql(graphqlOperation(
        getReferal,  {id: id},  {authMode: 'AWS_IAM'}
          
      )
        ) */
      
      return success
    } catch (err) {
      console.log('error GetLibraryPublic:', err)
    }
  }

  

  export const UserLoggedIN = async () =>  {
    try {
      /* let user = await Auth.currentUserInfo() */
      let user = await Auth.currentAuthenticatedUser()
      console.log(user)
      if(user){
        return user
      }
      
    }
    catch (err) {
      console.log('error UserLoggedIN:', err)
    }
  }
  export const IsAdmin = async () =>  {
    try {
      let ref = await Auth.currentAuthenticatedUser()
      console.log(ref)
      let res = false
      if(ref.signInUserSession.idToken.payload['cognito:groups']){
        if(ref.signInUserSession.idToken.payload['cognito:groups'].includes("Admins")){
          res = true
        }
      }
      return res
    }
    catch (err) {
      console.log('error IsAdmin:', err)
    }
  }

  /* export const AddToWaitingList = async (email) =>  {
    try {
     
     
     
     
     
      let success = await API.graphql(
        {
          query: mutations.createWaitlist,
          authMode: 'AWS_IAM',
          variables: {
              input: {
                email: email
              }
          },
        }
      )
     
      
      return success
    } catch (err) {
      console.log('error getting referal:', err)
    }
  } */