import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  CardActionArea,
  CardMedia,
  Typography,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Grid,
  Button,
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import styled from '@emotion/styled';
import PersonaSelector from './personas';
import { CreatePersona, CreateSalesPitch } from '../services/graphQlService';
import SalesPitch from './SalesPitch';
import FAQs from './FAQ';
import { Text } from '@aws-amplify/ui-react';




let ClusterPositions = {
  'Chemical Industry': [
    'Chemical engineer',
    'Process engineer',
    'Chemical plant manager',
    'Quality control manager',
    'Laboratory technician',
    'Safety officer',
    'Environmental compliance specialist',
  ],
  'Food and Beverage Industry': [
    'Food scientist',
    'Food technologist',
    'Production manager',
    'Quality assurance manager',
    'Packaging engineer',
    'Food safety specialist',
    'Plant supervisor',
  ],
  'Water and Wastewater Industry': [
    'Water treatment operator',
    'Wastewater treatment operator',
    'Water resource engineer',
    'Water quality analyst',
    'Maintenance technician',
    'Utility manager',
    'Environmental technician',
  ],
  'Oil and Gas Industry': [
    'Petroleum engineer',
    'Geologist',
    'Drilling engineer',
    'Pipeline engineer',
    'Refinery manager',
    'Production manager',
    'Health, safety, and environmental specialist',
  ],
  'Multi-Industry Skid Builder': [
    'Mechanical engineer',
    'Electrical engineer',
    'Control system engineer',
    'Fabrication specialist',
    'Project manager',
    'Quality control inspector',
    'Supply chain manager',
  ],
  'Research and Development': [
    'R&D scientist',
    'R&D engineer',
    'Project leader',
    'Intellectual property specialist',
    'Regulatory affairs manager',
    'Technology transfer specialist',
    'Innovation manager',
  ],
  'Sales and Marketing': [
    'Sales engineer',
    'Sales manager',
    'Business development manager',
    'Marketing specialist',
    'Product manager',
    'Account manager',
    'Customer service representative',
  ],
};

const StyledCard = styled(Card)`
  margin: 16px;
  &:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  }
`;



    

const ageRanges = ['27-35', '36-44', '45-54', '55-65'];
const genders = ['Male', 'Female', 'Other'];

const CombinedComponentAdjusted = (props) => {
  console.log(props);
  const [selectedCluster, setSelectedCluster] = useState('');
  const [selectedPosition, setSelectedPosition] = useState('');
  const [selectedAgeRange, setSelectedAgeRange] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedPersona, setselectedPersona] = useState({});
  const [positions, setPositions] = useState([]);
  const [personas, setPersonas] = useState([]);
  const [generatedPersonas, setGeneratedPersonas] = useState([]);
  const [salesPitch, setSalesPitch] = useState({});
  const [salesPitchQuestions, setSalesPitchQuestions] = useState('');
  const [salesPitchPainPoints, setSalesPitchPainPoints] = useState('');
  const [summary, setSummary] = useState('');
  

  function adjustJobs() {
    console.log(ClusterPositions);
    let array = Object.entries(ClusterPositions);
    console.log(array);

    let genericTitles = [
      'Technician',
      'Engineer',
      'Manager',
      'Department Head',
      'Division Head',
      'Vice President',
    ];
    let newObject = {};

    array.forEach((item, i) => {
      newObject[array[i][0]] = [...array[i][1], ...genericTitles];
    });
    setPersonas(newObject);
    return newObject;
  }

  const handleClusterChange = (event) => {
    const cluster = event.target.value;
    setSelectedCluster(cluster);
    setPositions(personas[cluster] || []);
    setSelectedPosition('');
  };

  const handlePositionChange = (event) => {
    setSelectedPosition(event.target.value);
  };

  const handleAgeRangeChange = (event) => {
    setSelectedAgeRange(event.target.value);
  };

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  const generatePersona = async () => {
    let newPersona = {
      cluster: selectedCluster,
      position: selectedPosition,
      ageRange: selectedAgeRange,
      gender: selectedGender,
    };
    
    let response = await CreatePersona(newPersona);
    console.log({response});
    let responseDetail = response.data.createPersona;
    console.log({responseDetail});
    let responseObject = JSON.parse(responseDetail);
    console.log({responseObject});
    setGeneratedPersonas(responseObject)
    
   
  };

  const createSalesPitchLocal = async () => {
    console.log(selectedPersona);
    let salesPitch = await CreateSalesPitch(selectedPersona)
    console.log({salesPitch});
    console.log(salesPitch.value.data.onUpdateSalesPitchResponse.fiveQuestionsOpenConversation)
    if(salesPitch.value.data.onUpdateSalesPitchResponse){
    setSalesPitchQuestions(salesPitch.value.data.onUpdateSalesPitchResponse);
    props.updateVariableFromChildQuestions(salesPitch.value.data.onUpdateSalesPitchResponse);
    }
    
}

const updateParentVariable = () => {
  console.log(generatedPersonas);
 /*  if(generatedPersonas.length > 0){
  props.updateParentVariable(generatedPersonas);
  } */
  console.log(salesPitchQuestions);
  if(salesPitchQuestions){
    
  props.updateVariableFromChildQuestions(salesPitchQuestions);
  }
  
}

const updateFromPersonaSelector = (persona) => {
  setselectedPersona(persona);
}


  useEffect(() => {
    
    if(props.activeStep === 0){
      adjustJobs();
    }
    if(props.activeStep === 1){
      /* updateParentVariable() */
      console.log(salesPitchQuestions);
      /* console.log(generatedPersonas);
      createSalesPitchLocal(generatedPersonas[0]); */
    }
    
  }, [generatedPersonas]);

  

 

  return (
    <>
    {props.activeStep === 1 && <Grid
    style={{width:'450px'}}
      container
      spacing={3}
      sx={{
        justifyContent: 'center',
        verticalAlign: 'middle',
        alignItems: 'center',
      }}
    >
      <Grid item xs={13}>
        Introduction
        {/* {props.salesPitchQuestions.result && <Text>{props.salesPitchQuestions.result}</Text>} */}
        
        
        {props.salesPitchQuestions.result && 
        <>
        <ul>
        {props.salesPitchQuestions.result.split('|').map((item, index) => {

        return(
          <>
          {index === 0 ? <Text style={{margin:'1em'}}>{item}</Text> :
          <li>{item}</li>}
          </>
        )
        
        }) }
        </ul>
        </>}
        {props.salesPitchQuestions && <FAQs content={props.salesPitchQuestions} />}
      </Grid>
     
     
    </Grid>}

    {props.activeStep === 0 &&
     <Grid
    style={{width:'100%'}}
      container
      spacing={3}
      sx={{
        justifyContent: 'center',
        verticalAlign: 'middle',
        alignItems: 'center',
      }}
    >
      {salesPitchQuestions.salesPitchQuestions && <FAQs content={salesPitchQuestions.salesPitchQuestions} />}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Select value={selectedCluster} onChange={handleClusterChange} displayEmpty>
            <MenuItem value="">
              <em>Select a cluster</em>
            </MenuItem>
            {Object.keys(ClusterPositions).map((cluster) => (
              <MenuItem key={cluster} value={cluster}>
                {cluster}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <Select
            value={selectedPosition}
            onChange={handlePositionChange}
            disabled={!selectedCluster}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select a position</em>
            </MenuItem>
            {positions.map((position) => (
              <MenuItem key={position} value={position}>
                {position}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <Select
            value={selectedAgeRange}
            onChange={handleAgeRangeChange}
            disabled={!selectedPosition}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select an age range</em>
            </MenuItem>
            {ageRanges.map((ageRange) => (
              <MenuItem key={ageRange} value={ageRange}>
                {ageRange}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <Select
            value={selectedGender}
            onChange={handleGenderChange}
            disabled={!selectedAgeRange}
            displayEmpty
          >
            <MenuItem value="">
              <em>Select a gender</em>
            </MenuItem>
            {genders.map((gender) => (
              <MenuItem key={gender} value={gender}>
                {gender}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button variant="contained" onClick={generatePersona} >Generate</Button>
        <Button variant="contained" onClick={createSalesPitchLocal} >Generate Sales Pitch</Button>
        <Button variant="contained" onClick={updateParentVariable} >Update Parent</Button>
      </Grid>
      <Grid item xs={12}>
      {generatedPersonas && <PersonaSelector updateFromPersonaSelector={setselectedPersona} personas={generatedPersonas} />}
      </Grid>
     {/*  <Grid item xs={12}>
        {generatedPersonas.length > 0 ? (
          <Grid container spacing={3}>
            {generatedPersonas.map((persona, index) => (
              <Grid item key={index} xs={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      Persona {index + 1}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {persona}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body2" color="textSecondary" component="p">
            Select attributes to generate personas
          </Typography>
        )}
      </Grid> */}
    </Grid>}
    </>
  );
};

export default CombinedComponentAdjusted;