import styled from "@emotion/styled";
import { Box, Container, Grid, Link, Typography } from "@mui/material";

const FooterWrapper = styled(Box)({
    backgroundColor: '#f5f5f5',
    paddingLeft: '2em',
    marginTop: '2em',
    paddingTop: '2em',
    height: '300px',
    paddingBottom: '2em',
    /* borderRadius: '2em', */
  });
  
  const FooterComponent = () => {
    const subLinks = [
      {
        title: 'Company',
        links: [
          { text: 'Home', href: '/' },
          { text: 'Library', href: '/library' },
          { text: 'Pricing', href: '/pricing' },
          /* { text: 'Careers', href: '/careers' },
          { text: 'Blog', href: '/blog' }, */
        ],
      },
      {
        title: 'Support',
        links: [
          /* { text: 'Help Center', href: '/help' },
          { text: 'Contact Us', href: '/contact' }, */
          { text: 'FAQs', href: '/pricing' },
        ],
      },
      {
        title: 'Legal',
        links: [
          { text: 'Terms of Service', href: '/tos' },
          { text: 'Privacy Policy', href: '/privacy' },
          { text: 'Imprint', href: '/imprint' },
          /* { text: 'Cookie Policy', href: '/cookie-policy' }, */
        ],
      },
    ];
  
    return (
        <Container >
      <FooterWrapper>
          <Grid container spacing={2}>
            {subLinks.map((subsection, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <Typography variant="h6" gutterBottom>
                  {subsection.title}
                </Typography>
                <Box>
                  {subsection.links.map((link, i) => (
                    <Typography key={i} variant="body2" gutterBottom>
                      <Link href={link.href}>{link.text}</Link>
                    </Typography>
                  ))}
                </Box>
              </Grid>
            ))}
          </Grid>
         
          
      </FooterWrapper>
      <Grid container  style={{ backgroundColor:"#1876d2", justifyContent:"center" /* , marginTop:'0.5em' */}}>
            <Grid item xs={12} sm={12} md={12} style={{textAlign:"center", marginBottom: '1em', marginTop:'1em'}}>
              <Typography variant="h12" gutterBottom style={{color:"white"}}>
                Coded with ❤️ in Switzerland. Powered by <img
            style={{ width: "30px", marginLeft: "0.5em", marginRight: "0.5em", marginTop: "0.5em", marginBottom: '-0.5em'}}
            src={'aws.png'}
            alt="Amazon Web Services"
          />  & <img
          style={{ width: "30px", marginLeft: "0.5em", marginRight: "0.5em", marginTop: "0.5em", marginBottom: '-0.5em'}}
          src={'GPT4.png'}
          alt="Open AI GPT4"
        /> 
               </Typography>
              
            </Grid>
          </Grid>
        </Container>
    );
  };
  
  export default FooterComponent 