import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Grid, Paper, Avatar, CardHeader, Chip, Badge, IconButton, MenuItem, FormControl, Select, Tooltip, Switch, FormControlLabel } from '@mui/material';
import { useSpring, animated } from '@react-spring/web';
import styled from '@emotion/styled';


const GridItem = styled(Grid)({
  padding: '1em',
});

const SnakeGame = () => {
  const [score, setScore] = useState(0);
  const [snake, setSnake] = useState([]);
  const [food, setFood] = useState({});
  const [direction, setDirection] = useState('right');
  const [gameOver, setGameOver] = useState(false);

  useEffect(() => {
    initSnake();
    initFood();
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  useEffect(() => {
    if (gameOver) return;
    const timer = setTimeout(moveSnake, 200);
    return () => clearTimeout(timer);
  }, [snake]);

  const initSnake = () => {
    const initialSnake = [
      { x: 10, y: 10 },
      { x: 11, y: 10 },
      { x: 12, y: 10 },
    ];
    setSnake(initialSnake);
  };

  const initFood = () => {
    const newFood = {
      x: Math.floor(Math.random() * 20),
      y: Math.floor(Math.random() * 20),
    };
    setFood(newFood);
  };

  const onKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowUp':
        setDirection('up');
        break;
      case 'ArrowDown':
        setDirection('down');
        break;
      case 'ArrowLeft':
        setDirection('left');
        break;
      case 'ArrowRight':
        setDirection('right');
        break;
      default:
        break;
    }
  };

  const moveSnake = () => {
    let newSnake = [...snake];
    let head = { ...newSnake[newSnake.length - 1] };

    switch (direction) {
      case 'up':
        head.y -= 1;
        break;
      case 'down':
        head.y += 1;
        break;
      case 'left':
        head.x -= 1;
        break;
      case 'right':
        head.x += 1;
        break;
      default:
        break;
    }

    newSnake.push(head);
    newSnake.shift();

    if (head.x === food.x && head.y === food.y) {
      setScore(score + 1);
      newSnake.unshift(newSnake[0]);
      initFood();
    }

    if (head.x < 0 || head.x >= 20 || head.y < 0 || head.y >= 20 || newSnake.slice(0, -1).some((s) => s.x === head.x && s.y === head.y)) {
      setGameOver(true);
      return;
    }

    setSnake(newSnake);
  };

  const resetGame = () => {
    setGameOver(false);
    setScore(0);
    initSnake();
    initFood();
    setDirection('right');
  };

  const animProps = useSpring({
    opacity: gameOver ? 0 : 1,
    config: { duration: 200 },
  });

  return (
    <Container>
      <Typography variant="h3" component="h1" gutterBottom>
        Snake Game
      </Typography>
      <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column">
        <GridItem item xs={12}>
          <Paper>
            <Box width={400} height={400} position="relative" bgcolor="black">
              {snake.map((s, i) => (
                <Box
                  key={i}
                  position="absolute"
                  left={s.x * 20}
                  top={s.y * 20}
                  width={20}
                  height={20}
                  bgcolor={i === snake.length - 1 ? 'limegreen' : 'green'}
                />
              ))}
              <Box position="absolute" left={food.x * 20} top={food.y * 20} width={20} height={20} bgcolor="red" />
            </Box>
          </Paper>
        </GridItem>
        <GridItem item xs={12}>
          <Typography variant="h6" component="h2" gutterBottom>
            Score: {score}
          </Typography>
        </GridItem>
        {gameOver && (
          <GridItem item xs={12}>
            <Typography variant="h6" component="h2" gutterBottom>
              Game Over!
            </Typography>
          </GridItem>
        )}
        <GridItem item xs={12}>
          <IconButton onClick={resetGame} >
            <Badge  variant="dot" >
              <Avatar style={{width:"140px", height:"140px"}}>Reset</Avatar>
            </Badge>
          </IconButton>
        </GridItem>
      </Grid>
    </Container>
  );
};


export default SnakeGame 