import React from 'react';
import {
  Box,
  Button,
  Typography,
  Rating,
  Divider,
} from '@mui/material';

import styled from '@emotion/styled'

const Card = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  border: '1px solid #ddd',
  borderRadius: 4,
  padding: 16,
  marginBottom: 24,
});

const ImageWrapper = styled(Box)({
  position: 'relative',
  width: 200,
  height: 200,
  marginRight: 16,
});

const Image = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: 4,
});

const Ribbon = styled(Box)({
  position: 'absolute',
  top: 8,
  left: 8,
  backgroundColor: 'blue',
  padding: '4px 8px',
  color: 'white',
  borderRadius: 4,
});

const Content = styled(Box)({
  flexGrow: 1,
});

const PropertyTitle = styled(Typography)({
  fontSize: 18,
  fontWeight: 'bold',
  marginBottom: 8,
});

const Location = styled(Typography)({
  textDecoration: 'none',
  color: '#333',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const Price = styled(Typography)({
  fontSize: 24,
  fontWeight: 'bold',
});

const AvailabilityButton = styled(Button)(({ theme }) => ({
  marginTop: 16,
  textTransform: 'none',
  backgroundColor: '#333',
  color: 'white',
  '&:hover': {
    backgroundColor: 'darkblue',
  },
}));

function AnotherPropertyCard() {
  return (
    <Card>
      <ImageWrapper>
        <Ribbon>Breakfast included</Ribbon>
        <Image
          src="https://via.placeholder.com/200x200"
          alt="Nikolakakis Rooms Lavrio"
        />
      </ImageWrapper>
      <Content>
        <PropertyTitle>Nikolakakis Rooms Lavrio</PropertyTitle>
        <Rating value={3} readOnly />
        <Typography variant="subtitle2" gutterBottom>
          Lavrio
        </Typography>
        <Location href="#">Show on map</Location>
        <Box my={2}>
          <Divider />
        </Box>
        <Typography variant="subtitle2">Double Room with Balcony</Typography>
        <Typography variant="body2" gutterBottom>
          3 beds (2 twins, 1 sofa bed)
        </Typography>
        <Typography variant="body2" gutterBottom>
          Free cancellation – no prepayment necessary
        </Typography>
        <Price>CHF 104</Price>
        <AvailabilityButton variant="contained">
          See availability
        </AvailabilityButton>
      </Content>
    </Card>
  );
}

export default AnotherPropertyCard;