
import CheckCircle from "@mui/icons-material/CheckCircle";
import AddCircle from "@mui/icons-material/AddCircle";

import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from '@mui/icons-material/Delete';



import React, { useEffect, useState } from "react";
import AceEditor from "react-ace";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
import { css } from '@emotion/react';
import styled from "@emotion/styled";

import {
  Container,
  Typography,
  Box,
  Grid,
  CardMedia,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Checkbox, FormControlLabel,
  Paper,
  TextField, Button, Table, TableBody, TableCell, TableHead, TableRow,  Step, Stepper, StepLabel,AppBar, Toolbar, Card, CardContent, InputAdornment, ListItem, ListItemIcon, ListItemText, List 
} from '@mui/material';

const StyledHeader = styled('div')({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1em",
  });
  
  const StyledMenu = styled('div')({
    position: "absolute",
    top: "30%",
    right: '4em',
    backgroundColor: "#fff",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    padding: "1em",
  });
  
  const MenuItem = styled('div')({
    padding: "0.5em 0",
    cursor: "pointer",
  });
  
  const Test = () => {
    const [origin, setOrigin] = useState("Basel (BSL)");
    const [destination, setDestination] = useState("Fuerteventura (FUE)");
    const [departDate, setDepartDate] = useState("05/09/2023");
    const [returnDate, setReturnDate] = useState("19/09/2023");
    const [travellers, setTravellers] = useState(3);
    const [cabinClass, setCabinClass] = useState("Economy");
    const [directFlights, setDirectFlights] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const [selectedMenuItem, setSelectedMenuItem] = useState("");
  
    const handleSearch = () => {
      prompt('test')
    };
  
    const handleMenuClick = (menuItem) => {
          
      setSelectedMenuItem(menuItem);
      setIsMenuOpen(false);
    };
  
    const handleMenuOpen = () => {
        
      setIsMenuOpen(true);
    };
  
    const handleMenuClose = () => {
      setIsMenuOpen(false);
    };
  
    return (
      <Container>
        <StyledHeader>
          <Typography variant="h5">Logo</Typography>
          <Button variant="contained" color="primary" onClick={handleMenuOpen}>
            Open menu
          </Button>
         {isMenuOpen && <StyledMenu>
            asassasa
              <MenuItem onClick={() => handleMenuClick("test1")}>test1</MenuItem>
              <MenuItem onClick={() => handleMenuClick("test2")}>test2</MenuItem>
              <MenuItem onClick={() => handleMenuClick("test3")}>test3</MenuItem>
            </StyledMenu>}
          
           
          
        </StyledHeader>
        <Typography variant="h2" gutterBottom>
         
          Quickly scan all your favourite travel sites
        </Typography>
        
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="From"
              fullWidth
              value={origin}
              onChange={(e) => setOrigin(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="To"
              fullWidth
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <TextField
              label="Depart"
              fullWidth
              value={departDate}
              onChange={(e) => setDepartDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <TextField
              label="Return"
              fullWidth
              value={returnDate}
              onChange={(e) => setReturnDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label="Travellers & Cabin Class"
              fullWidth
              value={`${travellers} Travellers, ${cabinClass}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={directFlights}
                  onChange={(e) => setDirectFlights(e.target.checked)}
                  color="primary"
                />
              }
              label="Direct flights"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSearch}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </Container>
    );
  };
  
  export default Test;