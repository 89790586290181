import React from 'react';
import { Box, Typography, Button, Rating } from '@mui/material';

import styled from '@emotion/styled'

const Image = styled('img')`
  width: 200px;
  height: 200px;
`;

const PropertyCard = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        border: '1px solid',
        borderRadius: '4px',
        p: '16px',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          gap: '8px',
        }}
      >
        <Image src="https://via.placeholder.com/200" alt="Nikolakakis Rooms Lavrio" />
        <Typography
          sx={{
            position: 'absolute',
            top: '8px',
            left: '8px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            p: '4px',
            borderRadius: '4px',
          }}
        >
          Breakfast included
        </Typography>
        <Button
          sx={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            backgroundColor: 'white',
            borderRadius: '50%',
          }}
        >
          ♥
        </Button>
      </Box>
      <Typography variant="h6">Nikolakakis Rooms Lavrio</Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Rating value={3} readOnly />
          <Typography variant="subtitle2">3/5</Typography>
        </Box>
        <Typography>Lavrio</Typography>
      </Box>
      <Typography>Show on map</Typography>
      <Typography>Travel Sustainable Level 1</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          py: '8px',
          px: '16px',
          border: '1px solid',
          borderRadius: '4px',
        }}
      >
        <Typography variant="subtitle2">
          Double Room with Balcony (2 Adults + 1 Child)
        </Typography>
        <Typography>3 beds (2 twins, 1 sofa bed)</Typography>
        <Typography>Free cancellation – no prepayment necessary</Typography>
        <Typography>You can cancel later, so lock in this great price today!</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Typography>1 night, 2 adults, 1 child</Typography>
          <Typography>Price CHF 104</Typography>
          <Typography>Includes taxes and fees</Typography>
        </Box>
        <Button variant="contained">See Availability</Button>
      </Box>
    </Box>
  );
};

export default PropertyCard;