import React, { useEffect, useState } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Typography } from '@mui/material';
import { GetUser, UserLoggedIN } from '../services/graphQlService';
import RegistrationForm from '../componentsReactor/Registration';
import HowItWorksStepper from './HowItWorks';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';





function Login(props) {
  
  const [registered, setRegistered] = useState(false)
  const [firstTimeRegistered, setFirstTimeRegistered] = useState(false)
  /* const [userIdLocal, setUserIdLocal] = useState("") */
  const [userLocal, setUserLocal] = useState(null)
  let counter = 0 
  console.log('props', props)
  const navigate = useNavigate();


    const LoggedIn = (c) => {
      
      useEffect(() => {
      console.log(c.user.username)
      if(!registered && !userLocal && counter < 1){
        counter++
      checkUserInDB(c.user.username).then((res) => {
      /* setUserIdLocal(c.user.username) */
      props.loggedIn(c.user.username) 
      })
      
      
        /* navigate("/editor", { replace: true }) */
      }
    }, [])
      return (
        <></>
      )
    }

    useEffect(() => {
      
      console.log('userLocal', userLocal)
      if(userLocal){
        navigate("/profile", { replace: true })
      }
    }, [userLocal])

    const checkUserInDB = async (username) => {
      /* console.log('user', user) */
      console.log(props)
      let userDB = await GetUser(username)
      console.log('userDB', userDB)
      if (userDB.data.getUser ) {
        setRegistered(true)
        setUserLocal(userDB.data.getUser)
    
       
        
         /*  TagManager.dataLayer({
            event: 'Login',
            category: "engagement",
            label: userDB.data.getUser.id,
          }) */
          /* window.dataLayer({
            event: 'Login',
            category: "engagement",
            label: userDB.data.getUser.id,
          }) */
         /*  let tagManagerArgs = {
            gtmId: 'GTM-NNGFH4J',
            events: {
              category: "engagement",
              label: userDB.data.getUser.id,
              action: "login",
            }
        }
        TagManager.initialize(tagManagerArgs) */
        
      }
      return userDB 
    }

    const handleUpdateReg = (e) => {
      console.log('e', e)
      setFirstTimeRegistered(true)
      
    }

  /*   useEffect(() => {
      console.log(props)
      if(props.user){
        console.log('user', props.user)
      let userReg = checkUserInDB(user)
      console.log('userReg', userReg)
      } else {
        let user = UserLoggedIN().then((user) => {

          console.log('user', user)
          let userReg = checkUserInDB(user)
          console.log('userReg', userReg)
          })
      }
      
      
      
      
    }, [userIdLocal]) */


  return (
    <Authenticator >
      {({ signOut, user }) => {
        let shortend = user.attributes.email.split('@')[0]
        /* setRegistered(false) */
        console.log('shortend', shortend)
        return(
        <main >
          <LoggedIn user={user} />
          {firstTimeRegistered ?
          < >
          <h1>Successfully registered 🎉</h1>
          <HowItWorksStepper/>
          </>
          :
          <>
          {registered ? <>
          <h1>Hi there, {shortend} 👋</h1>
          <Typography>
            You are now signed in with your unique UserID <strong>{user.username}</strong>.
          </Typography>
          <br/>
          <Typography>
          {/* Hello and welcome back to ReactEvolve! <br/><br/> */} We're thrilled to see you again and truly appreciate your support. Our mission is to make your life easier, saving you time and money as you navigate the world of AI-powered React component generation. Thank you for choosing our app, and we hope it helps you in delivering outstanding results faster. Enjoy your time here and happy generating!
          </Typography>
          
          {/* <button onClick={signOut}>Sign out</button> */}
          </>
          :
          <div >
          <h1>Welcome on bord, {shortend} 👋</h1>
          <Typography>
            You are logging in for the first time. 
            <br/><br/>
            We are delighted to extend a warm invitation to you, as you join our distinguished ReactEvolve Community. It is our immense pleasure to have you with us, where together, we shall embark upon a remarkable journey of intellectual exploration, creativity, and collaborative innovation. The vibrant spectrum of our society stems from an array of unique capabilities, diverse talents, experiences, cultures, and ideas.  Together, we can unify by our shared passion for enriching the world with modern web development.
             
            {/* You are now signed in with your unique UserID <strong>{user.username}</strong>. */}
          </Typography>
          <br/>
          <br/>
          
          {props.referal ? <RegistrationForm user={user} updateReg={handleUpdateReg} referal={props.referal} /> :
          <RegistrationForm user={user} updateReg={handleUpdateReg} /> }
          </div>}
          </>}
        </main>
      )}}

    </Authenticator>
  );
}

export default Login;
