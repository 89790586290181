import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Rating,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StateContext from "../globalState/StateContext";
import { ADD_ITEM, UPDATE_ITEM, REMOVE_ITEM } from "../globalState/actionTypes";

const StyledContainer = styled(Container)`
  margin-top: 2rem;
`;

const StyledPaper = styled(Paper)`
  padding: 1rem;
  margin-bottom: 1rem;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const SalesMeetingFeedback = () => {
  const [question, setQuestion] = useState('');
  const [questions, setQuestions] = useState([]);
  const [rating, setRating] = useState(5);
  

  const addQuestion = () => {
    if (question.trim() !== '') {
      setQuestions([...questions, { id: Date.now(), text: question.trim() }]);
      setQuestion('');
      
    }
  };

  const deleteQuestion = (id) => {
    setQuestions(questions.filter((q) => q.id !== id));
  };

  const editQuestion = (id) => {
    const questionToEdit = questions.find((q) => q.id === id);
    setQuestion(questionToEdit.text);
    deleteQuestion(id);
  };




  

  

  return (
    <StyledContainer maxWidth="sm">
     {/*  <Typography variant="h4" align="center" gutterBottom>
        Customer Feedback
      </Typography> */}
  
      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          Add Note
        </Typography>
        <StyledTextField
          label="Note"
          variant="outlined"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          onKeyPress={(e) => (e.key === 'Enter' ? addQuestion() : null)}
        />
        <Button variant="contained" color="primary" onClick={addQuestion}>
          Add
        </Button>
      </StyledPaper>
      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          Notes
        </Typography>
        <List>
          {questions.map((q) => (
            <ListItem key={q.id}>
              <ListItemText primary={q.text} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => editQuestion(q.id)}>
                  <EditIcon />
                </IconButton>
                <IconButton edge="end" onClick={() => deleteQuestion(q.id)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </StyledPaper>
      <StyledPaper>
        <Typography variant="h6" gutterBottom>
          Helpfulness
        </Typography>
        <Rating
          name="rating"
          value={rating}
          onChange={(e, newValue) => setRating(newValue)}
          precision={1}
        />
      </StyledPaper>
    </StyledContainer>
  );
};

export default SalesMeetingFeedback;