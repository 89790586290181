
import ReactGA from "react-ga4";
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Link, Outlet, Routes, useLocation, useNavigate } from 'react-router-dom';

import { Container, Grid, Box, Typography, AppBar, Toolbar, IconButton, Button, Drawer, List, ListItem, ListItemText, Avatar, Menu, MenuItem, Card } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';
import CombinedComponentAdjusted from './components/CombinedComponentAdjusted';
import { Amplify, Auth } from 'aws-amplify';
import CookieConsent from "react-cookie-consent";
 


import LoginModal from './components/loginModal';
import awsExports from './aws-exports';
import ProcessStepper from './components/ProcessStepper';
import { render } from '@testing-library/react';
import Dashboard from './components/dashboard';
import CombinedComponent from './components/EnablerTopics';
import CombinedComponentBillboard from './components/EnablerTopics';
import StateProvider from './globalState/StateProvider';
import DestinationCard from './components/DestinationCard';
import SearchField from './components/searchField';
import SearchFieldComponent from './components/searchField';
import PropertyCard from './components/defaultPropertyCard';
import AnotherPropertyCard from './components/anotherCard';
import ThreeStepProcess from './components/ThreeStepProcess';
import HorizontalStepper from './components/Stepper1';
import StepProcess2 from './components/codeEditor/StepProcess2';
import StepperComplete from './components/codeEditor/StepperComplete';
import SampleComponent from './components/codeEditor/test2';

import BannerNew from './componentsReactor/Banner';

import FeaturesReactorAI from './componentsReactor/Features';
import Patterns from './componentsReactor/Patterns';
import FooterComponent from './componentsReactor/Footer';
import Pricing1 from './componentsReactor/Pricing';
import { GetUser, IsAdmin, ListMyComponents, UserLoggedIN } from './services/graphQlService';
import { RequireAuth } from './services/requireAuth';
import { Authenticator } from '@aws-amplify/ui-react';
import { ToS } from './privacy/ToS';
import { PrivacyDeclaration } from './privacy/privacyDeclaration';
import { Impressum } from './privacy/Impressum';
import UserProfile from './componentsReactor/UserProfile';
import AboutUs from './componentsReactor/AboutUs';
import ComponentTreeBuilder from './components/codeEditor/componentTreeBuilder';
import ComponentLibrary from './componentsReactor/ComponentLibrary';
import MyComponentLibrary from './componentsReactor/MyComponents';
import HowItWorksStepper from './components/HowItWorks';
import HIWModal from './components/HIWModal';
import ActionBanner from "./components/ActionBanner";
import PricingModal from "./components/PricingModal";
import MobileVersionMarketing from "./componentsReactor/MobileVersionMarketing";
import CookieInfo from "./privacy/CookieInfo";
import CookiePolicy from "./privacy/CookiePolicy";
import DemoContainer from "./components/demoComponents/DemoContainer";
import FAQAccordion from "./componentsReactor/faq";
import TagManager from "react-gtm-module";

Amplify.configure(awsExports);

const Logo = styled('img')({
  maxHeight: '40px',
});

const NavigationContainer = styled('nav')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexGrow: 1,
});

const DropdownButton = styled(Button)({
  WebkitTextSizeAdjust: '100%',
  WebkitFontSmoothing: 'antialiased',
  fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
  fontSize: '0.875rem',
  lineHeight: '1.75',
  minWidth: '64px',
  transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  color: 'inherit',
  borderRadius: '12px',
  textTransform: 'none',
  padding: '9px 16px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  WebkitTapHighlightColor: 'transparent',
  backgroundColor: 'transparent',
  outline: '0px',
  border: '0px currentcolor',
  margin: '0px',
  cursor: 'pointer',
  userSelect: 'none',
  verticalAlign: 'middle',
  appearance: 'none',
  textDecoration: 'none',
  fontWeight: 600
});

const newCode1 = `

const ChecklistContainer = styled(Paper)({
  padding: '16px',
  width: '100%',
  maxWidth: '600px',
  margin: '0 auto'
});

const marketingItems = [
  'Identify target audience',
  'Create unique selling proposition',
  'Develop marketing strategy',
  'Set marketing goals',
  'Plan marketing budget',
  'Design marketing materials',
  'Launch marketing campaigns',
  'Track marketing performance',
  'Analyze marketing data',
  'Optimize marketing efforts'
];

const ProductMarketingChecklist = () => {
  const [checkedItems, setCheckedItems] = React.useState(Array(10).fill(false));

  const handleCheckboxChange = (index) => (event) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = event.target.checked;
    setCheckedItems(newCheckedItems);
  };

  return (
    <ChecklistContainer>
      <Typography variant="h6" gutterBottom>
        Product Marketing Checklist
      </Typography>
      {marketingItems.map((item, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={checkedItems[index]}
              onChange={handleCheckboxChange(index)}
            />
          }
          label={item}
        />
      ))}
    </ChecklistContainer>
  );
};

render(ProductMarketingChecklist)
`.trim()

const solarPanel = `
const StyledAppBar = styled(AppBar)({
  backgroundColor: '#4caf50'
})

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent:'space-between'
})





const SolarLandingPage = () => {
  const [roofSpace, setRoofSpace] = useState('');
  const [solarPanelEfficiency, setSolarPanelEfficiency] = useState('');
  const [roi, setRoi] = useState('');
  const [hover, setHover] = useState(false);

  const calculateROI = () => {
    const roiValue = (parseFloat(roofSpace) * parseFloat(solarPanelEfficiency)) / 100;
    setRoi(roiValue.toFixed(2));
  };

  const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: hover ? '0 4px 8px rgba(0, 0, 0, 0.2)' : '0 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    padding: '20px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    margin: '1em'
  };

  return (
    <>
      <StyledAppBar position="static">
        <StyledToolbar>
          <Typography variant="h6">Solar Panel Solutions</Typography>
          <Button color="inherit">Contact Us</Button>
        </StyledToolbar>
      </StyledAppBar>

      <Container>
        <Grid container>
          {/* ... existing cards ... */}

          <Grid item xs={12} md={8}>
            <Card style={cardStyle}     onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}>
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  Solar ROI Calculator
                </Typography>
                <Typography>
                  Calculate the return on investment (ROI) for your solar panel system based on your roof space and solar panel efficiency.
                </Typography>
                <TextField
                  label="Roof Space (sq ft)"
                  value={roofSpace}
                  onChange={(e) => setRoofSpace(e.target.value)}
                  style={{ marginTop: '15px', marginRight: '10px' }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">sq ft</InputAdornment>,
                  }}
                />
                <TextField
                  label="Solar Panel Efficiency (%)"
                  value={solarPanelEfficiency}
                  onChange={(e) => setSolarPanelEfficiency(e.target.value)}
                  style={{ marginTop: '15px' }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
                <Button variant="contained" color="primary" style={{ marginTop: '15px', marginRight: '10px' }} onClick={calculateROI}>
                  Calculate ROI
                </Button>
                {roi && (
                  <Typography variant="subtitle1" style={{margin:'1em'}}>
                    Estimated ROI: <strong>{roi}</strong> per year
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <footer>
        <Container>
          <Typography align="center" style={{ marginTop: '20px' }}>
            &copy; {new Date().getFullYear()} Solar Panel Solutions. All rights reserved.
          </Typography>
        </Container>
      </footer>
    </>
  );
};







render(<SolarLandingPage />)
`.trim()



const marketingChecklistNew = `
const ChecklistContainer = styled(Paper)({
  padding: '16px',
  width: '100%',
  maxWidth: '600px',
  margin: '0 auto',
});

const CircularProgressBar = ({ progress }) => {
  const [value, setValue] = useState(progress);

  React.useEffect(() => {
    setValue(progress);
  }, [progress]);

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" value={value} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">100%</Typography>
      </Box>
    </Box>
  );
};

const marketingItems = [
  'Identify target audience',
  'Create unique selling proposition',
  'Develop marketing strategy',
  'Set marketing goals',
  'Plan marketing budget',
  'Design marketing materials',
  'Launch marketing campaigns',
  'Track marketing performance',
  'Analyze marketing data',
  'Optimize marketing efforts',
];

const ProductMarketingChecklist = () => {
  const [checkedItems, setCheckedItems] = useState(Array(10).fill(false));
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(new Set());
  const [showConfetti, setShowConfetti] = useState(false);
  const steps = ['Marketing Checklist', 'Marketing Progress', 'Success!'];

  const handleCheckboxChange = (index) => (event) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = event.target.checked;
    setCheckedItems(newCheckedItems);
    if (event.target.checked) {
      setCompleted(completed.add(index));
    } else {
      completed.delete(index);
    }
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
        setActiveStep(0);
        setCheckedItems(Array(10).fill(false));
        setCompleted(new Set());
      }, 3000);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Product Marketing Checklist
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Paper>
              <List>
                {marketingItems.map((item, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <Checkbox
                        checked={checkedItems[index]}
                        onChange={handleCheckboxChange(index)}
                      />
                    </ListItemIcon>
                    <ListItemText primary={item} />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Task</TableCell>
                    <TableCell align="right">Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {marketingItems.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {item}
                      </TableCell>
                      <TableCell align="right">
                        {completed.has(index) ? (
                          <CheckCircle color="primary" />
                        ) : (
                          <CircularProgressBar
                            progress={
                              checkedItems.filter((value) => value).length /
                              marketingItems.length *
                              100
                            }
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
        <Box my={4}>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 && (
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  Marketing Checklist
                </Typography>
                <Typography variant="body2" component="p">
                  Complete the tasks in the checklist to progress to the next
                  step.
                </Typography>
              </CardContent>
            </Card>
          )}
          {activeStep === 1 && (
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  Marketing Progress
                </Typography>
                <Typography variant="body2" component="p">
                  You have completed{' '}
                  {checkedItems.filter((value) => value).length} out of{' '}
                  {marketingItems.length} tasks.
                </Typography>
              </CardContent>
            </Card>
          )}
          {activeStep === 2 && (
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  Success!
                </Typography>
                <Typography variant="body2" component="p">
                  Congratulations! You have completed all the tasks in the
                  checklist.
                </Typography>
              </CardContent>
            </Card>
          )}
          {activeStep !== 2 && (
            <Box display="flex" justifyContent="flex-end">
              <Button disabled={activeStep === 0} onClick={handleBack}>
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Restart' : 'Next'}
              </Button>
            </Box>
          )}
        </Box>
        {showConfetti && <Confetti />}
      </Box>
    </Container>
  );
};

render(<ProductMarketingChecklist />)`

const Home = (props) => {
  const handleRegister = () => {
    props.handleRegister()
  }

const StyledCardHoover = styled(Card)`
  margin-top: 4em;
  margin-bottom: 4em;
  padding: 16px;
  border-radius: 16px;
  &:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    
  }
`;


  return(
  

 <>
 
 <BannerNew  openLoginModal={handleRegister} />
 
 <br/>
{/* <DemoContainer />  */}
<StyledCardHoover>
<FeaturesReactorAI />
</StyledCardHoover>
<StyledCardHoover>
<ActionBanner action={handleRegister} />
</StyledCardHoover>

  <StyledCardHoover>
 <HowItWorksStepper noButton={true} openLoginModal={handleRegister} />
 </StyledCardHoover>
 <StyledCardHoover>
<Patterns />
</StyledCardHoover>
 {/* <CombinedComponentBillboard /> */}
 <BannerNew alternativeImage={true} openLoginModal={handleRegister} />

 </>
)
};


const HomeSalesAssist = () => (
  <Grid container justifyContent="center" alignItems="center" direction="column" spacing={3}>
    <Grid item xs={12}>
      <Box textAlign="center">
        <Typography variant="h5">salesAssistAI</Typography>
        <Typography variant="body1">Description for salesAssistAI</Typography>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Box textAlign="center">
        <Typography variant="h5">Selector</Typography>
        <CombinedComponentAdjusted />
        <ProcessStepper />
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Box textAlign="center">
        <Typography variant="h5">About Us</Typography>
        <Typography variant="body1">Dedicated developers using AI and AWS</Typography>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Box textAlign="center">
        <Typography variant="h5">Contact Details</Typography>
        <Typography variant="body1">Impressum, Privacy, Terms of Service, Contact</Typography>
      </Box>
    </Grid>
  </Grid>
);

const Features = () => {
  return (
    <Box>
      <Typography variant="h4">Features</Typography>
      
    </Box>
  );
};

const Pricing = (props) => {
  const handleRegister = () => {
    props.handleRegister()
  }
  return (
    <Box>
      
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Pricing1 />
        <br/>
      <HowItWorksStepper noButton={true} openLoginModal={handleRegister} />
        <Container style={{marginTop:'40px'}}>
        <FAQAccordion variant='faq1Public' />
      </Container>
  </div>
      <div>
  
    
  </div>
    </Box>
  );
};


const TreeBuilder = () => {
  return (
    <Box>
      
      {/* <DestinationCard /> */}
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop:'2em'}}>
    {/* <SearchFieldComponent /> */}
    {/* <AnotherPropertyCard /> */}
    {/* <StepProcess2 code={solarPanel} /> */}
    <StepperComplete variant={'Builder'} code={newCode1} />
  </div>
      <div>
    {/* <PropertyCard /> */}
    {/* <ThreeStepProcess /> */}
    
  </div>
    </Box>
  );
};

/* ReactGA.initialize("G-TWCT4GHYYW"); */

//Current
ReactGA.initialize("G-QNPD391DTS"); 

//G-TAG
const tagManagerArgs = {
  gtmId: 'GTM-NNGFH4J'
}
TagManager.initialize(tagManagerArgs)


const App = () => {
  
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState('');
  /* const [currentPage, setCurrentPage] = useState('React Evolve'); */
  const [currentPage, setCurrentPage] = useState('ReactEvolve™');
  const [openInfo, setOpenInfo] = useState(false);
  const [isDesktop, setIsDesktop] = useState(true);
  const [referalId, setReferalId] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  
  const handleAuthRequest = async (v) => {
    console.log('handleAuthRequest');
    console.log(v);
    setOpenDialog(true);
    if(v){
      let user = await UserLoggedIN()
      setUserLoggedIn(user.username)
    } else {
      setUserLoggedIn('')
    }
  }


  const handleCloseDialog = (result) => {
    console.log('close login dialog');
    console.log(result);
    setOpenDialog(false);
  };

  const Editor = () => {
    return (
      <Box>
        
        {/* <DestinationCard /> */}
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop:'2em'}}>
      {/* <SearchFieldComponent /> */}
      {/* <AnotherPropertyCard /> */}
      {/* <StepProcess2 code={solarPanel} /> */}
      <StepperComplete user={userLoggedIn} variant={'Editor'} code={newCode1} />
    </div>
        <div>
      {/* <PropertyCard /> */}
      {/* <ThreeStepProcess /> */}
      
    </div>
      </Box>
    );
  };

 /*  useEffect(async () => {
    let user = await UserLoggedIN()
    if(user.username){ 
      setUserLoggedIn(user.username)
    }
    console.log(user)
  }, []) */

  const handleRouteChange = (page) => {
    console.log(page)
    if(page === 'My Components'){
      
    }
    setCurrentPage(page)
    ReactGA.event({
      category: "engagement",
      label: "Navigation",
      action: page,
    });
    console.log({window})
    /* if(window.dataLayer){
      window.dataLayer.push({
        event: 'pageview',
        page: page
      })
    } */
    /* TagManager.dataLayer({
      event: 'pageview',
      page: page
    }) */
   
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
    ReactGA.event({
      category: "engagement",
      action: "LoginDialog",
    });
    /* if(window.dataLayer){
      window.dataLayer({
        category: "engagement",
        event: "LoginDialog",
      })
    } */
    
    
  };

  const handleOpenInfo = () => {
    setOpenInfo(true);
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const handleOpenDashboard = () => {
    render(<Dashboard />)
  }

  const toggleDrawer = (open) => (event) => {
    event.preventDefault()
    console.log(event)
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };


  
 

  const handleClose = () => {
    setAnchorEl(null);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
     {userLoggedIn ? <List>
        {/* {['Profile', 'Library', 'My Components', 'Editor', 'Stacker'].map((text, index) => { */}
        {['Profile', 'Library', 'My Components', 'Editor'].map((text, index) => {
            if(text === 'My Components'){
              let link = "mycomponents"
              return(
                <ListItem button key={text} onClick={() => handleRouteChange(text)} component={Link} to={index === 0 ? '/' : '/' + link.toLowerCase()}>
                  <ListItemText primary={text} />
                </ListItem>
           )
            }
          return(
          <ListItem button key={text} onClick={() => handleRouteChange(text)} component={Link} to={index === 0 ? '/' : '/' + text.toLowerCase()}>
            <ListItemText primary={text} />
          </ListItem>
     )})}
      </List> :
      <List>
        {['Home','Library', 'Pricing', 'About','Editor'].map((text, index) => (
          <ListItem button key={text}  component={Link} to={index === 0 ? '/' : '/' + text.toLowerCase()}>
            <ListItemText  primary={text} />
          </ListItem>
        ))}
      </List>}
     
    </Box>
  );

  

  const isUserLoggedIn = async () => {
    
    let user = await UserLoggedIN()
    console.log(user)
    if(user && user.username){ 
      setUserLoggedIn(user.username)
    
    }
    
  }

  const isAdminLocal = async () => {
    let isAdmin1 = await IsAdmin()
    if(isAdmin1){
      setIsAdmin(true)
    }
  } 

 /*  const NavigationProvider = () => {
    const navigate = useNavigate();
    navigate('/profile')
    return(
      <></>
    )
  } */
  

  const HandleSignOutComponent = () => {
    const navigate = useNavigate();
    
    
    const handleLogoutLocal = () => {
      setUserLoggedIn('')
      setCurrentPage('ReactEvolve™')
    Auth.signOut()
    
    navigate('/')
    }

    return(
      <div>
        <Button onClick={handleLogoutLocal} color="inherit">Sign Out</Button>
      </div>
    )}

  const handleSignOut = async () => {
    
    Auth.signOut()
    setUserLoggedIn('')
    navigate('/')
  }

  const handleLoggedInUpdate = (loggedIn) => {
    
    //console.log(loggedIn)
    setUserLoggedIn(loggedIn)
    setAnchorEl(false);
    console.log('login change from subcomponent')
  }

  const getUserLocal = async (user) => {
    console.log(user)
    let res = await GetUser(user)
    return res
  }

  const ReadUserCredits = (user) => {
    const [credits, setCredits] = useState(0)
    console.log(user.user)
    
    let res = getUserLocal(user.user).then(
      (res) => {
        
        /* setUserLoggedIn('') */
        /* setUserLoggedIn(res.data.getUser.id) */
        console.log(res)
        if(res.data.getUser.credits){
          setCredits(res.data.getUser.credits)
        }
        
      }
    )
    return(
      <>Credits: {credits}</>
      )
   
  }

  const handleRegistrationReset = () => {
    setOpenDialog(true)
  }

  const isUuid = (uuid) => {
   /*  let s = "" + uuid;
    s = s.replace(/^\s+|\s+$/g, "");
    if (s.match(/^[a-fA-F0-9]{32}$/)) {
      return true;
    }
    return false; */
    let s = "" + uuid;
    let s1 = "" + uuid;
    let s2 = "" + uuid;

    s = s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    s1 = s1.match('^[0-9a-fA-F]{6}-[0-9a-fA-F]{4}-[0-9a-fA-F]{3}-[0-9a-fA-F]{4}-[0-9a-fA-F]{11}$');
    s2 = s2.match('^[0-9a-fA-F]{7}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
    console.log({s, s1})
    if (s === null && s1 === null && s2 === null) {
      return false;
    } else {
    return true;
    }
  }



  useEffect( () => {
    isUserLoggedIn() 
    isAdminLocal()
    console.log('use effect')
    let width = window.innerWidth
    if(width < 1100){
      setIsDesktop(false)
    }
    console.log(window.location.pathname)
    let path = window.location.pathname
    let pathArray = path.split('/')
    if(pathArray[1]){
      let referalId = pathArray[1]
      /* console.log({referalId}) */
      let isUuidBool = isUuid(referalId)
      /* console.log(isUuidBool) */
      if(isUuidBool){
        setReferalId(referalId)
      }
    }
      

    console.log(width)
  }, [])

  return (
    <Authenticator.Provider>
    <Router>
      <StateProvider>
      <Container>
        <header>
        <AppBar position="static">
  <Toolbar>
  {isDesktop && <>
    <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
      <MenuIcon />
    </IconButton>
    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
      {list()}
    </Drawer>
    </>}
    {userLoggedIn ? 
    <img src="aiListLogo.png" alt="Logo" style={{ width: '50px', marginRight: '1rem' }} />
    :
    <Link to="/profile" style={{cursor:"pointer"}} >
    <img src="aiListLogo.png" alt="Logo" style={{ width: '50px', marginRight: '1rem' }} />
    </Link>
    }
    {/* {userLoggedIn && <NavigationProvider />} */}
    <Grid container alignItems="center">
      <Grid item>
      
    <Typography color={'white'} variant="h6">{currentPage}</Typography>
    
      </Grid>
      <Grid item xs>
    {/*   <Link to="/pricing" style={{width: "25%"}}>
          <Button color='info' style={{marginLeft:'1em'}} variant='contained'>Pricing</Button>
        </Link>
        <Link to="/editor" style={{width: "25%"}}>
        <Button color='info' style={{marginLeft:'1em'}} variant='contained'>Editor</Button>
        </Link>  */}
      </Grid>
      {isDesktop && <Grid item>
        {userLoggedIn != '' ? 
        <>
        <Button color='info' variant='contained' onClick={handleOpenInfo}>Info</Button>
        <DropdownButton type="button" onClick={handleClick}>
          <Avatar />
      </DropdownButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ marginTop: '1em' }}
      >
        <MenuItem><ReadUserCredits user={userLoggedIn} /></MenuItem>
        {/* <MenuItem onClick={handleClose}>My Components</MenuItem> */}
        {/* <Link to="/profile" style={{width: "25%"}}>
        <MenuItem >Profile</MenuItem>
        </Link> */}
        {/* <MenuItem onClick={handleClose}>Add Credit</MenuItem> */}
        {/* <MenuItem onClick={handleClose}>FAQ</MenuItem> */}
        <MenuItem /* onClick={handleSignOut} */><HandleSignOutComponent/></MenuItem>
        
      </Menu>
      </>
        :
        <Button color='info' variant='contained' onClick={handleOpenDialog}>Sign In</Button>
        }
        
      </Grid>}
    </Grid>
    <NavigationContainer />
  </Toolbar>
</AppBar>

        </header>
         <LoginModal user={userLoggedIn} text={''} open={openDialog} onClose={handleCloseDialog} loggedIn={handleLoggedInUpdate} referal={referalId} />
         <HIWModal open={openInfo} onClose={handleCloseInfo} />
         
         {isDesktop ? <main>
          <Routes>
            {/* <Route path="/feed/*" element={<RequireAuth><MemberArea page={"feed"} /></RequireAuth>} /> */}
            {userLoggedIn ? <Route path="/*" element={<RequireAuth updateAuth={handleAuthRequest}><UserProfile handleFinalizeRegistration={handleRegistrationReset} user={userLoggedIn} /></RequireAuth>}  />
            :
            <Route path="/*" element={<Home handleRegister={handleOpenDialog} />}  />}
            <Route path="/features" element={<Features />} />
            <Route path="/cookieinfo" element={<CookieInfo />} />
            <Route path="/cookiepolicy" element={<CookiePolicy />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/tos" element={<ToS />} />
            <Route path="/library" element={<ComponentLibrary user={userLoggedIn} isAdmin={isAdmin} />} />
            <Route path="/mycomponents" element={<RequireAuth updateAuth={handleAuthRequest}><MyComponentLibrary isAdmin={isAdmin} user={userLoggedIn} /></RequireAuth>} />
            <Route path="/stacker" element={<TreeBuilder />} />
            
            <Route path="/privacy" element={<PrivacyDeclaration />} />
            <Route path="/imprint" element={<Impressum />} />
            <Route path="/editor" element={<RequireAuth updateAuth={handleAuthRequest}><Editor /></RequireAuth>} />
            <Route path="/profile" element={<RequireAuth updateAuth={handleAuthRequest}><UserProfile handleFinalizeRegistration={handleRegistrationReset} user={userLoggedIn} /></RequireAuth>} />
            <Route path="/about" element={<AboutUs />} />
          </Routes>
        </main> :
        <main>
          {/* <Typography variant="h4" style={{textAlign: 'center', marginTop:"5em", marginBottom: "5em"}}>Sorry, this website is not optimized for mobile devices.</Typography>
          <Typography variant="h4" style={{textAlign: 'center', marginTop:"5em", marginBottom: "5em"}}>Please use a device with a width of at least 1100px.</Typography> */}
          <MobileVersionMarketing />
        </main>}
       
       {/*  <footer>
          
          <Box textAlign="center" py={3}>
            <Typography variant="body1">&copy; {new Date().getFullYear()} Website Name</Typography>
          </Box>
        </footer> */}
         <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="cookieConsentGen"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "20px" }}
            expires={150}
            debug={false}
          >
          This website uses cookies to improve the user experience.{" "}
            <span style={{ fontSize: "10px" }}><a style={{color: "brown"}} href='/privacy' target='_blank' >Privacy Declaration</a></span>
          </CookieConsent>
      </Container>
      { currentPage != 'Editor' && <FooterComponent />
       }
      </StateProvider>
    </Router>
    </Authenticator.Provider>
  );
};

export default App;