import React from 'react';
import { Button, TextField, FormControlLabel, Checkbox, Typography } from '@mui/material';
import { Search as SearchIcon, CalendarToday as CalendarTodayIcon, ArrowDropDown as ArrowDropDownIcon, HelpOutline as HelpOutlineIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@mui/icons-material';
import styled from '@emotion/styled'


const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const SearchField = styled(TextField)`
  && {
    .MuiInputLabel-root {
      font-weight: bold;
    }
    .MuiInputBase-root {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
`;

const DateButton = styled(Button)`
  && {
    text-align: left;
    gap: 0.5rem;
    .MuiTypography-root {
      font-weight: bold;
    }
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  && {
    .MuiCheckbox-root {
      padding: 0;
    }
  }
`;

const StyledHelpOutlineIcon = styled(HelpOutlineIcon)`
  && {
    margin-left: 0.25rem;
  }
`;

const SearchButton = styled(Button)`
  && {
    background-color: #018786;
    color: white;
    font-weight: bold;
    &:hover {
      background-color: #01675f;
    }
  }
`;

function SearchFieldComponent() {
  return (
    <Container>
      <Form>
        <SearchField
          label="Destination/property name:"
          placeholder="Where are you going?"
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
        />
        <DateButton startIcon={<CalendarTodayIcon />} endIcon={<ArrowDropDownIcon />}>
          <Typography>Check-in date</Typography>
          <Typography>10</Typography>
          <Typography>Wednesday, January 10, 2024</Typography>
        </DateButton>
        <DateButton startIcon={<CalendarTodayIcon />} endIcon={<ArrowDropDownIcon />}>
          <Typography>Check-out date</Typography>
          <Typography>11</Typography>
          <Typography>Thursday, January 11, 2024</Typography>
        </DateButton>
        <Typography>1-night stay</Typography>
        <CheckboxContainer>
          <StyledFormControlLabel
            control={<Checkbox icon={<CheckBoxOutlineBlankIcon />} />}
            label={
              <>
                Entire houses & apartments
                <StyledHelpOutlineIcon />
              </>
            }
          />
          <StyledFormControlLabel
            control={<Checkbox icon={<CheckBoxOutlineBlankIcon />} />}
            label={
              <>
                I'm traveling for work
                <StyledHelpOutlineIcon />
              </>
            }
          />
        </CheckboxContainer>
      </Form>
      <SearchButton>Search</SearchButton>
    </Container>
  );
}

export default SearchFieldComponent;