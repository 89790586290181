import React from "react";

class ChangingProgressProvider extends React.Component {
  
  
  static defaultProps = {
      interval: 400
    };
    constructor(){
    super()
    this._isMounted = false;
     this.state = {
      valuesIndex: 0
    };
    }
  

  componentDidMount() {
    this._isMounted = true;
    setInterval(() => {
      this._isMounted && this.setState({
        valuesIndex: (this.state.valuesIndex + 1) % this.props.values.length
      });
    }, this.props.interval);
  }
  componentWillUnmount(){
    this._isMounted = false;
  }

  render() {
    return this.props.children(this.props.values[this.state.valuesIndex]);
  }
}

export default ChangingProgressProvider;
