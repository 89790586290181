import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { Text } from '@aws-amplify/ui-react';

import {
  Container,
  Paper,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Rating,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SalesMeetingFeedback from './SalesMeetingFeedback';



const Container1 = styled(Box)`
  max-width: 800px;
  margin: 0 auto;
  padding: 16px;
`;

const QuestionTitle = styled(Typography)`
  font-weight: bold;
`;

const ArgumentText = styled(Typography)`
  margin-left: 16px;
`;

const StyledContainer = styled(Container)`
  margin-top: 2rem;
`;

const StyledPaper = styled(Paper)`
  padding: 1rem;
  margin-bottom: 1rem;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
`;

const content = `...`; // Insert the given content string here



const FAQs = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [expanded1, setExpanded1] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChange1 = (panel) => (event, isExpanded1) => {
    setExpanded1(isExpanded1 ? panel : false);
  };
console.log({props})
let fullResponseString = props.content

console.log({fullResponseString})
let fullResponseString1 = fullResponseString.fiveQuestionsOpenConversation.replace(/(\r\n|\n|\r)/gm, "");
let fullResponseString2 = fullResponseString.fiveQuestionsPains.replace(/(\r\n|\n|\r)/gm, "");
let JSONString1 = JSON.parse(fullResponseString1)
let JSONString2 = JSON.parse(fullResponseString2)
console.log({JSONString1})
console.log({JSONString2})

  const parsedContent1 = JSONString1
  const parsedContent2 = JSONString2
console.log({parsedContent1})
console.log({parsedContent2})



  return (
    <>
    <Container1>
      <h3>Open Conversation</h3>
      {parsedContent1.map((item, index) => (
        <Accordion
          key={`question-${index}`}
          expanded={expanded === index}
          onChange={handleChange(index)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <QuestionTitle>{item.question || item.painPoint || item.fear}</QuestionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <ArgumentText>{item.answer || item.solution}</ArgumentText>
            <SalesMeetingFeedback />
          </AccordionDetails>
        </Accordion>
      ))}
    </Container1>
    <Container1>
      <h3 variant="heading">Values</h3>
      {parsedContent2.map((item, index1) => (
        <Accordion
          key={`question-${index1}`}
          expanded={expanded1 === index1}
          onChange={handleChange1(index1)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <QuestionTitle>{item.question || item.painPoint || item.fear}</QuestionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <ArgumentText>{item.answer || item.solution}</ArgumentText>
            <SalesMeetingFeedback />
            
          </AccordionDetails>
        </Accordion>
      ))}
    </Container1>
    </>
  );
};

export default FAQs;