
import CheckCircle from "@mui/icons-material/CheckCircle";
import AddCircle from "@mui/icons-material/AddCircle";

import SearchIcon from '@mui/icons-material/Search';
import { useDrag } from '@use-gesture/react'

/* import CircularProgress from "@mui/material/CircularProgress"; */
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MenuIcon from '@mui/icons-material/Menu';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
/* import InfoIcon from '@mui/icons-material/Info'; */
import { ArrowBackIos, ArrowForwardIos, CancelOutlined } from '@mui/icons-material';
import VolumeUp from '@mui/icons-material/VolumeUp';
import VolumeOff from '@mui/icons-material/VolumeOff';
import { WidthProvider, Responsive, GridLayout } from 'react-grid-layout'
import Carousel from 'react-material-ui-carousel';
import { motion } from "framer-motion";
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import {
  useSpringRef,
  animated,
  useTransition,
  useSpring,
} from "@react-spring/web"

import React, { useEffect, useRef, useState } from "react";
import AceEditor from "react-ace";
import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live";
import { css } from '@emotion/react';
import styled from "@emotion/styled";
import ReactGA from 'react-ga4';

/* import {
  Chip,
  DialogActions,
  Radio,
  DialogContent,
  LinearProgress,
  DialogTitle,
  Container,
  Slider,
  Switch,
  Dialog,
  Divider,
  Tooltip,
  Menu,
  CardHeader,
  Typography,
  Box,
  Link,
  Grid,
  CardActions,
  Avatar,
  IconButton,
  FormControl,
  TableContainer,
  MenuItem,
  InputLabel,
  Badge,
  Select,
  Collapse, ToggleButton, ToggleButtonGroup,
  Checkbox, FormControlLabel,
  Paper,
  Modal,
  TextField, Button, Table, TableBody, TableCell, TableHead, TableRow,  Step, Stepper, StepLabel,AppBar, Toolbar, Card, CardContent, InputAdornment, ListItem, ListItemIcon, ListItemText, List, Accordion, AccordionSummary, AccordionDetails, Snackbar 
} from '@mui/material'; */

import {
  Accordion,
AccordionActions,
AccordionDetails,
AccordionSummary,
Alert,
AlertTitle,
AppBar,
Autocomplete,
Avatar,
AvatarGroup,
Backdrop,
Badge,
BottomNavigation,
BottomNavigationAction,
Box,
Breadcrumbs,
Button,
ButtonBase,
ButtonGroup,
Card,
CardActionArea,
CardActions,
CardContent,
CardHeader,
CardMedia,
Checkbox,
Chip,
CircularProgress,
Collapse,
Container,
CssBaseline,
Dialog,
DialogActions,
DialogContent,
DialogContentText,
DialogTitle,
Divider,
Drawer,
Fab,
Fade,
FilledInput,
FormControl,
FormControlLabel,
FormGroup,
FormHelperText,
FormLabel,
GlobalStyles,
Grid,
Grow,
Hidden,
Icon,
IconButton,
ImageList,
ImageListItem,
ImageListItemBar,
Input,
InputAdornment,
InputBase,
InputLabel,
LinearProgress,
Link,
List,
ListItem,
ListItemAvatar,
ListItemButton,
ListItemIcon,
ListItemSecondaryAction,
ListItemText,
ListSubheader,


Menu,
MenuItem,
MenuList,
MobileStepper,
Modal,
NativeSelect,
OutlinedInput,
Pagination,
PaginationItem,
Paper,
Popover,
Popper,
Radio,
RadioGroup,
Rating,
ScopedCssBaseline,
Select,
Skeleton,
Slide,
Slider,
Snackbar,
SnackbarContent,
SpeedDial,
SpeedDialAction,
SpeedDialIcon,
Stack,
Step,
StepButton,
StepConnector,
StepContent,
StepIcon,
StepLabel,
Stepper,
SvgIcon,
SwipeableDrawer,
Switch,
Tab,

Table,
TableBody,
TableCell,
TableContainer,
TableFooter,
TableHead,
TablePagination,
TableRow,
TableSortLabel,


Tabs,
TabScrollButton,
TextField,
ToggleButton,
ToggleButtonGroup,
Toolbar,
Tooltip,
Typography,
Zoom } from '@mui/material';
import {
  TreeView,
  TreeItem,
  Masonry,
  TabContext,
  TabPanel,
  TabList,
  Timeline,
TimelineConnector,
TimelineContent,
TimelineDot,
TimelineItem,
TimelineOppositeContent,
LoadingButton,
TimelineSeparator,
} from '@mui/lab';
/* import Autocomplete from '@mui/material/Autocomplete'; */
import Confetti from 'react-confetti';
/* import {CardMedia} from '@mui/material'; */
import {LocalizationProvider, DatePicker} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';


import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";

import ace from "ace-builds/src-noconflict/ace";
import { FieldGroupIconButton } from "@aws-amplify/ui-react";
import { GetUser, ListComponents, ListMyComponents, UpdateCode, deleteComponent } from "../../services/graphQlService";
import { updateComponents } from "../../graphql/mutations";
import { Label } from "@mui/icons-material";
import { SplineCurve } from "three";
import CountDownTimer from "../CountDownTimer";
ace.config.set("useWorker", false);

const StyledGrid = styled(Grid)`
  height: 100vh;
`;



const NewEditor = (props) => {
  const [code, setCode] = useState(props.code || "");
  const [commands, setCommands] = useState("");
  const [commandList, setCommandList] = useState([]);
  const [currentCommand, setCurrentCommand] = useState({});
  const [selectedCommand, setSelectedCommand] = useState("");
    const [selectedOption, setSelectedOption] = useState('Creation');
    const [selectedSize, setSelectedSize] = useState('Square');
    const [selectedOptionSubComponents, setSelectedOptionSubComponents] = useState('');
    const [selectedOptionAdjustment, setSelectedOptionAdjustment] = useState('New');
  const [htmlContent, setHtmlContent] = useState('');
  const [tokenCount, setTokenCount] = useState(0);
  const [commandCount, setCommandCount] = useState(0);
  const [ componentName, setComponentName] = useState('');
  const [ cssContent, setCSSContent] = useState('');
  const [tokenCountCSS, setTokenCountCSS] = useState(0);
  const [tokenEstimateTotal, setTokenEstimateTotal] = useState(0);
  const [tokenEstimate, setTokenEstimate] = useState(0);
  const [previewWidth, setPreviewWidth] = useState(6);
  
  const [selectedComponent, setSelectedComponent] = useState({});
  const [selectedComponents, setSelectedComponents] = useState({});
  const [componentTree, setComponentTree] = useState([]);
  const [components, setComponents] = useState([]);
  const [subComponents ,setSubComponents] = useState([]); 
  const [selectedSubComponents ,setSelectedSubComponents] = useState([]); 
  const [newComponentName ,setNewComponentName] = useState([]); 
  const [selectedSubComponentId ,setSubComponentId] = useState([]); 
  const editorRef = useRef();
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [plan, setAvailablePlans] = useState('');

  const marks = [
 
    {
      value: 6,
      label: '50%',
    },
    {
      value: 8,
      label: '75%',
    },
    {
      value: 10,
      label: '100%',
    },
  ];

  const sendCommand = () => {
    if(selectedOption === 'Adjustment'){
      setSelectedOptionAdjustment('Adjust');
    }
    if (commands.trim() !== "") {
      const newCommand = { text: commands, textShort: commands.slice(0, 20), type:selectedOption, adjustment: selectedOptionAdjustment, componentName:componentName, cssContent:cssContent, selectedOption:selectedOption, htmlContent:htmlContent, status: "pending", codeIteration:"", datum: String(new Date()) };
      /* console.log({newCommand}) */
      //handleCommandChange(newCommand)
      
      setCurrentCommand(newCommand);
      setCommandList((prevList) => [...prevList, newCommand]);
      setCommands("");
      /* setTimeout(() => {
        setCommandList((prevList) =>
          prevList.map((cmd) =>
            cmd === newCommand ? { ...cmd, status: "received" } : cmd
          )
        );
      }, 2000); */
    }
  };

  const onLoad1 = (editor) => {
    // Get the editor session
    const session = editor.getSession();
  
    // Disable the worker (which produces errors)
    session.setUseWorker(false);
  
    // Set the editor mode to JavaScript
    session.setMode('ace/mode/javascript');
  
    // Set options to enable autocompletion and snippets
    session.setOptions({
      enableBasicAutocompletion: true,
      enableLiveAutocompletion: true,
      enableSnippets: true,
    });
  
    // Hide the gutter and line numbers
    editor.renderer.setShowGutter(true);
    editor.renderer.setOption('showLineNumbers', true);
  };
  const onLoad = (editor) => {
    const mode = editor.getSession().getMode();
  
    if (mode.$worker) {
      mode.$worker.send("setOptions", [
        {
          asi: false,
        },
      ]);
    }
        // Suppress errors by hiding the gutter and line numbers
        editor.renderer.setShowGutter(true);
        editor.renderer.setOption('showLineNumbers', true);
    
        // Configure the editor to ignore certain types of errors
        const session = editor.getSession();
        session.setUseWorker(false);
        /* session.setMode('ace/mode/react');  <-- causing error in aceditor*/
        session.setOptions({
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
        });
  };



  const handleCodeChange = (newCode) => {
    console.log({newCode})
    let posComment = newCode.indexOf('/* This component');
    if(posComment > 0){
    newCode = newCode.slice(0, posComment);
    }
    setCode(newCode);
    if (props.onCodeChange) {
      props.onCodeChange(newCode);
    }
  };
  const handleCommandChange = (newCommand) => {
    setCurrentCommand(newCommand);
    handleCodeChange(code)
    setTimeout(() => {
      if (props.onCommandChange) {
        props.onCommandChange(newCommand);
      }
    }, 1500);
   
  };

  const handleSetSelectedCommand = (event) => {
    console.log(event.text)
    setCommands(event.text);
    
  };

  const handleSetOption = (e) => {
    setSelectedOption(e.target.value);
  };
  const handleSetSize = (e) => {
    setSelectedSize(e.target.value);
  };
  
  const handleSetOptionAdjustment = (e) => {
    setSelectedOptionAdjustment(e.target.value);
  };

  const handleSetHtmlContent = (e) => {
    const html = e.target.value;
    /* setHtmlContent(html); */
    setTokenCount(html.length);
    setTokenEstimateTotal(Math.floor((((html.length*0.7 + cssContent.length + commandCount + componentName.length)/4)/1000)*0.03*100)/100);
    setTokenEstimate(Math.floor((html.length*0.7 + cssContent.length + commandCount + componentName.length)/4));
  };
  const handleSetCSSContent = (e) => {
    const css = e.target.value;
    /* setCSSContent(css); */
    setTokenCountCSS(css.length);
    console.log((((tokenCount*0.7 + css.length + commandCount + componentName.length)/4)/1000)*0.03)
    setTokenEstimateTotal(Math.floor((((tokenCount*0.7 + css.length + commandCount + componentName.length)/4)/1000)*0.03*100)/100);
    setTokenEstimate(Math.floor((tokenCount*0.7 + css.length + commandCount + componentName.length)/4));
    
  };
  const handleSetName = (e) => {
    const name = e.target.value;
    setComponentName(name);
    
  };

  useEffect(() => {
    console.log(props.requestFailed)
    if(props.requestFailed){
    console.log({commandList})
    setCommandList((prevList) => 
      prevList.map((cmd) =>
      cmd === currentCommand ? { ...cmd, status: "failed", currentIteration: props.code, datumReceived: String(new Date()) } : cmd
    ));
    setSnackBarMessage('Request failed. Please check that you have credits or try again later.')
    setSnackBarOpen(true);
    }
    
   /*  if(commandList.length > 0){
      setCommandList(commandList.sort((a, b) => new Date(b.datum) - new Date(a.datum)));
    } */

        /* setCode(props.code) */
  }, [props.requestFailed])
  

  /* useEffect(() => {
    console.log({commandList})
    setCommandList((prevList) => {
      console.log({prevList})
      return(
      prevList.map((cmd) => {
        return(
      cmd === currentCommand ? { ...cmd, status: "received", currentIteration: props.code, datumReceived: String(new Date()) } : cmd
        )
    }
    )
      )
    }
    );


  }, [props.newAnswer]) */

  useEffect(() => {
    const components = [
      { label: 'Component A', value: 'componentA' },
      { label: 'Component B', value: 'componentB' },
      { label: 'Component C', value: 'componentC' },
    ];
    /* setComponents(components); */
    /* setCommandList([]) */
    loadComponents();
    

 }, [props.newAnswer]);

let commandLocal = ''

const handleSetCommand = (e) => {
  e.preventDefault();
  let newCommand = e.target.value;
  /* setCommands(newCommand); */
  setCommandCount(newCommand.length);
}


const handleChangePreviewWidth = (e) => {
  e.preventDefault();
  let newWidth = e.target.value;
  setPreviewWidth(newWidth);
}

const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};
const copyToClipboardFinalComponent = (c) => {
  console.log(c)
  let finalComponent = ''
  if(c.openAiFullResponse){
    let fullResponse = JSON.parse(c.openAiFullResponse)
    console.log(fullResponse)
    let fullReply = fullResponse.choices[0].message.content
    console.log(fullReply)
    let posImport = fullReply.indexOf('import')
    fullReply = fullReply.substring(posImport)
    console.log(fullReply)
    let posJavascriptEnd = fullReply.indexOf('javascriptEnd')
    if(posJavascriptEnd === -1){
      posJavascriptEnd = fullReply.indexOf('```')
    }
    let fullComponent = fullReply.substring(0, posJavascriptEnd)
    console.log(fullComponent)
    finalComponent = fullComponent
  }
  
  navigator.clipboard.writeText(finalComponent);
};
const copyToClipboardFinalComponentImports = (c) => {
  if(c){
  console.log(c)
  let finalComponent = ''
  if(c.newImports){
    let imports = c.newImports
    let replace1 = ''
    let posImport = imports.indexOf('import')
    imports = imports.substring(posImport)
    let code = c.newComponent
    let posRender = code.indexOf('render(')
    let render = code.substring(posRender+8, code.length)
    let posRenderEnd = render.indexOf('/')-1
    render = render.substring(0, posRenderEnd+1)
    render = 'export default ' + render
    code = code.substring(0, posRender)
    finalComponent = imports + '\n' + code + '\n' + render

  }
  console.log(finalComponent)
  navigator.clipboard.writeText(finalComponent);
  setSnackBarMessage('Copied to clipboard!')
  setSnackBarOpen(true);
} else {
  setSnackBarMessage('This might have been a component from previous versions. Please try the Adjustment feature and enter "reproduce component" as a command.')
  setSnackBarOpen(true);
}
};

const MyAccordion = ({ cmd }) => {
  /* console.log({cmd}) */
  return (
    <Accordion style={{marginLeft: '2em'}}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{cmd.componentName}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
        {/*   <Grid xs={3} >
            <Typography sx={{fontWeight:'bold'}}>Command</Typography>
          </Grid> */}
          <Grid item xs={12}>
            <Typography  sx={{fontWeight:'bold'}}>{cmd.selectedOption}</Typography>
             <Typography  sx={{fontWeight:'bold'}}>Adjustment: {cmd.adjustment}</Typography>
             <ListItemText
                    secondary={`${(Math.round((cmd.totalTokens))/1000)*0.03} $ total costs`}
                  /> 
              <ListItemText
                    secondary={`${(8000/1000)*0.03} total tokens`}
                  /> 
          </Grid>
          <Grid item xs={6}>
          <TextField
              multiline
              maxRows={4}
              fullWidth
              value={cmd.text}
              /* onChange={(e) => handleSetHtmlContent(e)} */
              label="Command"
              variant="outlined"
            />
           {cmd.text && <ListItemText
                    secondary={`${cmd.text.length/4} Tokens`}
                  /> }
                  
            {/* <Typography>{cmd.text}</Typography> */}
          </Grid>
          <Grid item xs={3}>
            <FieldGroupIconButton onClick={() => copyToClipboard(cmd.text)}>
              <FileCopyIcon />
            </FieldGroupIconButton>
          </Grid>

          {cmd.currentIteration && <>
          <Grid item xs={6}>
          <TextField
              multiline
              maxRows={4}
              fullWidth
              value={cmd.currentIteration}
              /* onChange={(e) => handleSetHtmlContent(e)} */
              label="Iteration"
              variant="outlined"
            />
             <ListItemText
                    secondary={`${Math.floor(cmd.currentIteration.length/4)} Tokens`}
                  /> 
            {/* <Typography>{cmd.currentIteration}</Typography> */}
          </Grid>
          <Grid item xs={3}>
            <FieldGroupIconButton onClick={() => copyToClipboard(cmd.currentIteration)}>
              <FileCopyIcon />
            </FieldGroupIconButton>
          </Grid>
          </>}
          
        {cmd.cssContent &&
        <>
        <Grid item xs={6}>
          <TextField
              multiline
              maxRows={4}
              fullWidth
              value={cmd.cssContent}
              /* onChange={(e) => handleSetHtmlContent(e)} */
              label="cssContent"
              variant="outlined"
            />
             <ListItemText
                    secondary={`${Math.floor(cmd.cssContent.length/4)} Tokens`}
                  /> 
            {/* <Typography>{cmd.currentIteration}</Typography> */}
          </Grid>
          <Grid item xs={3}>
            <FieldGroupIconButton onClick={() => copyToClipboard(cmd.cssContent)}>
              <FileCopyIcon />
            </FieldGroupIconButton>
          </Grid>
          </>}

          {cmd.htmlContent &&
        <>
          <Grid item xs={6}>
          <TextField
              multiline
              maxRows={4}
              fullWidth
              value={cmd.htmlContent}
              /* onChange={(e) => handleSetHtmlContent(e)} */
              label="htmlContent"
              variant="outlined"
            />
             <ListItemText
                    secondary={`${Math.floor(cmd.htmlContent.length/4)} Tokens`}
                  /> 
            {/* <Typography>{cmd.currentIteration}</Typography> */}
          </Grid>
          <Grid item xs={3}>
            <FieldGroupIconButton onClick={() => copyToClipboard(cmd.htmlContent)}>
              <FileCopyIcon />
            </FieldGroupIconButton>
          </Grid>
          </>}
         
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};



const loadComponents = async () => {
  await loadUser()
  const components = await ListMyComponents(props.user)
  let componentsList = components.data.listComponents.items
    componentsList.forEach(element => {
      element.label = element.componentName+' ('+element.id+')'
      element.value = 'Value'+element.id
    });
  /* console.log(Object.keys(componentsList[0]));
  let newArray = []
  let newObj = {}
  let uniqueNames = [];

  componentsList.forEach(element => {
    if(!uniqueNames.includes(element.componentName)){
      element['children'] = []
      newObj[element.componentName] = [element]
      newArray.push(element)
      uniqueNames.push(element.componentName)
    } else {
      newArray.forEach(element2 => {
        if(element2.componentName === element.componentName){
          element2.children.push(element)
        }
      })
    }
  });
console.log({newArray}); */

//sort newArray by createdAt
componentsList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))


  setComponents(componentsList)
  let copTemp = []
  copTemp = [...componentsList]

    let historicalCommands = []
    copTemp.map((component) => {
      console.log({component})
      console.log(Number(component.responseReceived));
      console.log(Number(new Date(component.createdAt)));
        let newCommand = {componentName: component.componentName, text: component.command, htmlContent: component.html, cssContent: component.css, type: component.type, adjustment: component.isAdjust, status: component.status, codeIteration: component.newComponent, createdAt: Number(new Date(component.createdAt)), responseReceived: Number(component.responseReceived)};
        historicalCommands.push(newCommand)
    })
    
    

    let allCommands = [...commands, ...historicalCommands]
    allCommands.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    let last10 = allCommands.slice(0, 20)
    setCommandList(allCommands)
    
    console.log({allCommands});

  

}

const loadUser = async () => {
  /* const user = await Auth.currentAuthenticatedUser(); */
  let userDB = await GetUser(props.user)
  if(userDB.data.getUser){
    let userDBData = userDB.data.getUser
    console.log({userDBData});
    let plan = userDBData.subscriptionLevel
    setAvailablePlans(plan)
  console.log({userDBData});
  }
}

const handleComponentSelect = (event, component, cardIndex) => {
  console.log({component, cardIndex, event});
  const newSelectedComponents = { ...selectedComponents };
  newSelectedComponents[cardIndex] = component;
  setSelectedComponents(newSelectedComponents);
  setSubComponentId("")
  console.log(component.newComponent)
  if(component.newComponent){
  handleCodeChange(component.newComponent)
  } else {
    let pendingCode = `const Pending = () => (<>
      <Card>
      <CardContent>
      <Typography variant="h9" gutterBottom>
      Component with the ID ${component.id} is still in creation. Please wait 1-2 Minutes, if no change. Please try again.
      </Typography>
      </CardContent>
      </Card>
       </>) \n render(Pending)`
    handleCodeChange(pendingCode)
  }
  if(component.children && component.children.length > 0){
    setSubComponents(component.children)
  } else {
    setSubComponents([])
  }

  const newComponentTree = [...componentTree];
  newComponentTree[cardIndex] = { component };
  setComponentTree(newComponentTree);
};

const handleSubComponentSelect = (event, component, cardIndex) => {
  console.log({component, cardIndex, event});
  event.preventDefault()
  /* const newSelectedComponents = { ...selectedSubComponents }; */
  /* newSelectedComponents[cardIndex] = component; */
  setSelectedSubComponents(component);

  handleCodeChange(component.newComponent)
 

/*   const newComponentTree = [...componentTree];
  newComponentTree[cardIndex] = { component };
  setComponentTree(newComponentTree); */
};

const renderDropdown = (cardIndex) => {
  const selectedComponent = selectedComponents[cardIndex];
  return (
    <>
      
       <Autocomplete
        /* id={`${cardIndex}-component-select`} */
        options={components}
        getOptionLabel={(option) => option.label}
        value={selectedComponent || null}
        onChange={(event, component) =>
          handleComponentSelect(event, component, cardIndex)
        }
        renderInput={(params) => (
          <TextField {...params} label="Select component" variant="outlined" />
        )}
    />
      
    </>
   
  );
};


const handleSliderChange = (event, newValue) => {
  setPreviewWidth(newValue);
  
};

const handleChangeDropDown = (event) => {
  console.log(event.target.value);
  let pos1 = event.target.value.indexOf('(')
  let pos2 = event.target.value.indexOf(')')
  let id = event.target.value.substring(pos1+1, pos2)
  setSubComponentId(id)
  setSelectedOptionSubComponents(event.target.value);
  let subcomp = subComponents.filter((subComp) => id === subComp.id)
  handleCodeChange(subcomp.newComponent)

};

const handleUpdateCodeSubmit = async (e) => {
  e.preventDefault()
  const formData = new FormData(e.target);
  const name = formData.get('componentName');
  setNewComponentName(name)
  handleUpdateCode(code, name)
  console.log({name});
}


const handleUpdateCode = async (code, name) => {
  console.log({selectedSubComponentId});
  console.log({selectedComponents});
  let obj = {}
  obj['id'] = selectedComponents[1].id
  if(selectedSubComponentId !== ''){
    obj.id = selectedSubComponentId
  }
  obj['newComponent'] = code
  
  if(name !== ''){
    obj['componentName'] = name
  }
  console.log({obj});
  let updateCompRes = await UpdateCode(obj)
  console.log({updateCompRes});
  /* await loadComponents(); */
  /* if(props.onCodeUpdate){
    props.onCodeUpdate(code)
  } */
  }

  const handleClose = () => {
    setSnackBarOpen(false);
      
    }

const handleDeleteComponent = async () => {
  console.log({selectedComponents});
  console.log({selectedSubComponentId});
  let obj = {}
  obj['id'] = selectedComponents[1].id
  if(selectedSubComponentId !== ''){
    obj.id = selectedSubComponentId
  }
  /* obj['newComponent'] = code */
  let deleteCompRes = await deleteComponent(obj)
  console.log({deleteCompRes});
  

  /* loadComponents() */
  /* loadComponents(); */
  /* if(props.onCodeUpdate){
    props.onCodeUpdate(code)
  } */
  
  
}


const handleError = (error) => {
  console.log(error);
};

const handleSubmit = async (e) => {
  e.preventDefault();
  console.log(e.target)
  const formData = new FormData(e.target);
  const commands = formData.get('commands');
  
  const size = formData.get('size');
  const selectedOption = formData.get('selectedOption');
  console.log({commands, size, selectedOption});
  if(commands.length > 400 && selectedOption === "ImageGen"){
    setSnackBarMessage("Please use less than 400 characters for Image Generation")
    setSnackBarOpen(true)
    return
  }
  const adjustment = formData.get('Adjustment');
  const componentName = formData.get('componentName');
  const html = formData.get('html');
  const css = formData.get('css');

  const newCommand = { text: commands, textShort: commands.slice(0, 20), type:selectedOption, adjustment: adjustment, componentName:componentName, cssContent:css, selectedOption:selectedOption, htmlContent:html, status: "pending", codeIteration:"", size:size, datum: String(new Date()) };
      console.log({newCommand});
      handleCommandChange(newCommand)
      setCurrentCommand(newCommand);
      setCommandList((prevList) => [newCommand, ...prevList, ]);
      setCommands("");
      console.log({commands, selectedOption});
      ReactGA.event({
        category: "Editor",
        action: "generate "+newCommand.type,
      });
      /* if(window.dataLayer){
        window.dataLayer.push({
          event: "generate "+newCommand.type,
        })
      } */
}
const handleCodeSubmit = async (e) => {
  e.preventDefault();
  console.log(e.target)
  /* const formData = new FormData(e.target); */
  const editorValue = editorRef.current.editor.getValue();
  console.log({editorValue});
  handleCodeChange(editorValue);

}





  return (
    <StyledGrid container>
      <Grid alignItems={'flex-end'} item xs={12}>
     
            <Typography style={{color:"darkgray"}}>
              Preview Width
            </Typography>
            <Slider
             value={previewWidth}
        onChange={handleSliderChange}
        aria-labelledby="preview-width-slider"
        style={{marginLeft: '0.5em', marginRight:'0.5em', width:'99%'}}
        /* valueLabelDisplay="auto" */
        min={6}
        max={10}
               marks={marks} />
            <Box sx={{ mt: 1 }}>{renderDropdown(1)}</Box>
            {subComponents.length > 0 && <Box sx={{ mt: 1 }}>
            <TextField
                select
                label="Select a variant"
                value={selectedOptionSubComponents}
                onChange={handleChangeDropDown}
                variant="outlined"
                style={{width:'100%'}}>
              {subComponents.map((option, index) => (
                <MenuItem key ={index} value={option.label}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
            </Box>}
        </Grid>
        
      
      <Grid item xs={12-previewWidth} style={{marginLeft:'0.5em', marginRight: '-0.5em', marginTop:'1em'}}>
      
        <Grid container direction="column" style={{ height: "100%" }} >
        <form onSubmit={handleCodeSubmit}>
        <Typography variant="h6" style={{marginBottom:'0.5em'}}>Code Editor</Typography>
          <Grid item style={{  border: '1px solid black', padding:'0.25em', borderRadius: '10px',
                backgroundColor: 'green' }} >


            <AceEditor
              mode="javascript"
              value={code}
              // onChange={handleCodeChange} 
              ref={editorRef}
              name="code-editor"
              // editorProps={{ $blockScrolling: true }} 
              width="100%"
              height="1000px"
              onLoad={onLoad}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                // showLineNumbers: false, 
                tabSize: 2,
              }}
            />
          
            {/* <Button variant="contained" onClick={() => handleCodeChange(code)}>Render</Button> */}
          </Grid>
          <Typography variant="h6" style={{marginBottom:'0em', marginLeft:'0em', marginTop:'1em'}}>Component Actions</Typography>
            <Button style={{marginTop: '1em', marginBottom: '1em'}} 
                  onKeyDown={(event) => {
                    console.log(event)
                    const isCmdOrCtrlPressed = event.metaKey || event.ctrlKey;
                    const isSPressed = event.key === 's' || event.key === 'S';
                
                    if (isCmdOrCtrlPressed && isSPressed) {
                      event.preventDefault(); // Prevent the default browser behavior (Save dialog)
                      console.log('Cmd + S pressed');
                      handleCodeSubmit(event)
                    }}}
                  type="submit" variant="contained" /* onSubmit={() => handleCodeSubmit(code)} */>Render</Button>
                  
            </form>
            <FieldGroupIconButton onClick={() => copyToClipboardFinalComponentImports(selectedComponents[1])}>
              <FileCopyIcon /> Export full component to clipboard
            </FieldGroupIconButton>
            <form onSubmit={handleUpdateCodeSubmit}>
          <TextField label="New Component Name" fullWidth /* value={newComponentName} */ name='componentName' /* onChange={(e) => setNewComponentName(e.target.value)} */ />
          <Button style={{marginTop: '1em'}} type="submit" variant="contained" /* onClick={() => handleUpdateCode(code)} */>Update</Button>
          </form>
          <Button style={{marginTop: '1em', marginLeft:'1em'}} variant="contained" onClick={handleDeleteComponent}>Delete</Button>
            
            
        </Grid>
        
      </Grid>
      
      <Grid item xs={previewWidth}>
      <Typography variant="h6" style={{marginBottom:'0em', marginLeft:'1em', marginTop:'1em'}}>Preview</Typography>
      
        <Grid container direction="column" style={{ height: "100%" }}>
        <Grid item style={{ flexGrow: 2 }} style={{border: '1px solid black', marginLeft:'1em', marginTop:'0.5em', padding:'0.25em', borderRadius: '10px'}} >
            <LiveProvider 
              onError={handleError}
            code={code} scope={
          {
            Accordion,
            EditIcon,
AccordionActions,
AccordionDetails,
AccordionSummary,
Alert,
AlertTitle,
AppBar,
Autocomplete,
Avatar,
AvatarGroup,
Backdrop,
Badge,
BottomNavigation,
BottomNavigationAction,
Box,
Breadcrumbs,
Button,
ButtonBase,
ButtonGroup,
Card,
CardActionArea,
CardActions,
CardContent,
CardHeader,
CardMedia,
Checkbox,
Chip,
CircularProgress,
Collapse,
Container,
CssBaseline,
Dialog,
DialogActions,
DialogContent,
DialogContentText,
DialogTitle,
Divider,
Drawer,
Fab,
Fade,
FilledInput,
FormControl,
FormControlLabel,
FormGroup,
FormHelperText,
FormLabel,
GlobalStyles,
Grid,
Grow,
Hidden,
Icon,
IconButton,
ImageList,
ImageListItem,
ImageListItemBar,
Input,
InputAdornment,
InputBase,
InputLabel,
LinearProgress,
Link,
List,
ListItem,
ListItemAvatar,
ListItemButton,
ListItemIcon,
ListItemSecondaryAction,
ListItemText,
ListSubheader,
LoadingButton,
Masonry,
Menu,
MenuItem,
MenuList,
MobileStepper,
Modal,
NativeSelect,
OutlinedInput,
Pagination,
PaginationItem,
Paper,
Popover,
Popper,
Radio,
RadioGroup,
Rating,
ScopedCssBaseline,
Select,
Skeleton,
Slide,
Slider,
Snackbar,
SnackbarContent,
SpeedDial,
SpeedDialAction,
SpeedDialIcon,
Stack,
Step,
StepButton,
StepConnector,
StepContent,
StepIcon,
StepLabel,
Stepper,
SvgIcon,
SwipeableDrawer,
Switch,
Tab,
TabContext,
Table,
TableBody,
TableCell,
TableContainer,
TableFooter,
TableHead,
TablePagination,
TableRow,
TableSortLabel,
TabList,
TabPanel,
Tabs,
TabScrollButton,
TextField,
Timeline,
TimelineConnector,
TimelineContent,
TimelineDot,
TimelineItem,
TimelineOppositeContent,
TimelineSeparator,
ToggleButton,
ToggleButtonGroup,
Toolbar,
Tooltip,
TreeItem,
TreeView,
Typography,
Zoom,
useState,
React,
Confetti,
styled,
AddCircle,
SearchIcon,
InfoOutlinedIcon,
ArrowUpward,
ArrowDownward,
useDrag,
useSpringRef,
animated,
useTransition,
useSpring,
motion,
VolumeUp,
Carousel,
ArrowBackIos,
ArrowForwardIos,
GridLayout,
WidthProvider,
Responsive,
AddCircleIcon,
DeleteIcon,
AceEditor,
AttachMoneyIcon,
CreditCardIcon,
LocalizationProvider,
DatePicker,
AdapterDateFns,
MenuIcon,
ExpandMoreIcon,
LivePreview,
LiveProvider,
useEffect,
css,
CheckCircle,
             }} noInline>
              <LivePreview />
              <LiveError />
            </LiveProvider>
          </Grid>
          <form onSubmit={handleSubmit}>
          <Typography variant="h6" style={{marginBottom:'0em', marginLeft:'1em', marginTop:'1em'}}>ReactEvolve™</Typography>
          <Card style={{border: '1px solid black', margin:'1em', padding:'1em'}} item>
          <Grid >
            <Grid container alignItems="flex-end">
            
            <Grid item xs={12}>
              <FormControl variant="outlined" style={{marginBottom: '1em'}}>
          <InputLabel id="select-option-label">Option</InputLabel>
          <Select
            labelId="select-option-label"
            value={selectedOption}
            name={'selectedOption'}
            onChange={handleSetOption}
            label="Select Component Operation AI"
          >
            <MenuItem value="Creation">Creation</MenuItem>}
            {plan != 'free' && <MenuItem value="Adjustment">Adjustment</MenuItem>}
            {plan != 'free' && plan != 'solo' && <MenuItem value="From Html">Reproduce</MenuItem>}
            {plan != 'free' && plan != 'solo' && plan != 'lift' && <MenuItem value="ImageGen">ImageGen</MenuItem>}
          </Select>
          
        </FormControl>
        </Grid>
              <Grid item xs>
                
              {selectedOption === 'ImageGen' ? 
              <>
              <TextField
                  multiline
                  fullWidth
                  maxRows={10}
                  /* value={commands} */
                  onChange={(e) => { e.target.value.length > 200 ? handleSetCommand(e) : null}}
                  name={'commands'}
                  /* onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault(); // Prevents newline when pressing Enter
                      sendCommand();
                    }
                  }} */
                  label="Describe component"
                  variant="outlined"
                />
                {commandCount > 400 &&  <TableRow>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Token count: {commandCount}. Reduce to below 400 to generate component.
                    </Typography>
                  </TableCell>
                </TableRow>}
                </>
                :
                <TextField
                  multiline
                  fullWidth
                  maxRows={10}
              /*     value={commands}
                  onChange={(e) => handleSetCommand(e)} */
                  name={'commands'}
                  /* onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault(); // Prevents newline when pressing Enter
                      sendCommand();
                    }
                  }} */
                  label="Describe component"
                  variant="outlined"
                />
                }
                
                <Grid item >
               
       {selectedOption === 'ImageGen' &&  <FormControl variant="outlined" style={{marginTop: '1em'}}>
          <InputLabel id="select-option-label" >Size</InputLabel>
            <Select
              labelId="select-size-label"
              /* value={selectedSize} */
              style={{width: '10em'}}
              name={'size'}
              /* onChange={handleSetSize} */
              label="Select Component Operation AI"
            >
              <MenuItem value="verysmall">Very Small (256x256px)</MenuItem>
              <MenuItem value="small">Small (512x512px)</MenuItem>
              <MenuItem value="normal">Normal (1024x1024px)</MenuItem>
              {/* <MenuItem value="landscape">Landscape (1024x512px)</MenuItem> */}
            </Select>
          
        </FormControl>}
        
        
      </Grid>
               {/*  <Grid item sx={{marginTop: '1em'}} >
                <FormControl variant="outlined">
          <InputLabel id="select-option-label">Adjustment</InputLabel>
          <Select
            labelId="select-option-label"
            value={selectedOptionAdjustment}
            name={'Adjustment'}
            onChange={handleSetOptionAdjustment}
            label="Adjustment"
          >
            <MenuItem value="Adjust">Adjust</MenuItem>
            <MenuItem value="New">Create New</MenuItem>
            
          </Select>
                 </FormControl>
        
      </Grid> */}
              </Grid>
             
     
      {/* {(selectedOption === 'Creation' || selectedOption === 'Adjustment') && ( */}
        <Grid container style={{marginTop:'1em'}} >
          <Grid item xs >
            <TextField
              multiline
              maxRows={10}
              fullWidth
              name={'componentName'}
              /* value={componentName} */
              /* onChange={(e) => handleSetName(e)} */
              label="Enter Component Name"
              variant="outlined"
            />
          </Grid>
         
         
        </Grid>
      {/* )} */}
      {selectedOption === 'From Html' && (
        <Grid container style={{marginTop:'1em'}} >
          <TableRow>
          <Grid item xs >
            <TextField
              multiline
              maxRows={10}
              fullWidth
              name={'html'}
              /* value={htmlContent} */
              onChange={(e) => handleSetHtmlContent(e)}
              label="Enter HTML code"
              variant="outlined"
            />
          </Grid>
          <Grid item>
          <TableRow>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Token count: {tokenCount/4}
                    </Typography>
                  </TableCell>
                </TableRow>
            
          </Grid>
          </TableRow>
          <TableRow>
          <Grid item xs >
            <TextField
              multiline
              maxRows={10}
              name={'css'}
              fullWidth
              /* value={cssContent} */
              onChange={(e) => handleSetCSSContent(e)}
              label="Enter CSS code"
              variant="outlined"
            />
          </Grid>
          <Grid item>
          <TableRow>
                  <TableCell>
                    <Typography variant="body2" color="textSecondary" component="p">
                      Token count: {tokenCountCSS/4}
                    </Typography>
                  </TableCell>
                </TableRow>
            
          </Grid>
          </TableRow>  
        
         
        </Grid>
      )}
              <Grid item>
              
              {/* <ListItemText
                    secondary={`${tokenEstimate} input tokens`} //will result in ${tokenEstimateTotal} $ input costs
                  />  */}
              
              {commandCount > 400 ? <Button
                  variant="contained"
                  color="primary"
                  /* onClick={sendCommand} */
                  style={{ marginLeft: "1em", marginTop: '1em' }}
                  type="submit"
                  disabled
                >
                  Send
                </Button>
                :
               <Button
                  variant="contained"
                  color="primary"
                  /* onClick={sendCommand} */
                  style={{ marginLeft: "1em", marginTop: '1em'  }}
                  type="submit"
                  
                >
                  Send
                </Button>}
                
              </Grid>
              
              
            </Grid>
            
          </Grid>
          </Card>
          </form>

          <Typography variant="h6" style={{marginBottom:'0em', marginLeft:'1em', marginTop:'1em'}}>Last Commands</Typography>
          <Card style={{border: '1px solid black', margin:'1em', padding:'1em'}} item>
          <Grid container style={{ flexGrow: 1, overflow: "auto" }}>
            <List>
              {commandList.map((cmd, index) => (
                <ListItem key={index}>
                  {cmd.responseReceived ? <ListItemText
                  onClick={() => handleSetSelectedCommand(cmd)}
                    /* primary={cmd.textShort} */
                    secondary={`Processed in ${Math.floor(((cmd.responseReceived-cmd.createdAt)/1000))} Seconds`}
                  /> :
                  <ListItemText
                  onClick={() => handleSetSelectedCommand(cmd)}
                    /* primary={cmd.textShort} */
                    secondary={`Sent at ${cmd.datum}`}
                  />}
                 {cmd && <MyAccordion cmd={cmd} />}
                  <ListItemIcon>
                    {cmd.status === "pending" ? (
                      <>
                      <CircularProgress size={40} />
                      <div style={{marginLeft: '-2em', marginTop:'0.6em'}}>
                      <CountDownTimer  />

                      </div>
                      </>
                      
                    ) : (
                      <>{cmd.status === 'failed' ? 
                      <CancelIcon color="error" /> :
                      <CheckCircle color="success" />}
                      </>
                      
                    )}
                  </ListItemIcon>
                </ListItem>
              ))}
            </List>
          </Grid>
          </Card>

          
         
        </Grid>
        
      </Grid>
      <Snackbar
  open={snackBarOpen}
  autoHideDuration={6000}
  onClose={handleClose}
  message={snackBarMessage}
  /* action={action} */
/>
    </StyledGrid>
  );
};

export default NewEditor;