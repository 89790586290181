import React from 'react';
import { Avatar, Card, CardContent, CardHeader, CardMedia, Grid, Typography } from '@mui/material';
import styled  from '@emotion/styled';

const teamMember = {
  name: 'Constantin',
  title: 'React Enthusiast',
  imageUrl: 'https://reactevolveimg.s3.eu-central-1.amazonaws.com/profilePicCartoon.jpg',
  description: `Feeling the urge to enhance my productivity while working with repetitive React UI components, I crafted ReactEvolve to streamline my workflow. Now, I'm thrilled to share this AI-powered suite with fellow React Devs, enthusiasts and learners, enabling everyone to reap the benefits of an AI-boosted component creation process and a community library of code examples. Let's elevate our development experience together!`
  /* description: `Tired of programming redundant React UI components, Constantin created ReactEvolve for his own efficiency.
                Now, he's opening up access to this AI suite so all React student & developers can profit from the efficiency
                gains coming from AI-enhanced component creation and the community component library.` */
};

const StyledCardMedia = styled(CardMedia)({
  marginTop: '1em'
});

const AboutUs = () => {
  return (
    <Grid container marginTop={'2em'} marginBottom={'2em'} spacing={2} alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={6}>
        <Card>
          <CardHeader
            avatar={
              <Avatar src={teamMember.imageUrl}>
                {teamMember.name[0]}
              </Avatar>
            }
            title={teamMember.name}
            subheader={teamMember.title}
          />
          <CardContent>
            <StyledCardMedia
              component="img"
              alt={`${teamMember.name} - ${teamMember.title}`}
              image={teamMember.imageUrl}
              title={`${teamMember.name} - ${teamMember.title}`}
            />
            <Typography variant="body1" component="p" style={{ marginTop: '1em' }}>
              {teamMember.description}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AboutUs;
