import React from 'react';
import { Button, Card, CardActionArea, CardContent, CardMedia, Typography, SvgIcon } from '@mui/material'
import styled from '@emotion/styled'

const StyledCard = styled(Card)({
  maxWidth: 345,
});

const StyledCardMedia = styled(CardMedia)({
  height: 140,
});

const StyledStarIcon = styled(SvgIcon)({
  display: 'block',
  height: 12,
  width: 12,
  fill: 'currentColor',
});

const StarIcon = (props) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path d="M15.094 1.579l-4.124 8.885-9.86 1.27a1 1 0 0 0-.542 1.736l7.293 6.565-1.965 9.852a1 1 0 0 0 1.483 1.061L16 25.951l8.625 4.997a1 1 0 0 0 1.482-1.06l-1.965-9.853 7.293-6.565a1 1 0 0 0-.541-1.735l-9.86-1.271-4.127-8.885a1 1 0 0 0-1.814 0z" fillRule="evenodd" />
  </SvgIcon>
);

const DestinationCard = () => {
  return (
    <StyledCard>
      <CardActionArea>
        <StyledCardMedia
          image="https://a0.muscache.com/im/pictures/22f86e64-0d34-4237-b47c-d41aff588de0.jpg?im_w=720"
          title="Amsterdam, Netherlands"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Amsterdam, Netherlands
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            608 kilometers away
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            May 23 – 28
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            2,142 CHF total
          </Typography>
          <div>
            <StyledStarIcon />
            <span>4.6</span>
          </div>
        </CardContent>
      </CardActionArea>
      <Button>Save</Button>
    </StyledCard>
  );
};

export default DestinationCard;