import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Link, Outlet, Routes } from 'react-router-dom';
import { AppBar, Typography, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ProcessStepper from './ProcessStepper';

const Dashboard = () => {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const navigationItems = [
    { name: 'Dashboard', path: '/', description: 'This is the Dashboard page.' },
    { name: 'Customer Meeting', path: '/customer-meeting', description: 'This is the Customer Meeting page.' },
    { name: 'Guides', path: '/guides', description: 'This is the Guides page.' },
    { name: 'Personas', path: '/personas', description: 'This is the Personas page.' },
    { name: 'Products', path: '/products', description: 'This is the Products page.' },
    { name: 'Deep Knowledge', path: '/deep-knowledge', description: 'This is the Deep Knowledge page.' },
  ];

  return (
    <Router>
      <AppBar position="static" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">
          Logo
        </Typography>
        <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer}>
          <MenuIcon />
        </IconButton>
      </AppBar>
      <Drawer anchor="left" open={open} onClose={toggleDrawer}>
        <List>
          {navigationItems.map((item, index) => (
            <ListItem button key={index} component={Link} to={item.path} onClick={toggleDrawer}>
              <ListItemText primary={item.name} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Routes>
        
          {navigationItems.map((item, index) => {
            if (item.name === 'Customer Meeting') {
            return(
              <Route key={index} path={item.path} element={<PageCustomerMeeting name={item.name} description={item.description} />} />
              )
            } else {
              return(
              <Route key={index} path={item.path} element={<Page name={item.name} description={item.description} />} />
            )
            }
          })
            
        }
        
      </Routes>
    </Router>
  );
};

const Page = ({ name, description }) => (
  <div style={{ padding: '16px' }}>
    <Typography variant="h5">{name}</Typography>
    <Typography>{description}</Typography>
  </div>
);
const PageCustomerMeeting = ({ name, description }) => (
  <div style={{minWidth:'900px', padding: '16px' }}>
    <Typography variant="h5">{name}</Typography>
    <Typography>{description}</Typography>
    <ProcessStepper />
  </div>
);

export default Dashboard;