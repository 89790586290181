import { Avatar, Box, Button, Card, CardContent, CardHeader, Container, Dialog, DialogContent, FormControl, Grid, List, ListItem, ListItemText, Menu, MenuItem, Modal, Select, Snackbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PaypalButtonApp from "./paypalButton";
import { GetReferal, GetUser, UpdateUserPlan } from "../services/graphQlService";
import FAQAccordion from "./faq";
import { Link } from "react-router-dom";
import Corporate2 from "../components/ContactForm";
import styled from "@emotion/styled";
import PricingModal from "../components/PricingModal";
import { InfoOutlined } from "@mui/icons-material";


const UserProfile = (props) => {
    const [credits, setCredits] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [amount, setAmount] = useState('');
    const [userDetails, setUserDetails] = useState({});
    const [finalizeRegistration, setFinalizeRegistration] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ newPlan, setNewPlan ] = useState(null);
    const   [currentPlan, setCurrentPlan] = useState(null);
    const [disablePlanChange ,setDisablePlanChange] = useState(true)
    const [pricingModalOpen, setPricingModalOpen] = useState(false);

    const user = {
      name: 'John Doe',
      email: 'john.doe@example.com',
      credits: credits,
      plan: 'Free',
    };


    const DropdownButton = styled(Button)({
      WebkitTextSizeAdjust: '100%',
      WebkitFontSmoothing: 'antialiased',
      fontFamily: 'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      fontSize: '0.875rem',
      lineHeight: '1.75',
      minWidth: '64px',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      color: 'inherit',
      borderRadius: '12px',
      textTransform: 'none',
      padding: '9px 16px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      boxSizing: 'border-box',
      WebkitTapHighlightColor: 'transparent',
      backgroundColor: 'transparent',
      outline: '0px',
      border: '0px currentcolor',
      margin: '0px',
      cursor: 'pointer',
      userSelect: 'none',
      verticalAlign: 'middle',
      appearance: 'none',
      textDecoration: 'none',
      fontWeight: 600
    });

    const addAdditionalPlanInfo = (userData) => {
      console.log({userData})
      let user = userData
      /* free: [{name: 'Creation', access: true}, {name: 'Adjustment', access: false}, {name: 'Inspire', access: false}, {name: 'ImageGen', access: false}], */
      let userAccessLevels = {
        free: ['Creation'],
        solo: ['Creation', 'Adjustment'],
        lift: ['Creation', 'Adjustment', 'Inspire'],
        fly: ['Creation', 'Adjustment', 'Inspire', 'ImageGen'],
      }

      switch (user.subscriptionLevel.toLowerCase()) {
        case 'free':
          user.accessLevels = userAccessLevels.free
          user.includedCreditsMt = 0
          user.includedCreditsOneTime = 5
          user.maxOwnComponents = 2
          user.initToPay = 0
          break;
        case 'solo':
          user.accessLevels = userAccessLevels.solo
          user.includedCreditsMt = 10
          user.includedCreditsOneTime = 10
          user.maxOwnComponents = 10
          user.initToPay = 9
          break;
        case 'lift':
          user.accessLevels = userAccessLevels.lift
          user.includedCreditsMt = 50
          user.includedCreditsOneTime = 50
          user.maxOwnComponents = 100
          user.initToPay = 29
          break;
        case 'fly':
          user.accessLevels = userAccessLevels.fly
          user.includedCreditsMt = 100
          user.includedCreditsOneTime = 100
          user.maxOwnComponents = 1000
          user.initToPay = 79
          break;
        default:
          break;
      }
      return user
    }


    const getUser = async (id) => {
      console.log({id})
        let user = await GetUser(id)
        console.log({user})
        if(user.data.getUser){

          let userRes = user.data.getUser
          userRes = addAdditionalPlanInfo(userRes)
          console.log({userRes})
            setUserDetails(user.data.getUser)
            setCurrentPlan(userRes.subscriptionLevel)
            setNewPlan(userRes.subscriptionLevel)
            setFinalizeRegistration(true)
        } else {
          setFinalizeRegistration(false)
          handleFinalizeRegistration()
        }
     
    }

    useEffect(() => {
      
        getUser(props.user)
      
    }, [])
  
    const handleOpenModal = () => {
      setOpenModal(true);
    };
  
    const handleCloseModal = () => {
      setOpenModal(false);
      setAmount('');
    };

    const handleCloseSnackbar = () => {
      setSnackBarOpen(false)
    }
  
    const handleAmountChange = (event) => {
        console.log(event)
      setAmount(event.target.value);
    };
  
    const handleAddCredits = () => {
      setCredits(credits + parseInt(amount));
      handleCloseModal();
    };

    const handleFinalizeRegistration = () => {
      props.handleFinalizeRegistration()
    }

    const handleCopyReferralLink = async () => {
      navigator.clipboard.writeText(`https://www.reactevolve.com/${userDetails.id}`)
      /* navigator.clipboard.writeText(`http://localhost:3000/${userDetails.id}`) */
      setSnackBarMessage('Referral link copied to clipboard')
      setSnackBarOpen(true)

    }

    const changeAmount = (amount) => {
      setAmount(amount)
    }

    const handlePlanChange = (event) => {
      setNewPlan(event.target.value)
      let userDetailsTemp = userDetails
      userDetailsTemp.subscriptionLevel = event.target.value
      let userTemp = addAdditionalPlanInfo(userDetailsTemp)
            setUserDetails(userTemp)
          console.log(currentPlan)
          console.log(event.target.value)
           if(currentPlan.toLowerCase() === event.target.value.toLowerCase()){

            setDisablePlanChange(true)
           } else {
            setDisablePlanChange(false)
           }
    };

    const handleClose = (event) => {
      setAnchorEl(null);
    };

    const handlePlanChangeSubmit = async () => {
      console.log({newPlan})

      let plan = newPlan.toLowerCase()
      /* userDetails.credits = 100 */
      switch (plan) {
        case 'free':
          plan = 'FREE'
          break;
        case 'solo':
          console.log('solo')
          console.log(userDetails)
          if(userDetails.credits < 9){
            setSnackBarMessage('Please upload 9 USD to the credits in order to change to this plan')
            
            setSnackBarOpen(true)
            return
          }
          break;
        case 'lift':
          plan = 'LIFT'
          if(userDetails.credits < 29){
            setSnackBarMessage('Please upload 29 USD to the credits in order to change to this plan')
            setSnackBarOpen(true)
            return
          }
          break;
        case 'fly':
          plan = 'FLY'
          if(userDetails.credits < 79){
            setSnackBarMessage('Please upload 79 USD to the credits in order to change to this plan')
            setSnackBarOpen(true)
            return
          }
          break;
        default:
          break;
      }
      

      let user = await UpdateUserPlan(userDetails.id, currentPlan, newPlan)
      
      console.log({user})
      if(user && user.body.message){
        setSnackBarMessage(user.body.message)
        setSnackBarOpen(true)
        getUser(props.user)
      } else {
        setSnackBarMessage('Something went wrong')
        setSnackBarOpen(true)
      }
    }

    const handleClosePricing = () => {
      setPricingModalOpen(false)
    }
    

  
    return (
      <Container>
        <Card style={{marginTop:'2em', marginBottom:'2em', width:'100%'}}>
          <CardHeader
            avatar={<Avatar>{String(userDetails.name)[0]}</Avatar>}
            title={userDetails.name}
            subheader={userDetails.email}
          />
          <Grid container>
            <Grid item xs={12} sm={6}>

             <CardContent>
        <Typography variant="h6">Profile Information</Typography>
        <Box mt={2}>
          <Typography>
            Credits: <span style={{ color: 'blue' }}>{userDetails.credits}</span>
          </Typography>
        </Box>
        <Box mt={2}>
          {userDetails.subscriptionLevel && (
            <>
            <Typography>
              Current Plan: <span style={{ color: 'blue' }}>{currentPlan.toUpperCase()}</span> 
              
            </Typography>
            <Grid container spacing={2} style={{ marginBottom: "1em" }} alignItems={"center"} alignContent={"center"}>
          <Grid item xs={12} sm={1}>
            
            <InfoOutlined style={{color:"blue"}} onClick={() => setPricingModalOpen(true)} />
            </Grid>
            <Grid item xs={12} sm={11}>
            <Typography>
              Plan:  <Select
            value={newPlan}
            onChange={handlePlanChange}
            /* disabled={!selectedAgeRange} */
            displayEmpty
          >
            <MenuItem value="free">
              <em>Free</em>
            </MenuItem>
            <MenuItem value="solo">
              <em>Solo</em>
            </MenuItem>
            <MenuItem value="lift">
              <em>Lift</em>
            </MenuItem>
            <MenuItem value="fly">
              <em>Fly</em>
            </MenuItem>
            
          </Select>
          <Button onClick={() => handlePlanChangeSubmit()} style={{marginLeft:'1em'}} variant="contained" disabled={disablePlanChange} color="primary">
            Change Plan
          </Button>
              
            </Typography>
            </Grid>
            </Grid>
           
           
      
            {newPlan === currentPlan && userDetails.credits < 1 && userDetails.initToPay > 0 &&<Typography>
              <span style={{ color: 'red' }}>Please add at least {userDetails.initToPay} $ to your account.</span>
           </Typography>}
           </>
          )}
        </Box>
        
        <Box mt={2}>
          <Typography>Included Credits mtly: <span style={{ color: 'blue' }}>{userDetails.includedCreditsMt}</span></Typography>
        </Box>
        {/* {userDetails.credits <1 &&<Box mt={2}>
          <Typography>Included Credits Early Bird: <span style={{ color: 'blue' }}>{userDetails.includedCreditsOneTime}</span></Typography>
        </Box>} */}
       
        <Box mt={2}>
          <Typography>My Components Limit: <span style={{ color: 'blue' }}>{userDetails.maxOwnComponents}</span></Typography>
        </Box>
        
         <Box mt={2}>
         {finalizeRegistration ?
          <Button variant="contained" color="primary" onClick={handleOpenModal}>
            Add Credits
          </Button>
          : 
          <Button variant="contained" color="primary" onClick={handleFinalizeRegistration}>
            Finalize Registration
          </Button>
      }
        </Box>
      </CardContent>
            </Grid>
            <Grid item xs={12} sm={6}>
            <CardContent>
        <Typography variant="h6">Access Levels</Typography>
        <Box >
          
          {userDetails.accessLevels && (
            <List>
              {userDetails.accessLevels.map((item, index) => (
                <ListItem key={index}>
                 {/* <Link to={'/editor'}>  */}
                 <Typography style={{ color: 'blue' }}>ReactEvolve {item}</Typography>
                 {/* </Link> */}
                </ListItem>
              ))}
            </List>
          )}
        </Box>
        </CardContent>
        </Grid>
        </Grid>

        </Card>
        {finalizeRegistration && <Card style={{marginTop:'2em', marginBottom:'2em', width:'100%'}}>
        <CardHeader
            title="Referral"
          />
        <CardContent>
        <Grid container>
          <Grid item xs={12} sm={6}>
          
        <Button variant="contained" color="primary" onClick={handleCopyReferralLink}>Copy Referral Link</Button>
        <br/><br/>
        <Typography variant="h8" color={"darkgray"}>Get +5 credits per referred user. <br/> Just copy the link and share with your friends.</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
        {userDetails.referredUsers && userDetails.referredUsers.length > 0 && 
        <>
        <Typography variant="h6">Successfully referred Users</Typography>
        <Box mt={2}>
          <Grid container alignItems={'center'} justifyItems={'center'}>
            <Grid item xs={12} sm={6} >
          <Typography style={{textAlign: "center"}}>
          {userDetails.referredUsers.length}
          </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Typography style={{textAlign: "center"}}>
            Earned credits: +{userDetails.referredUsers.length * 5}
          </Typography>
          </Grid>
          </Grid>
         {/*  {userDetails.referredUsers.map((item, index) => {
          return(
            <Grid container key={index}>
              <Grid item xs={12} sm={6}>
          <Typography>
            {item}
          </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Typography>
            +{2} credits
          </Typography>
          </Grid>
          </Grid>

          )})} */}
        </Box>
        </>}
        </Grid>
        </Grid>
        </CardContent>
        </Card>}
        {finalizeRegistration && <Card style={{marginTop:'2em', marginBottom:'2em', width:'100%'}}>
        <CardHeader
            title="Feedback"
          />
        <CardContent>
        
        <Corporate2 user={userDetails} />
        </CardContent>
        </Card>}

        {finalizeRegistration && <Card style={{marginBottom:"1em", padding:"1em"}}>
        <CardHeader
            title="FAQ"
          />
        <FAQAccordion variant='faq2Member' />
        </Card>}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="add-credits-modal"
          aria-describedby="select-amount-to-add"
        >
            <Dialog open={openModal} onClose={handleCloseModal}>
            <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Container>
            <Grid container alignContent={'center'} justifyContent={'center'} alignItems={'center'}>
              <Grid item xs={12} sm={12} alignSelf={'center'} style={{textAlign:"center", paddingBottom:'1em'}}>
          <FormControl>
        <Select value={amount} displayEmpty onChange={(e:any) => {handleAmountChange(e)}} >  
            
            <MenuItem value="">
                  <em>Select amount</em>
                </MenuItem>
             <MenuItem  value="0.1">0.1</MenuItem>
            <MenuItem  value="5">1</MenuItem>
            <MenuItem  value="5">5</MenuItem>
            <MenuItem  value="9">9</MenuItem>
            {/* <MenuItem  value="10">10</MenuItem> */}
            <MenuItem  value="15">15</MenuItem>
            {/* <MenuItem  value="20">20</MenuItem> */}
            <MenuItem  value="29">29</MenuItem>
            {/* <MenuItem  value="30">30</MenuItem> */}
            <MenuItem  value="50">50</MenuItem> 
            <MenuItem  value="79">79</MenuItem> 
           {/*  <MenuItem  value="9">9</MenuItem>
            <MenuItem  value="29">29</MenuItem>
            <MenuItem  value="49">49</MenuItem> */}
            <MenuItem  value="100">100</MenuItem>
        </Select>
        </FormControl>
        </Grid>
            <PaypalButtonApp amount={amount} init={userDetails.initToPay} modalState={openModal}  />
            </Grid>
          </Container>
          </DialogContent>
          </Dialog>
        </Modal>
        <Snackbar open={snackBarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar} message={snackBarMessage} />
        <PricingModal open={pricingModalOpen} onClose={handleClosePricing} />
      </Container>
    );
  };
  
  export default UserProfile 
  
  /* This component used 1195 tokens in total. Creation costs on GPT4: 0.05622 $ and on GPT3.5Turbo 0.00239 $ */