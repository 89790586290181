import React from 'react';
import { Typography } from '@mui/material';

const CookiePolicy = () => {
  return (
    <div>
      <Typography variant="h2">Cookie Policy</Typography>
      <br />
      <Typography>
        This website and related websites ("Website") are owned and operated by:
      </Typography>
      <br />
      <img width={"30%"} src="https://reactevolveimg.s3.eu-central-1.amazonaws.com/imprint1.jpg"></img>
      <Typography>
        We are pleased that you are visiting a website of Schoo IT (hereinafter: SchooIT). On this website, certain data is collected using cookies and other online technologies. Since we place great value on your privacy and the protection of your personal data, we would like to inform you here how we use and protect the data collected about you. For SchooIT, it is a matter of course that we comply with all applicable legal provisions and regulations on data protection, including the EU General Data Protection Regulation ("GDPR").
      </Typography>
      <br />
      <Typography>
        This cookie policy and our privacy policy describe the policies and procedures of SchooIT regarding its use of the data collected on this website and your privacy rights.
      </Typography>
      <br />
      <Typography>
        By using this site and agreeing to this cookie policy, you consent to SchooIT and the related projects explained in the privacy policy placing cookies - as described below - and collecting, sharing, and using your personal data for the personalization of advertising or other services.
      </Typography>
<br/>
      <Typography variant="h4">Why do we use cookies?</Typography>
      
      <Typography>
        Cookies improve your user experience. We use cookies to make your use of this website faster and more efficient. Cookies enable us to distinguish you from other users and recognize you every time you visit our website. Cookies also allow us to store user preferences and provide you with a positive experience when navigating this website, including personalizing your website settings. Our marketing partners also collect data on web views to ensure that the most relevant advertising is displayed to you.
      </Typography>
      <br/>
      <Typography variant="h4">What are cookies?</Typography>
      
      <Typography>
        Cookies are small text files that can be stored on a user's own computer, tablet or mobile device ("device") when visiting a website. A cookie typically contains the name of the originating domain, the duration of the cookie's validity, and a randomly generated unique number or another value.
      </Typography>
      <Typography>
        The cookies used by SchooIT identify you using a tracking ID as a unique user. Cookies themselves are anonymous and do not contain personal data unless the data is provided by you, for example, when entering your name and email address when completing a registration form.
      </Typography>
      <br/>
      <Typography variant="h4">GDPR and your privacy rights</Typography>
      
      <Typography>
        If a cookie placed by SchooIT or a third party, as described above, can uniquely identify a device or the user of that device, it constitutes personal data within the meaning of the GDPR. Therefore, the privacy policy applies to such personal data collected from users of this website.
      </Typography>
      <br/>
      <Typography variant="h4">Changes and updates to the cookie policy</Typography>
      
      <Typography>
        We reserve the right to change this cookie policy occasionally. In the case of significant changes to the cookie policy, we will publish a notice on this website. Nevertheless, you should regularly review the current policy on this website.
      </Typography>
      <br/>
      <Typography variant="h4">How to contact us</Typography>
      
      <Typography>
        If you have any questions, concerns or complaints about SchooIT's handling of personal data or this cookie policy, you should contact our designated Data Protection Officer ("DPO").
      </Typography>
      <Typography>
        If you believe that you have suffered a loss as a result of a breach of your rights by SchooIT under this cookie policy and that SchooIT has not handled your complaint appropriately, you may also file a complaint with the competent supervisory authority.
      </Typography>
      <Typography>
        Contact details of SchooIT's Data Protection Officer:
        
      </Typography>
      <img width={"30%"} src="https://reactevolveimg.s3.eu-central-1.amazonaws.com/imprint1.jpg"></img>
    </div>
  );
};

export default CookiePolicy;