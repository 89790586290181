import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Rating,
  Switch,
  TextField,
} from '@mui/material';
import Login from './login';
import HowItWorksStepper from './HowItWorks';

const CommentField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

function HIWModal(props) {
    let open = props.open
    let onClose = props.onClose
    let  text =   props.text
  console.log('LoginModal', props)
  const [ratingValue, setRatingValue] = useState(null);
  const [commentValue, setCommentValue] = useState('');
  const [anonymValue, setAnonymValue] = useState(false);

  const handleRatingChange = (event, newValue) => {
    setRatingValue(newValue);
  };
  const handleSwitchChange = (event, newValue) => {
    setAnonymValue(newValue);
  };

  const handleCommentChange = (event) => {
    setCommentValue(event.target.value);
  };

  const handleCancel = () => {
    onClose(null);
  };

  const handleOk = () => {
    onClose({ rating: ratingValue, comment: commentValue, anonym: anonymValue });
  };

  const handleLoggedInFromLogin = (loggedIn) => {
    console.log('loggedin 1st layer', loggedIn)
    props.loggedIn(loggedIn)
  }
  



  return (
    <Dialog  open={open} onClose={handleCancel}>
    <DialogTitle style={{ textAlign: 'center' }} >{text}</DialogTitle>
    <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      
          <HowItWorksStepper />
      
      
    </DialogContent>
    <DialogActions
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Button onClick={handleCancel}>Close</Button>
      {/* <Button onClick={handleOk}>Ok</Button> */}
    </DialogActions>
  </Dialog>
  );
}

export default HIWModal;
