import { Autocomplete, Button, Chip, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const TagSelector = (props) => {
  /* console.log = function () {} */
    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [selectedTags, setSelectedTags] = useState(props.tags || []);
  /* console.log(props) */
    // Dummy data for tags
    const allTags = [
      { title: 'Tag1' },
      { title: 'Tag2' },
      { title: 'Tag3' },
      { title: 'Tag4' },
      { title: 'Tag5' },
    ];
  
    // Filter the tags based on the input value
    const filteredTags = allTags.filter((tag) =>
      tag.title.toLowerCase().includes(inputValue.toLowerCase())
    );
  
    const handleTagSelection = (tag) => {
      setSelectedTags((prevState) => [...prevState, tag]);
    };
  
    const handleTagDelete = (tagToDelete) => () => {
      setSelectedTags((prevState) => prevState.filter((tag) => tag.title !== tagToDelete.title));
    };
    
      const addNewTag = () => {
      let newTags = [...selectedTags]
      newTags.push({title:inputValue})
      setSelectedTags(newTags)
    };

    useEffect(() => {
        props.updateParent(selectedTags)
    }, [selectedTags])

  
    return (
      <div style={{ display: 'flex', flexDirection: 'row', gap: '1em' }}>
       <Grid container spacing={2}>
        <Grid item xl={12}>

        <Autocomplete
          /* value={inputValue} */
          inputValue={inputValue}
          style={{ width: 300 }}
          onChange={(event, newTags) => {
            setTags(newTags);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue || "");
          }}
          onSelect={(event) => {
            const selectedTag = filteredTags.find((tag) => tag.title === event.target.value);
            if (selectedTag) {
              handleTagSelection(selectedTag);
              setInputValue('');
              setTags([]);
            }
          }}
          onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault(); // Prevents newline when pressing Enter
                        addNewTag();
                      }
                    }}
          options={filteredTags}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => <TextField {...params} label="Select or set new tags" />}
        />
        </Grid>
        <Grid item xl={6}>
        <div>
        
        {selectedTags.map((tag) => (
          <Chip
            key={tag.title}
            label={tag.title}
            onDelete={handleTagDelete(tag)}
            style={{ marginRight: '1em' }}
          />
        ))}
      </div>
        </Grid>
        </Grid>
      </div>
    );
  };
  
  export default TagSelector 
  
  /* This component used 1086 tokens in total. Creation costs on GPT4: 0.05058 $ and on GPT3.5Turbo 0.0021720000000000003 $ */