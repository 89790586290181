import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { useSpring, animated } from '@react-spring/web';
import styled from '@emotion/styled';


const StyledCard = styled(Card)({
  width: '300px',
  margin: '1em',
  height: "400px",
  perspective: '1000px',
  cursor: 'pointer',
});

const StyledCardMedia = styled(CardMedia)({
  height: '200px',
  marginTop: '1em',
});

const FlipCard = () => {
  const [isFlipped, setIsFlipped] = useState(false);
  const { transform, opacity } = useSpring({
    opacity: isFlipped ? 1 : 0,
    transform: `rotateY(${isFlipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  const handleMouseEnter = () => {
    setIsFlipped(true);
  };

  const handleMouseLeave = () => {
    setIsFlipped(false);
  };

  return (
    <StyledCard
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <animated.div
        style={{
          opacity: opacity.to((o) => 1 - o),
          transform,
          position: 'absolute',
        }}
      >
        <CardContent>
          <Typography variant="h5">Front Side</Typography>
          <StyledCardMedia
            component="img"
            image="https://via.placeholder.com/150"
          />
          <Typography variant="body2">
            This is the front side of the card. Hover over the card to see the back side.
          </Typography>
        </CardContent>
      </animated.div>

      <animated.div
        style={{
          opacity,
          transform: transform.to((t) => `${t} rotateY(180deg)`),
          position: 'absolute',
        }}
      >
        <CardContent>
          <Typography variant="h5">Back Side</Typography>
          <StyledCardMedia
            component="img"
            image="https://via.placeholder.com/150"
          />
          <Typography variant="body2">
            This is the back side of the card.
          </Typography>
        </CardContent>
      </animated.div>
    </StyledCard>
  );
};


export default FlipCard 