import { Box, Card, CardContent, Container, Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useState } from "react";

const FlipCardContainer = styled(Box)({
    perspective: "1000px",
    display: "inline-block",
    margin: "1em",
  });
  
  const FlipCardInner = styled(Box)({
    position: "relative",
    width: "350px",
    height: "600px",
    textAlign: "center",
   transition: "transform 0.8s",
    transformStyle: "preserve-3d"
  });
  
  const FlipCardFront = styled(Card)({
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden"
  });
  
  const FlipCardBack = styled(Card)({
    position: "absolute",
    width: "100%",
    height: "100%",
    backfaceVisibility: "hidden",
    overflowY: "scroll",
    transform: "rotateY(180deg)"
  });
  
  const StyledCardMedia = styled(CardContent)({
    marginTop: '1em',
  });
  
  const StyledCard = styled(Card)({
    perspective: '1000px',
    '&:hover > div:first-of-type': {
      transform: 'rotateY(180deg)',
    },
  });
  
  const CardInner = styled(Card)({
    transformStyle: 'preserve-3d',
    transition: '0.6s',
  });
  
  const CardFace = styled(Card)({
    backfaceVisibility: 'hidden',
    position: 'absolute',
    width: '100%',
    height: '100%',
  });
  
  const CardFront = styled(CardFace)({
    backgroundColor: '#fff',
  });
  
  const CardBack = styled(CardFace)({
    backgroundColor: '#f8f8f8',
    transform: 'rotateY(180deg)',
  });
  
  const FeaturesReactorAI = () => {
    const title = 'Why?';
    const subtitle = 'Develop faster with ReactEvolve';
    const subtitle1 = 'ReactEvolve™ AISuite';
    /* const description = `StartupAI is an innovative company that aims to revolutionize the way React developers code by providing them with cutting-edge AI tools. Our vision is to create a future where AI-powered tools seamlessly integrate with the developers' workflow, enabling them to work more efficiently and accurately.`;
    const missionStatement = `At StartupAI, our mission is to empower React developers by offering them AI tools that streamline their coding process, reduce errors, and ultimately, help them develop high-quality applications faster. By harnessing the power of artificial intelligence, we strive to transform the way developers work and redefine the limits of what they can achieve.`; */
    const description = `ReactEvolve™ aims to improve the way React Developers code by providing them with cutting-edge AI tools. Our vision is to create a future where AI-powered tools seamlessly integrate with the developers' workflow, inspiring them to work more efficiently and accurately while saving time.`;
    /* const missionStatement = `ReactEvolve™ aims to empower both aspiring and experienced React Developers by offering them AI tools that streamline their coding process, reduce errors, and ultimately, help them develop high-quality applications faster. With the ReactEvolve™ AISuite, we harness the power of artificial intelligence to transform the way developers work and redefine the limits of what they can achieve. Our vision is to create a future where AI-powered tools seamlessly integrate with the developers' workflow, inspiring them to work more efficiently and accurately while saving time.`; */
    /* const missionStatement = `ReactEvolve™ AI tools are here to amp up your coding experience as a developer, but hold on - we believe teamwork makes the dream work! 💪 The AI-generated components might need some reviewing, and maybe even a few tweaks here and there. 🧐 But you know the drill, keeping an eye on what's being created ensures perfection. 👌✨ So with ReactEvolve™, you'll save time and energy, but still, be necessary for your usual review process! It's teamwork between you and AI for a seamless coding journey ahead! 🤝💻`; */
    const missionStatement = `ReactEvolve™ AI tools are here to amp up your coding experience as a developer, but hold on - we believe teamwork makes the dream work! The AI-generated components might need some reviewing, and maybe even a few tweaks here and there. But you know the drill, keeping an eye on what's being created ensures perfection. So with ReactEvolve™, you'll save time and energy, but still, be necessary for your usual review process! It's teamwork between you and AI for a seamless coding journey ahead.`;
   /*  const aiTools = [
    {
      name: 'BuilderAI',
      description: `Builder is an AI-powered tool designed to assist React developers in creating robust and efficient code. By understanding the developer's intent and the desired output, Builder can suggest optimized code snippets, components, and structures, significantly reducing the time spent on manual coding.`,
      benefits: {
        reactLearners: 'Helps learners understand best practices and improve their coding skills by generating optimized code suggestions.',
        developmentTeams: 'Improves team productivity by automating code generation tasks and reducing the time spent on manual coding.',
        webAgencies: 'Enables web agencies to deliver high-quality, optimized React applications in less time, increasing their competitiveness in the market.'
      }
    },
    {
      name: 'AdjusterAI',
      description: `Adjuster is a smart AI tool that helps developers fine-tune their code by analyzing the overall structure, identifying potential bottlenecks, and suggesting performance improvements. With Adjuster, developers can ensure that their applications run smoothly and efficiently, providing an enhanced user experience.`,
      benefits: {
        reactLearners: 'Teaches learners how to identify and resolve performance bottlenecks, leading to a deeper understanding of React optimization techniques.',
        developmentTeams: 'Increases team efficiency by quickly identifying and addressing performance issues in the codebase, leading to smoother-running applications.',
        webAgencies: 'Helps web agencies deliver high-performance React applications that meet client expectations, leading to increased customer satisfaction and repeat business.'
      }
    },
    {
      name: 'ReproduceAI',
      description: `Cloner is an AI-driven tool that enables React developers to replicate or adapt existing code components, layouts, or designs in a matter of seconds. By leveraging machine learning algorithms, Cloner intelligently identifies the most relevant code elements and adapts them to suit the developer's requirements, significantly reducing development time and effort.`,
      benefits: {
        reactLearners: 'Fosters a deeper understanding of component reuse and adaptation, helping learners become more efficient React developers.',
        developmentTeams: 'Boosts team productivity by streamlining the process of reusing and adapting code components, layouts, and designs.',
        webAgencies: 'Empowers web agencies to reuse existing code assets, reducing development time and costs while maintaining high-quality outputs for their clients.'
      }
    },
  ] */
/*   const aiTools = [
    {
      name: 'BuildAI',
      description: `ReactEvolve™ Builder is an AI-powered tool designed to inspire and assist React developers in creating robust and efficient code. By understanding the developer's intent and the desired output, ReactEvolve™ Builder can suggest optimized components and variable structures, significantly reducing the time spent on manual coding.`,
      benefits: {
        reactLearners: 'Inspires learners to understand best practices and improve their coding skills by generating AI-generated React components, saving time and increasing efficiency.',
        developmentTeams: 'Enhances team productivity by automating code generation tasks, reducing the time spent on manual coding and allowing for a faster development process.',
        webAgencies: 'Enables web agencies to deliver high-quality, optimized React applications in less time, increasing their competitiveness in the market and meeting client needs more effectively.'
      }
    },
    {
      "name": "AdjustAI",
      "description": "ReactEvolve™ AdjustAI is a smart AI tool that helps developers fine-tune their code by analyzing the existing component as an input and returning an adjusted version of the component with performance improvements or other adjustments. With ReactEvolve™ AdjustAI, developers can provide great flexibility to clients and adjust existing components without manual coding. Users can upload their existing component code and request AI to make code changes based on their specific requirements. The result will be an optimized React component.",
      "benefits": {
        "reactLearners": "Inspires learners to identify and resolve performance bottlenecks, leading to a deeper understanding of React optimization techniques and faster learning progress.",
        "developmentTeams": "Increases team efficiency by quickly prototyping react component based on custom command.",
        "webAgencies": "Helps web agencies to harmonise on components, enabling increased speed in prototyping and solving the same problems with the same solutions."
      }
    },
    {
      name: 'InspireAI',
      description: `ReactEvolve™ InspireAI is an AI-driven tool that enables React developers to replicate React component based on existing html and css input. By leveraging machine learning algorithms, ReactEvolve™ InspireAI intelligently identifies the most relevant code elements, variables and structures and adapts to a new react component, significantly reducing development time and effort.`,
      benefits: {
        reactLearners: 'Inspires a deeper understanding of component reuse and adaptation, helping learners become more efficient React developers and saving time on learning.',
        developmentTeams: 'Boosts team productivity by streamlining the process of reusing and adapting code components, layouts, and designs, allowing for faster project completion.',
        webAgencies: 'Empowers web agencies to reuse existing code assets, reducing development time and costs while maintaining high-quality outputs for their clients and increasing overall efficiency.'
      }
    },
  ]  */

  // Updated with specific benefit arguments for the groups listed above

const aiTools = [
  {
    name: 'BuildAI',
    description: `ReactEvolve™ Builder is an AI-powered tool designed to inspire and assist React developers in creating robust and efficient code. By understanding the developer's intent and the desired output, ReactEvolve™ Builder can suggest optimized components and variable structures, significantly reducing the time spent on manual coding.`,
    benefits: {
      BeginnerReactStudents: 'Eases the learning curve for beginner React students by allowing to witness best-practice automated code generation. Generate and observe the code to learn how to write it yourself.',
      JuniorReactDevelopers: 'Allows junior React developers to quickly improve their code by utilizing AI expertise, accelerating their growth within development teams.',
      SeniorReactDevelopers: 'Enables senior React developers to maximize their productivity, leveraging AI-generated suggestions to optimize components and variable structures.',
      HobbyistProgrammers: 'Boosts hobbyist programmers\' efficiency by offering AI-generated suggestions, giving them more time to focus on their creative projects.',
      /* TechSavvyStudents: 'Inspires tech-savvy students to learn and implement AI-driven code enhancements, leading to better understanding and overall development skills.',
      CodingEnthusiasts: 'Assists coding enthusiasts by suggesting optimized components and variable structures, improving code quality and efficiency.',
      AdvancedReactStudents: 'Challenges advanced React students to improve their code by providing AI-driven optimization suggestions, fostering continuous skill growth.',
      CodingBootcampPreparation: 'Prepares individuals for coding bootcamps by introducing them to AI-assisted coding, enhancing their chances of success in the bootcamp environment.',
      CollegeUniversityStudents: 'Offers college/university students a cutting-edge tool to help learn and apply AI-driven code optimization techniques, setting them apart in their software development career.',
      ReactCodingEducators: 'Provides React coding educators with an additional resource for teaching AI-assisted coding, empowering students to build better, more efficient projects.' */
    }
  },
  {
    "name": "AdjustAI",
    "description": `ReactEvolve™ AdjustAI is a smart AI tool that helps developers fine-tune their code by analyzing the existing component as an input and returning an adjusted version of the component with performance improvements or other adjustments. With ReactEvolve™ AdjustAI, developers can provide great flexibility to clients and adjust existing components without manual coding. Users can upload their existing component code and request AI to make code changes based on their specific requirements. The result will be an optimized React component.`,
    "benefits": {
      "BeginnerReactStudents": "Gives beginner React students insights into how existing components can be adjusted and optimized, leading to a better understanding of React best practices.",
      "JuniorReactDevelopers": "Empowers junior React developers to make impactful optimizations in their code using AI-driven adjustments, reducing the time spent on manual code fine-tuning.",
      "SeniorReactDevelopers": "Streamlines the development process for senior React developers by automating code adjustments, enabling them to focus on project strategy and oversight.",
      "HobbyistProgrammers": "Saves time for hobbyist programmers by quickly optimizing existing components, enabling them to focus on other creative aspects of their projects.",
     /*  "CodingEnthusiasts": "Enhances coding enthusiasts' development experience by automating code adjustment tasks, allowing them to focus on their passion projects.",
      "TechSavvyStudents": "Inspires tech-savvy students to optimize and adjust their React components, improving their projects and boosting their academic performance.",
      "AdvancedReactStudents": "Encourages advanced React students to explore component optimization techniques through AI-driven improvements, further expanding their coding skills.",
      "CodingBootcampPreparation": "Provides valuable experience in analyzing and optimizing React components, setting the stage for success in coding bootcamp environments.",
      "CollegeUniversityStudents": "Offers college/university students practical, AI-driven techniques for optimizing React components, teaching valuable skills for their software development careers.",
      "ReactCodingEducators": "Gives React coding educators a powerful, AI-driven tool to demonstrate component optimization techniques, leading to more efficient and effective learning experiences." */
    }
  },
  {
    name: 'InspireAI',
    description: `ReactEvolve™ InspireAI is an AI-driven tool that enables React developers to replicate React component based on existing html and css input. By leveraging machine learning algorithms, ReactEvolve™ InspireAI intelligently identifies the most relevant code elements, variables and structures and adapts to a new react component, significantly reducing development time and effort.`,
    benefits: {
      BeginnerReactStudents: 'Eases the transition to React by simplifying the process of converting HTML & CSS code, helping beginner students better understand how to create reactive components.',
      JuniorReactDevelopers: 'Simplifies the transformation of HTML & CSS into React components for junior developers, enabling them to contribute more effectively and grow within their roles.',
      SeniorReactDevelopers: 'Allows senior React developers to focus more on application architecture and foundation by automating the conversion of static code into React components.',
      HobbyistProgrammers: 'Saves time for hobbyist programmers by automating component replication, enabling the focus on other areas of creativity within their projects.',
      /* CodingEnthusiasts: 'Inspires coding enthusiasts with a seamless way to convert HTML & CSS into React components, allowing them to quickly prototype ideas and streamline their projects.',
      AdvancedReactStudents: 'Saves time for advanced React students by transforming HTML & CSS into React components on-demand, enabling them to implement complex designs more efficiently.',
      TechSavvyStudents:`Enhances tech-savvy students' understanding of component reuse, empowering them to create versatile React applications and improve their web development skills.`,
      CodingBootcampPreparation: 'Offers future coding bootcamp participants an accessible way to practice converting static designs into interactive applications, leading to better bootcamp outcomes.',
      CollegeUniversityStudents: 'Introduces college/university students to machine learning-driven development tools, giving them an edge in their software development career.',
      ReactCodingEducators: 'Provides React coding educators an efficient method to demonstrate how to convert static layouts into interactive applications, leading to a more engaging and effective learning experience.' */
    }
  },
]
  /* const conclusion = `StartupAI is committed to bringing the power of artificial intelligence to React developers, helping them unlock their full potential and redefine what's possible in the world of software development. With our AI tools - Builder, Adjuster, and Cloner, we aim to make coding more efficient, accurate, and enjoyable, paving the way for a new era of innovation and creativity.`; */
    const conclusion = `ReactEvolve™ is dedicated to harnessing the power of artificial intelligence for React developers and aspiring coders, enabling them to save time and enhance efficiency through the Ai-based Code Generation and Community Library. With our AI tools - Build, Adjust, and Inspire, we strive to make coding more efficient, accurate, and enjoyable, inspiring a new era of innovation and creativity in the web development world.`;
   const [isFlipped, setIsFlipped] = useState(false);
   const [isFlipped1, setIsFlipped1] = useState(false);
   const [isFlipped2, setIsFlipped2] = useState(false);
   const [tabs, setTabs] = useState(aiTools)
  
    const handleMouseEnter = () => {
      setIsFlipped(true);
    };
  
    const handleMouseLeave = () => {
      setIsFlipped(false);
    };
    
    const handleMouseEnterSep = (index) => {
      if(index === 0){
        setIsFlipped(true);
      }
      if(index === 1){
        setIsFlipped1(true);
      }
      if(index === 2){
        setIsFlipped2(true);
      }
    }
    
    const handleMouseLeaveSep = (index) => {
      if(index === 0){
        setIsFlipped(false);
      }
      if(index === 1){
        setIsFlipped1(false);
      }
      if(index === 2){
        setIsFlipped2(false);
      }
      setTabs(tabs.slice())
    }
  
    return (
      <Container>
        <Box my={4}>
          {/* <Typography variant="h2" component="h1" gutterBottom>
            {title}
          </Typography> */}
          <Typography variant="h4" style={{textAlign:"center"}} component="h2" gutterBottom>
            {subtitle}
          </Typography>
          <StyledCardMedia>
           {/*  <Typography variant="body1" component="p" gutterBottom>
              {description}
            </Typography> */}
            <Typography variant="body1" component="p" gutterBottom>
              {missionStatement}
            </Typography>
          </StyledCardMedia>
       {/*    <br/><br/>
          <Typography style={{textAlign:"center"}} variant="h4" component="h2" gutterBottom>
            {subtitle1}
          </Typography> */}
          
           <Grid container spacing={4}>
            {tabs.map((tool, index) => {
              console.log('tool', tool)
              let array = Object.entries(tool.benefits)
              console.log('array', array)
            return(
              <Grid item xs={12} sm={4} key={index} container justifyContent="center" alignItems="center" direction="column">
                
               {index === 0 &&  <>
                    <FlipCardContainer onMouseEnter={() => handleMouseEnterSep(index)} onMouseLeave={() => handleMouseLeaveSep(index)}>
                        <FlipCardInner style={isFlipped ? { transform: "rotateY(180deg)" } : {}}>
                        <FlipCardFront>
                            <CardContent>
                            <Typography variant="h5"> {tool.name}</Typography>
                            <Typography variant="body1"> {tool.description}</Typography>
                            </CardContent>
                        </FlipCardFront>
                        <FlipCardBack>
                          {array.map((benefit, index) => (
                            <CardContent key={index}>
                              <Typography variant="h5">{benefit[0].replace(/([a-z])([A-Z])/g, '$1 $2')}</Typography>
                              <Typography variant="body1">{benefit[1]}</Typography>
                            </CardContent>
                          ))}
                           {/*  <CardContent>
                            <Typography variant="h5">React Beginners</Typography>
                            <Typography variant="body1">{tool.benefits.reactLearners}</Typography>
                            </CardContent>
                            <CardContent>
                            <Typography variant="h5">Development Teams</Typography>
                            <Typography variant="body1">{tool.benefits.developmentTeams}</Typography>
                            </CardContent>
                            <CardContent>
                            <Typography variant="h5">Web Agencies</Typography>
                            <Typography variant="body1">{tool.benefits.webAgencies}</Typography>
                            </CardContent> */}
                        </FlipCardBack>
                        </FlipCardInner>
                    </FlipCardContainer>
                </>}
                 {index === 1 &&  <>
                    <FlipCardContainer onMouseEnter={() => handleMouseEnterSep(index)} onMouseLeave={() => handleMouseLeaveSep(index)}>
        <FlipCardInner style={isFlipped1 ? { transform: "rotateY(180deg)" } : {}}>
          <FlipCardFront>
            <CardContent>
              <Typography variant="h5"> {tool.name}</Typography>
              <Typography variant="body1"> {tool.description}</Typography>
            </CardContent>
          </FlipCardFront>
          <FlipCardBack>
          {array.map((benefit, index) => (
                            <CardContent key={index}>
                              <Typography variant="h5">{benefit[0].replace(/([a-z])([A-Z])/g, '$1 $2')}</Typography>
                              <Typography variant="body1">{benefit[1]}</Typography>
                            </CardContent>
                          ))}
          </FlipCardBack>
        </FlipCardInner>
      </FlipCardContainer>
                </>}
                 {index === 2 &&  <>
                    <FlipCardContainer onMouseEnter={() => handleMouseEnterSep(index)} onMouseLeave={() => handleMouseLeaveSep(index)}>
        <FlipCardInner style={isFlipped2 ? { transform: "rotateY(180deg)" } : {}}>
          <FlipCardFront>
            <CardContent>
              <Typography variant="h5"> {tool.name}</Typography>
              <Typography variant="body1"> {tool.description}</Typography>
            </CardContent>
          </FlipCardFront>
          <FlipCardBack>
          {array.map((benefit, index) => (
                            <CardContent key={index}>
                              <Typography variant="h5">{benefit[0].replace(/([a-z])([A-Z])/g, '$1 $2')}</Typography>
                              <Typography variant="body1">{benefit[1]}</Typography>
                            </CardContent>
                          ))}
          </FlipCardBack>
        </FlipCardInner>
      </FlipCardContainer>
                </>}
  
    
      
              </Grid>
            )})}
          </Grid>
          <StyledCardMedia>
            <Typography variant="body1" component="p" gutterBottom>
              {conclusion}
            </Typography>
          </StyledCardMedia>
        </Box>
      </Container>
    );
  };
  
  export default FeaturesReactorAI;