import React, { useReducer } from "react";
import StateContext from "./StateContext";
import { ADD_ITEM, UPDATE_ITEM, REMOVE_ITEM } from "./actionTypes";

const initialState = {
    items: [],

  };

  const reducer = (state, action) => {
    console.log(action, state);
    switch (action.type) {
      case ADD_ITEM:
        return { ...state, items: [...state.items, action.payload] };
      case UPDATE_ITEM:
        return {
          ...state,
          items: state.items.map((item) =>
            item.id === action.payload.id ? action.payload : item
          ),
        };
      case REMOVE_ITEM:
        return {
          ...state,
          items: state.items.filter((item) => item.id !== action.payload),
        };
      default:
        return state;
    }
  };

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
};

export default StateProvider;