import { Button, Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";



const ActionBanner = (props) => {

    return(
        <div style={{marginTop: '1em'}}>
            <Card style={{backgroundColor:"blueviolet"}}> 
                <CardHeader style={{color:"white"}} title="Early birds, Earn credits!" />
                <CardContent style={{justifyContent: "center", alignContent:"center"}}>
                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={6}>
                    <Typography variant="h7" align="center" gutterBottom style={{marginBottom:'1em', textAlign:"center", color:"white"}}>
                        Get +5 credits for each friend you refer! <br/>
                        After you registered, simply copy and paste the link from profile & share.
                    </Typography>
                    <br/><br/>
                    <Typography variant="h7" align="center" gutterBottom style={{marginBottom:'1em', textAlign:"center", color:"lightgray"}}>
                        Limited seats for Referal Program available.
                    </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
        <Button variant="contained" color="secondary" onClick={props.action} style={{marginBottom:'1em', textAlign:"center", color:"white"}}>
            Get Referal now!
        </Button>
        </Grid>
        </Grid>
                </CardContent>
                
            </Card>
        </div>
    )
}

export default ActionBanner;