import React, { useState } from "react";
   import Box from "@mui/material/Box";
   import Typography from "@mui/material/Typography";
   import Stepper from "@mui/material/Stepper";
   import Step from "@mui/material/Step";
   import StepLabel from "@mui/material/StepLabel";
import { Button, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import SnakeGame from "./demoComponents/SnakeGame";
import DemoCodeSelector from "../componentsReactor/DemoCodeSelector";
import FlipCard from "../componentsReactor/FlipCard";

function HowItWorksStepper(props) {
    const [activeStep, setActiveStep] = useState(0);

    const steps = [
      "Prepare",
      "Component Generation",
      "Adapt",
      "Get Started",
    ];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      };
    
      const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };

      const handleLogin = () => {
        props.openLoginModal();
      };

    const stepDetails = [
      "Start with different Use Cases",
      "Time to generate!",
      "Analyse and Evolve",
      "Get Started",
      "Community Library",
      "Generate your 1st component"
    ];

    const lists = [
        {listItems: [
            "CreationAI - Create a new component from scratch according to your commands",
             `AdjustAI - Select a pre-build components from the library and adjust the component with ReactEvolve Adjustment`,
            "InspireAI - You may also get inspired by HTML and CSS content and provide it to ReactEvolve Inspire.",
            "ImageComponentAI - Lastly, there is an image component generation using DALL·E 2 for quickly generating image components."
        ]}
    ]

    return (
        <div style={{marginTop:'1em', marginBottom: "1em"}}>
        <Typography style={{textAlign:"center"}}variant="h4" component="h2" gutterBottom>
            How it works
          </Typography>
        
      <Box margin={5}>
        
        <Stepper activeStep={activeStep}>
            
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
          
        </Stepper>
        <Card style={{margin:'1em'}}>
            <CardHeader title={stepDetails[activeStep]} />
        
        {activeStep === 0 && <CardContent style={{marginLeft:'1em'}}>
        <Typography variant="h9"  gutterBottom>
            {lists[0].listItems.map((item, index) => {
                return (
                    <><li key={index}>{item}</li><br/></>
                )
            })}
            </Typography>
            </CardContent>
            }
        {activeStep === 1 && <CardContent style={{marginLeft:'1em'}}>
        
        <Grid container spacing={2} alignItems={'center'}>
            <Typography variant="h9"  gutterBottom>
            After you selected your method of choice, it's time to generate. The creation process involves several computationally-intensive steps.
            </Typography>
        <Grid key={1} item xs={12} md={4}>
        1. Tokenization
            </Grid>
            <Grid key={2} item xs={12} md={8}>
            The input command is broken down into smaller units called tokens, which requires significant processing, especially for large commands.
                </Grid>
        <Grid key={3} item xs={12} md={4}>
        2. Model Inference
            </Grid>
            <Grid item xs={12} md={8}>
            The API accesses a pre-trained code model, typically with billions of parameters. Processing these parameters requires substantial computational power.
                </Grid>
        <Grid key={4} item xs={12} md={4}>
        3. Contextual Understanding
            </Grid>
            <Grid item xs={12} md={8}>
            The model gathers contextual information from the input commands, inferring relevant past or future events, which adds to the complexity of the task.
                </Grid>
        <Grid item xs={12} md={4}>
        4. Component Generation
            </Grid>
            <Grid item xs={12} md={8}>
            The model generates an appropriate response for each token in the input sequence by weighing potential codes. This involves sampling and may involve multiple iterations.
                </Grid>
        <Grid item xs={12} md={4}>
        5. Decoding and Post-processing
            </Grid>
            <Grid item xs={12} md={8}>
            The generated tokens are decoded back into a human-readable code, and additional processing, like syntax checks, are performed.
                </Grid>
                </Grid>
                <br/>            
            <Typography variant="h9"  gutterBottom>
    
These steps, involving vast computations, lead to an optimized generation time of 1...2 minutes per component. However, jobs can run in parallel.

            </Typography>
            </CardContent>
            }
        {activeStep === 2 && <CardContent style={{marginLeft:'1em'}}>
        
       
            <Typography variant="h9"  gutterBottom>
            After the component has been generated, it can be analyzed in Editor and could be shared with the community in 'My Components' section.
            Please only share working components with the community.
            The component can be adjusted or extended by new commands with ReactEvolve Adjustment and the whole process start from the beginning, 
            as an iteration or an <b>evolution</b> of the previous component.
            </Typography>
       
            </CardContent>
            }
             {activeStep === 3 && <><CardContent style={{marginLeft:'1em'}}>
            
             {!props.noButton && 
             <> <Typography variant="h9" color={'red'}  gutterBottom>
            Before the service can be used, please go to profile page and load up credits.
        </Typography>
        
            <br/><br/>
            </>
        }
        <Typography variant="h9"  gutterBottom>
        
        With the menu in the top left corner, you will find the following sites available for you:
        
        <ul>
            <li>Library - This is the place where to find all community components.</li>
            <li>My Components - Here you can manage your generated components. These components can be used in the Editor or Stacker.</li>
            <li>Editor - This is where the magic takes place. Depending on you subscription plan you will find different options to choose from.</li>
            <li>Stacker - Use the Stacker to stack different components on top of each other and return in one component.</li>
        </ul>
        </Typography>
   
        </CardContent>
        <Card>
          <CardHeader title={'Starter Commands'} />
          <CardContent>
            
            <Typography variant="h9" style={{color:"gray"}}  >
              Select the first command & click the copy button. Navigate to "Editor" and paste the command under "describe your component", enter a ComponentName (with Capitalised starting letter) and press send.
              </Typography>
          </CardContent>
        <CardContent>
               
          <DemoCodeSelector />
          
        </CardContent>
      </Card>
      </>
        }

        </Card>
        
        {activeStep === steps.length-1 ? 
          <>
           {!props.noButton ? <><Button variant="contained" color="primary" onClick={handleBack}>Back</Button></>:
           <>
            <Button variant="contained" color="primary" onClick={handleBack}>Back</Button>
            <Button style={{marginLeft:"1em"}} variant="contained" color="primary" onClick={handleLogin}>Try now</Button>
            </>
           }
           </>
            :
            <>
           {activeStep > 0 && <Button variant="contained" color="primary" onClick={handleBack}>Back</Button>}
            <Button  style={{marginLeft:"1em"}} variant="contained" color="primary" onClick={handleNext}>Next</Button>    
            </>
        }
        
      </Box>
      
      </div>
    );
  }

  export default HowItWorksStepper;