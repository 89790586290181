// RequireAuth.js
import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useContext } from 'react';


export function RequireAuth({ children, ...props }) {
  
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  if (route !== 'authenticated') {
    console.log('not authenticated');
    /* console.log({ props }); */
    props.updateAuth(false)
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  console.log({ route });
  /* loadAvailableGotchaaas() */
  return children;
}