

  import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardActions,
  CardActionArea,
  CardMedia,
  Typography,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Grid,
} from '@mui/material'

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import  ArrowForwardIcon  from '@mui/icons-material/ArrowForward';
import styled from '@emotion/styled';
import { AlignVerticalCenter } from '@mui/icons-material';

const personasRef = [
    {
      name: "John Smith",
      gender: "Male",
      age: 45,
      jobTitle: "Chemical Engineer",
      cluster: "Chemical Industry Professionals",
      responsibilities: "Design and develop chemical processes, optimize production efficiency, and ensure safety standards.",
      values: "Efficiency, safety, innovation.",
      preferences: "Clear communication, teamwork, cutting-edge technology.",
      painPoints: "Lack of resources, outdated equipment, unclear expectations.",
      profession: ['Chemical engineer', 'Process engineer', 'Chemical plant manager']
    },
    {
      name: "Jane Doe",
      gender: "Female",
      age: 37,
      jobTitle: "Food Scientist",
      cluster: "Food and Beverage Industry Professionals",
      responsibilities: "Research and develop new food products, improve existing products, ensure food safety and quality.",
      values: "Innovation, quality, sustainability.",
      preferences: "Collaboration, data-driven decisions, continuous learning.",
      painPoints: "Regulatory barriers, limited funding, lack of understanding from non-scientific stakeholders.",
      profession: ['Food scientist', 'Food technologist', 'Production manager']
    },
    {
      name: "Carlos Martinez",
      gender: "Male",
      age: 52,
      jobTitle: "Water Treatment Operator",
      cluster: "Water and Wastewater Industry Professionals",
      responsibilities: "Operate and maintain water treatment facilities, monitor water quality, and comply with regulations.",
      values: "Safety, environmental responsibility, community health.",
      preferences: "Stable work environment, hands-on work, ongoing training.",
      painPoints: "Outdated infrastructure, budget constraints, lack of public awareness.",
      profession: ['Water treatment operator', 'Wastewater treatment operator', 'Water resource engineer']
    },
    {
      name: "Aisha Patel",
      gender: "Female",
      age: 29,
      jobTitle: "Petroleum Engineer",
      cluster: "Oil and Gas Industry Professionals",
      responsibilities: "Design and implement drilling methods, optimize production, and evaluate reservoirs.",
      values: "Efficiency, safety, environmental stewardship.",
      preferences: "Challenging projects, collaboration, continuous improvement.",
      painPoints: "Market volatility, public perception, regulatory challenges.",
      profession: ['Petroleum engineer', 'Geologist', 'Drilling engineer']
    },
    {
      name: "Michael Brown",
      gender: "Male",
      age: 40,
      jobTitle: "Control System Engineer",
      cluster: "Multi-Industry Skid Builder Professionals",
      responsibilities: "Design, develop, and maintain control systems for various industries.",
      values: "Reliability, innovation, collaboration.",
      preferences: "Cross-industry projects, cutting-edge technology, professional development.",
      painPoints: "Tight deadlines, limited resources, insufficient client communication.",
      profession: ['Mechanical engineer', 'Electrical engineer', 'Control system engineer']
    },
    {
      name: "Emily Chen",
      gender: "Female",
      age: 33,
      jobTitle: "R&D Scientist",
      cluster: "Research and Development Professionals",
      responsibilities: "Conduct research, develop new products and technologies, and contribute to intellectual property.",
      values: "Creativity, innovation, collaboration.",
      preferences: "Supportive work environment, access to resources, recognition for achievements.",
      painPoints: "Lack of funding, bureaucratic obstacles, limited commercialization opportunities.",
      profession: ['R&D scientist', 'R&D engineer', 'Project leader']
    },
    {
      name: "David Johnson",
      gender: "Male",
      age: 57,
      jobTitle: "Sales Engineer",
      cluster: "Sales and Marketing Professionals",
      responsibilities: "Sell complex technical products, provide customer support, and identify new business opportunities.",
      values: "Customer satisfaction, integrity, relationship-building.",
      preferences: "Technical expertise, clear communication, ongoing training.",
      painPoints: "High pressure, long sales cycles, lack of support from internal teams.",
      profession: ['Sales engineer', 'Account manager', 'Marketing specialist']
    },
    {
      name: "Olivia Kim",
      gender: "Female",
      age: 41,
      jobTitle: "Production Manager",
      cluster: "Food and Beverage Industry Professionals",
      responsibilities: "Oversee food production operations, manage personnel, and ensure quality and safety standards.",
      values: "Efficiency, quality, teamwork.",
      preferences: "Clear communication, data-driven decisions, employee development.",
      painPoints: "Resource constraints, high turnover, regulatory challenges.",
      profession: ['Production manager', 'Quality assurance manager', 'Packaging engineer']
    },
    {
      name: "Daniel Garcia",
      gender: "Male",
      age: 36,
      jobTitle: "Environmental Technician",
      cluster: "Water and Wastewater Industry Professionals",
      responsibilities: "Collect water samples, monitor environmental impact, and assist with compliance efforts.",
      values: "Environmental responsibility, accuracy, continuous learning.",
      preferences: "Hands-on work, opportunities for growth, supportive team.",
      painPoints: "Limited resources, changing regulations, lack of public awareness.",
      profession: ['Environmental technician', 'Maintenance technician', 'Utility manager']
    },
    {
      name: "Sophia Nguyen",
      gender: "Female",
      age: 30,
      jobTitle: "Marketing Specialist",
      cluster: "Sales and Marketing Professionals",
      responsibilities: "Develop and execute marketing strategies, analyze market trends, and support product launches.",
      values: "Creativity, data-driven decisions, collaboration.",
      preferences: "Clear goals, cross-functional teamwork, innovative thinking.",
      painPoints: "Limited budget, lack of internal support, fast-paced environment.",
      profession: ['Marketing specialist', 'Marketing manager', 'Product manager']
    },
  ]
const StyledCard = styled(Card)`
  margin: 16px;
  &:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  }
`;

const PersonaSelector = (props) => {
  console.log(props)
  const [personas, setPersonas] = useState(personasRef);
  const [selectedPersonaIndex, setSelectedPersonaIndex] = useState(0);
  const selectedPersona = personas[selectedPersonaIndex];
  props.updateFromPersonaSelector(personas[selectedPersonaIndex])

  const handleSelectionChange = (event) => {
    setSelectedPersonaIndex(event.target.value);
    handlePersonaChange(personas[event.target.value])
  };

  const navigateLeft = () => {
    setSelectedPersonaIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : personas.length - 1
    );
  };

  const navigateRight = () => {
    setSelectedPersonaIndex((prevIndex) =>
      prevIndex < personas.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handlePersonaChange = (persona) => {
    props.updateFromPersonaSelector(persona)
  }

  useEffect(() => {
    if(props.personas.length > 0){
      setPersonas(props.personas)
      setSelectedPersonaIndex(0)
    }
    
  }, [props.personas])

  return (
    <div>
        <Grid container sx={{ justifyContent: 'center',
             verticalAlign: 'middle',
             alignItems: 'center'}} >
        <Grid xs={6}  style={{
             /* border: '1px solid #ccc', */ padding: '1rem',
            
             }}>
      <FormControl>
        <Select
          value={selectedPersonaIndex}
          onChange={handleSelectionChange}
        >
          {personas.map((persona, index) => (
            <MenuItem key={persona.name} value={index}>
              {persona.name} - {persona.jobTitle}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
        </Grid>
        <Grid xs={12} style={{ border: '1px solid #ccc', padding: '1rem' }}>
      <StyledCard>
        <CardActionArea>
          <CardMedia
            component="img"
            alt={`${selectedPersona.name}`}
            height="140"
            image="https://via.placeholder.com/150"
            title={`${selectedPersona.name}`}
          />
          <CardContent>
            {Object.entries(selectedPersona).map(([key, value]) => (
              <Typography key={key} variant="body2" color="textSecondary">
                <b>{key}:</b> {value}
              </Typography>
            ))}
          </CardContent>
        </CardActionArea>
        <CardActions>
          <IconButton onClick={navigateLeft}>
            <ArrowBackIcon />
          </IconButton>
          <IconButton onClick={navigateRight}>
            <ArrowForwardIcon />
          </IconButton>
        </CardActions>
      </StyledCard>
      </Grid>
      </Grid>
      




    </div>
  );
};

export default PersonaSelector;