import styled from "@emotion/styled";
import { Button, Card, CardActions, CardContent, CardHeader, Container, Grid, TableCell, Typography } from "@mui/material";
import FAQAccordion from "./faq";

import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';

import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const StyledCard = styled(Card)({
    marginTop: '1em',
    backgroundColor: "rgb(255, 255, 255)",
  color: "rgb(17, 25, 39)",
  boxShadow: "rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px",
  borderRadius: "20px",
  overflow: "hidden",
  backgroundImage: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: "1.5",
  fontFamily: "'Inter',-apple-system,BlinkMacSystemFont,'Segoe UI',Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji'",
  "&:hover": {
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  });
  
  const StyledCardHeader = styled(CardHeader)({
    textAlign: 'center',
    color: 'blue',
    backgroundColor: 'lightgray'
    
  });
  
  const StyledCardContent = styled(CardContent)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight:'2em'
  });
  
  const StyledCardActions = styled(CardActions)({
    display: 'flex',
    justifyContent: 'center',
  });
  
  
  const StyledTypography = styled(Typography)({
    color: 'blue',
  });
  
  const Pricing1 = (props) => {
    const plans = [
      {
        title: 'Free',
        price: '$0',
        period: '/mo',
        features: [
          {title:'CreationAI', has: true},
          {title:'AdjustAI', has: false},
          {title:'InspireAI', has: false},
          {title:'ImageComponentAI', has: false},
          {title:'Export Components', has: false},
          {title:'Community Library Access', has: true},
          {title:'2 own components slots', has: true},
          {title:'Pay-per-use credit system (0.33$/credit)', has: true},
        ],
        buttonText: 'Sign up for free',
        alwaysFree: true,
      },
      {
        title: 'Solopreneur',
        price: '$9',
        period: '/mo',
        features: [
          {title:'CreationAI', has: true},
          {title:'AdjustAI', has: true},
          {title:'InspireAI', has: false},
          {title:'ImageComponentAI', has: false},
          {title:'Export Components', has: true},
          {title:'Community Library Access', has: true},
          {title:'10 own components slots', has: true},
          {title:'10 credits monthly', has: true},
          {title:'10 Extra Credits one-time*', has: true},
          {title:'Add more credits flexibly (0.25$/credit)', has: true},
        ],
        buttonText: 'Sign up for free',
        solo: true,
      },
      {
        title: 'Lift',
        price: '$29',
        period: '/mo',
        features: [
          {title:'CreationAI', has: true},
          {title:'AdjustAI', has: true},
          {title:'InspireAI', has: true},
          {title:'ImageComponentAI', has: true},
          {title:'Export Components', has: true},
          {title:'Community Library Access', has: true},
          {title:'100 own components slots', has: true},
          {title:'50 credits monthly', has: true},
          {title:'50 Extra Credits one-time*', has: true},
          {title:'Add more credits flexibly (0.2$/credit)', has: true},
        ],
        buttonText: 'Get started',
        popular: true,
      },
      {
        title: 'Fly',
        price: '$79',
        period: '/mo',
        features: [
          {title:'CreationAI', has: true},
          {title:'AdjustAI', has: true},
          {title:'InspireAI', has: true},
          {title:'ImageComponentAI', has: true},
          {title:'Export Components', has: true},
          {title:'Community Library Access', has: true},
          {title:'1000 own components slots', has: true},
          {title:'100 credits monthly', has: true},
          {title:'75 Extra Credits one-time*', has: true},
          {title:'Add more credits flexibly (0.15$/credit)', has: true},
        ],
        buttonText: 'Contact us',
        fly: true,
      },
    ];
  
    return (
      <>
      <Container style={{marginTop:'40px'}}>
      {!props.tableOnly && <Typography variant="h2" align="center" gutterBottom>
          Pricing
        </Typography>}
        <Typography variant="h5" align="center" gutterBottom>
          Choose the plan that works for you.
          {/* Monthly prices. Cancel anytime. */}
        </Typography>
        <Grid container spacing={5}>
          {plans.map((plan, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <StyledCard>
              {plan.popular && <StyledCardHeader
                  title={plan.title}
                  style={{color: 'blue'}}
                  subheader={plan.popular ? 'Most popular' : null}
                />}
               {plan.alwaysFree && <StyledCardHeader
                  title={plan.title}
                  style={{color: 'black'}}
                  subheader={plan.alwaysFree ? 'Always free' : " "}
                />}
                {plan.solo && <StyledCardHeader
                  title={plan.title}
                  style={{color: 'black'}}
                  subheader={plan.solo ? 'Best for students' : " "}
                />}
                 {plan.fly && <StyledCardHeader
                  title={plan.title}
                  style={{color: 'black'}}
                  subheader={plan.fly ? 'Best for growth hackers' : " "}
                />}
                <StyledCardContent>
                  <Typography variant="h3" style={{color: 'blue'}} >{plan.price}</Typography>
                  <StyledTypography variant="h6" style={{color: 'gray'}}>{plan.period}</StyledTypography>
                  
                  <ul>
                  <Grid container spacing={2} alignItems="center" alignContent={"center"} justifyContent={"center"}  >
                    {plan.features.map((feature, index) => (
                      <>
                      
                      <Grid item xs={12} sm={2.5} key={index}>
                    {feature.has ? <CheckCircleIcon style={{color:"green"}} key={index}/>
                    :
                    <CancelIcon style={{color:"red"}} key={index}/>}
                    </Grid>
                    <Grid item xs={12} sm={9.5}  >
                      <Typography style={{marginTop:"-0.5em"}} variant="subtitle1" align="left">
                        {feature.title}
                      </Typography>
                      </Grid>
                      
                      </>
                    ))}
                  </Grid>
                  </ul>
                 
                </StyledCardContent>
               {/*  <StyledCardActions>
                  <Button
                    variant={plan.popular ? 'contained' : 'outlined'}
                    color="primary"
                    fullWidth
                  >
                    {plan.buttonText}
                  </Button>
                </StyledCardActions> */}
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Typography variant="h5" align="center" gutterBottom style={{marginTop:"1em"}}>
          {/* Choose the plan that works for you. */}
          *If plan is selected at registration. Not available for upgrades.
        </Typography>
      <Typography variant="h5" align="center" gutterBottom style={{marginTop:"1em"}}>
          {/* Choose the plan that works for you. */}
          Monthly prices. Cancel anytime.
        </Typography>
      <Container style={{marginTop:'20px', justifyItems:'center', width:"400px"}}>
        
      <img
            style={{ display: 'flex', width: "100%", /* paddingBottom:"-30%"  */}}
            src={'payment.png'}
            alt="online payment options"
          />
        
      </Container>
    
      </>
    );
  };
  
  export default Pricing1 /* This component used 2709 tokens in total. Creation costs on GPT4: 0.09983999999999998 $ and on GPT3.5Turbo 0.005418 $ */