import React, { useEffect, useState } from "react";
import {
Container,
Typography,
Box,
Grid,
Paper,
TextField,
Autocomplete,
ListItem,
List,
Accordion,
Button,
ListItemIcon,
ListItemText,
Card,
CardContent,
} from "@mui/material"
import Checkbox from '@mui/material/Checkbox';

import  styled  from "@emotion/styled";
import { ListComponents } from "../../services/graphQlService";

const StyledCard = styled(Card)({
    marginTop: "1em",
  });
  

  
  const StyledListItem = styled(ListItem)({
    paddingLeft: '0',
  });
  
  const StyledAccordion = styled(Accordion)({
    marginTop: '1em',
  });
  
  const ComponentTreeBuilder = (props) => {
    const [selectedComponents, setSelectedComponents] = useState({});
    const [componentTree, setComponentTree] = useState([]);
    const [components, setComponents] = useState([]);
    const [currentCardsAmount, setCurrentCardsAmount] = useState(3);
    const [componentName, setComponentName] = useState('');
    const [stackedComponentsNameState, setstackedComponentsNameState] = useState('');

    const loadComponents = async () => {
      const components = await ListComponents()
      let componentsList = components.data.listComponents.items
        componentsList.forEach(element => {
          element.label = element.componentName+'('+element.id+')'
          element.value = 'Value'+element.id
        });
      console.log(componentsList);
      setComponents(componentsList)
    }

    useEffect(() => {
      // Initialize components
      const components = [
        { label: 'Component A', value: 'componentA' },
        { label: 'Component B', value: 'componentB' },
        { label: 'Component C', value: 'componentC' },
      ];
      setComponents(components);
      loadComponents()
    }, []);
  
    
  
    const handleComponentSelect = (event, component, cardIndex) => {
      const newSelectedComponents = { ...selectedComponents };
      newSelectedComponents[cardIndex] = component;
      setSelectedComponents(newSelectedComponents);
  
      const newComponentTree = [...componentTree];
      newComponentTree[cardIndex] = { component };
      setComponentTree(newComponentTree);
    };
  
    const renderDropdown = (cardIndex) => {
      const selectedComponent = selectedComponents[cardIndex];
      return (
        <Autocomplete
          id={`${cardIndex}-component-select`}
          options={components}
          getOptionLabel={(option) => option.label}
          value={selectedComponent || null}
          onChange={(event, component) =>
            handleComponentSelect(event, component, cardIndex)
          }
          renderInput={(params) => (
            <TextField {...params} label="Select component" variant="outlined" />
          )}
        />
      );
    };
  
    const renderCard = (index) => {
      return (
        <StyledCard key={index}>
          <CardContent>
            <Typography variant="h5" component="div">
              Component {index + 1}
            </Typography>
            <Box sx={{ mt: 1 }}>{renderDropdown(index)}</Box>
            <Box sx={{ mt: 1 }}>
              <Button variant="contained" onClick={() => handleRemove(index)}>Remove</Button>
            </Box>
          </CardContent>
        </StyledCard>
      );
    };
  
    const renderCards = () => {
      const cards = [];
      for (let i = 0; i < currentCardsAmount; i++) {
        cards.push(renderCard(i));
      }
      return cards;
    };

    const handleCompile = () => {
      console.log(componentTree);
      let stackedComponents = ''
      let stackedComponentsName = ''
      componentTree.forEach(element => {
        stackedComponents += element.component.newComponent
        stackedComponentsName += element.component.componentName
      })
      console.log(stackedComponents);
      setstackedComponentsNameState(stackedComponentsName)
      handleCodeChange(stackedComponents)
      setTimeout(() => {
        sendCommand('')
      }, 1500);
    };

    const handleCombine = () => {
      console.log(componentTree);
      let stackedComponents = ''
      let stackedComponentsName = ''
      componentTree.forEach(element => {
        let component = element.component.newComponent
        let posRender = component.indexOf("render")
      if(posRender > -1){
        component = component.substring(0, posRender)
      }
        stackedComponents += component
        stackedComponentsName += element.component.componentName
      })
      console.log(stackedComponents);
      setstackedComponentsNameState(stackedComponentsName)
      handleCodeChange(stackedComponents)
      setTimeout(() => {
        sendCommand(stackedComponents, stackedComponentsName)
      }, 2500);
    };

    const handleRemove = (index) => {
      let components = [...componentTree]
      components.splice(index, 1)
      setComponentTree(components)
      setCurrentCardsAmount(currentCardsAmount - 1)
      
    };

    const handleAddComponent = () => {
     setCurrentCardsAmount(currentCardsAmount + 1);
     renderCards()
    };

    const handleCodeChange = (newCode) => {
      console.log(newCode);
      if (props.onCodeChange) {
        props.onCodeChange(newCode);
      }
    };

    const sendCommand = (stackedCode, stackedComponentsName) => {
      
        const newCommand = { text: "combine all components to one component and return the whole combined component", textShort: componentName.slice(0, 20), type:"", adjustment: "Adjust", componentName:stackedComponentsName, cssContent:"", selectedOption:"Combined Components", htmlContent:"", status: "pending", codeIteration:stackedCode, datum: String(new Date()) };
        
        if (props.onCommandChange) {
          props.onCommandChange(newCommand);
        }
        
      
    };
  
    return (
      <Container sx={{ mt: 2 }}>
       {/*  <Typography variant="h4" component="h1" gutterBottom>
          StackerAi
        </Typography> */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" component="h2" gutterBottom>
                Component Selection
              </Typography>
              {renderCards()}
              <Box sx={{ mt: 2 }}>
                <Button variant="contained" onClick={handleAddComponent}>
                  Add
                </Button>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Button variant="contained" onClick={handleCompile}>
                  Compile
                </Button>
                <Button variant="contained" onClick={handleCombine}>
                  Combine Components
                </Button>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" component="h2" gutterBottom>
                Component Tree
              </Typography>
              <List>
                {componentTree.map((component, index) => (
                  <StyledListItem key={index}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={component !== undefined}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': index }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={index}
                      primary={component?.component?.label || '-'}
                    />
                  </StyledListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );
  };
  
  export default ComponentTreeBuilder
  
  /* This component used 1477 tokens in total. Creation costs on GPT4: 0.07536 $ and on GPT3.5Turbo 0.002954 $ */