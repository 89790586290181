import React, { useState } from 'react';
import { Stepper, Step, StepLabel, TextField, Button } from '@mui/material';
import styled from '@emotion/styled';
import CodeMirror from '@uiw/react-codemirror';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const StepContent = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const CodeEditorContainer = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  height: '100%',
});

const PreviewContainer = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: '100%',
});

const PreviewComponent = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #ccc',
});

const CommandTextField = styled(TextField)({
  flex: 1,
  margin: '16px',
});

function HorizontalStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [framework, setFramework] = useState('');
  const [htmlCode, setHtmlCode] = useState('');
  const [commands, setCommands] = useState('');

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChangeFramework = (event) => {
    setFramework(event.target.value);
  };

  const handleChangeHtmlCode = (editor, data, value) => {
    setHtmlCode(value);
  };

  const handleChangeCommands = (event) => {
    setCommands(event.target.value);
  };

  return (
    <Container>
      <Stepper activeStep={activeStep} alternativeLabel>
        <Step>
          <StepLabel>Select Framework and Enter HTML Code</StepLabel>
        </Step>
        <Step>
          <StepLabel>Code Editor and Live Preview</StepLabel>
        </Step>
        <Step>
          <StepLabel>Adjust Component</StepLabel>
        </Step>
      </Stepper>

      {activeStep === 0 && (
        <StepContent>
          <TextField
            label="Framework"
            value={framework}
            onChange={handleChangeFramework}
            variant="outlined"
          />
          <CodeMirror
            value={htmlCode}
            options={{ mode: 'htmlmixed' }}
            onChange={handleChangeHtmlCode}
          />
          <Button onClick={handleNext}>Next</Button>
        </StepContent>
      )}

      {activeStep === 1 && (
        <StepContent>
          <CodeEditorContainer>
            <CodeMirror
              value={htmlCode}
              options={{ mode: 'htmlmixed', lineNumbers: true }}
              onChange={handleChangeHtmlCode}
            />
            <PreviewContainer>
              <PreviewComponent
                dangerouslySetInnerHTML={{ __html: htmlCode }}
              />
              <CommandTextField
                label="Commands"
                value={commands}
                onChange={handleChangeCommands}
                variant="outlined"
                multiline
              />
              <Button onClick={handleNext}>Next</Button>
            </PreviewContainer>
          </CodeEditorContainer>
        </StepContent>
      )}

      {activeStep === 2 && (
        <StepContent>
          <CodeEditorContainer>
            <CodeMirror
              value={htmlCode}
              options={{ mode: 'htmlmixed', lineNumbers: true }}
              onChange={handleChangeHtmlCode}
            />
            <PreviewContainer>
              <PreviewComponent
                dangerouslySetInnerHTML={{ __html: htmlCode }}
              />
              <CommandTextField
                label="Commands"
                value={commands}
                onChange={handleChangeCommands}
                variant="outlined"
                multiline
              />
              <Button onClick={handleBack}>Back</Button>
            </PreviewContainer>
          </CodeEditorContainer>
        </StepContent>
      )}
    </Container>
  );
}

export default HorizontalStepper;