import { FieldGroupIconButton } from "@aws-amplify/ui-react";
import { Button, FormControl, Grid, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import FileCopyIcon from '@mui/icons-material/FileCopy';



const DemoCodeSelector =  (props) => {
    const [selectedCode, setSelectedCode] = useState("");
    const [selectedOption, setSelectedOption] = useState("");
    const list = [
        {no: 1, text: "Countdown Timer", code: "Build a Countdown Time that counts down from 90s to 0s"},
        {no: 2, text: 'TicTacToe Game', code: "Create a TicTacToe Game. The classic game played on a 3x3 grid (500px width) where players take turns to place their chosen symbol (either X or O) and the first one to form a straight line (horizontal, vertical, or diagonal) wins. Always display 3 columns and a reset button to reset the game."},
        {no: 3, text: 'FlipCard', code: "create a card with mouse hoover effect. if the mouse touches the card, the card shall display another card that is displayed in mouse over event. use an animation as if the card turns to the backside"},
    ]

    const copyToClipboard = (ev) => {
        navigator.clipboard.writeText(selectedCode);
      };

      const handlePositionChange = (event) => {
        console.log(event.target.value);
        setSelectedOption(event.target.value);
        let findPosIndex = list.findIndex((pos) => pos.text === event.target.value);
        console.log(findPosIndex);
        console.log(list);
        let code = list[findPosIndex].code;
        setSelectedCode(code);
        };

    return(
        <>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>

        <FormControl fullWidth>
        <Select
          value={selectedOption}
          onChange={handlePositionChange}
          /* disabled={!selectedCluster} */
          displayEmpty
        >
          <MenuItem value="">
            <em>Select command</em>
          </MenuItem>
          {list.map((position) => (
            <MenuItem key={position} value={position.text}>
              {position.text}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
      <FieldGroupIconButton onClick={() => copyToClipboard('')}>
              <FileCopyIcon />
            </FieldGroupIconButton>
            </Grid>
            </Grid>
      
      </>
    )
}

export default DemoCodeSelector;