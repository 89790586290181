import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useState } from "react";
const StyledContainer = styled(Container)({
    display: 'flex',
    flexWrap: 'nowrap',
    margin: 0,
    padding: 0,
    border: 0,
    verticalAlign: 'baseline',
    marginLeft: '-15px',
    marginRight: '-15px',
  });
  
  const StyledPaper = styled(Paper)({
    padding: '1em',
    margin: '1em',
    backgroundColor: '#2d2d2d',
    color: '#f5f5f5',
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: 'blue',
    },
  });
  
  const StyledH2 = styled(Typography)({
    fontSize: '1.5em',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: '1.7em',
  });
  
  const StyledP = styled(Typography)({
    fontSize: '1em',
    letterSpacing: 0,
    lineHeight: '1.7em',
  });
  
  const Patterns = () => {
    const [hovered, setHovered] = useState(false);
  
    const handleHover = () => {
      setHovered(!hovered);
    };
  
    const features = [
      {
        title: 'AI-Powered Code Generation',
        description: 'Streamline the process of redundant codes, reduce errors, and develop at the speed of thought.',
        target: '_blank',
      },
      {
        title: 'Optimized Components',
        description: 'Generate optimized components and variable structures, reducing time spent on manual coding.',
        target: '_self',
      },
      {
        title: 'Performance Improvements',
        description: 'Identify and analyse efficient coding approaches, leading to deeper understanding and faster learning progress.',
        target: '_self',
      },
      {
        title: 'Code Reuse and Adaptation',
        description: 'Boost efficiency by reusing and adapting code components, layouts, and designs, allowing for faster prototyping.',
        target: '_self',
      },
    ];
  
    return (<>
    <Typography style={{margin:'1em', textAlign:"center"}}variant="h4" component="h2" gutterBottom >
            Benefits
          </Typography>
    
      <StyledContainer>
        {features.map((feature) => (
          <Grid item key={feature.title} xs={12} sm={6} md={3}>
            {/* <a href="#" target={feature.target}> */}
              <StyledPaper
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                hovered={hovered}
              >
                <Box>
                  <StyledH2>{feature.title}</StyledH2>
                  <StyledP>{feature.description}</StyledP>
                </Box>
              </StyledPaper>
            {/* </a> */}
          </Grid>
        ))}
      </StyledContainer>
      </>
    );
  };
  
  export default Patterns
  /* This component used 1587 tokens in total. Creation costs on GPT4: 0.07035 $ and on GPT3.5Turbo 0.003174 $ */