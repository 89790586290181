import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useState } from "react";
const StyledSection = styled('section')`
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  --speechify-primary-color: #2137fc;
  --font-family: ABCDiatype;
  --transitions: none;
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.43;
  --google-font-color-materialiconstwotone: none;
  scroll-behavior: auto !important;
  box-sizing: inherit;
  color: #000;
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 80vh;
  min-height: 500px;
  max-height: 1200px;
  
`;
const StyledPaper = styled(Paper)({
  padding: '1em',
  marginTop: '1em',
  backgroundColor: 'red',
  color: '#f5f5f5',
  transition: 'background-color 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: 'blue',

  },
});


const StyledTypography = styled('typography')`
color: white;
align:'center';
`

const BannerNew = (props) => {
  const [hovered, setHovered] = useState(false);
  
  const handleHover = () => {
    setHovered(!hovered);
  };
  /* const imageSrc1 = 'https://mui.com/static/themes/onepirate/productHeroWonder.png'; */
  /* https://reactevolveimg.s3.eu-central-1.amazonaws.com/background2169.jpg */
  let imageSrc2 = 'https://reactevolveimg.s3.eu-central-1.amazonaws.com/background2169.jpg';
  if(props.alternativeImage){
     imageSrc2 = 'background.png';
  }
    
  /*   imageSrc1 = 'https://mui.com/static/themes/onepirate/productHeroWonder.png';
  const imageSrc2 = 'https://mui.com/static/themes/onepirate/productHeroArrowDown.png'; */
    

    {/* <img src="aiListLogo.png" alt="Logo" style={{ width: '50px', marginRight: '1rem' }} /> */}
   

const handleRegister = () => {
  props.openLoginModal();
};


  return (
    <StyledPaper
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}
                hovered={hovered}
              >
            {!props.alternativeImage &&    <img
            style={{ display: 'flex', width: "180px"}}
            src={'hinter.png'}
            alt="React Code Example Library"
          /> }
      <Container /* maxWidth="lg" */>
        <Box 
        style={{ display: 'flex', width: "100%", height: "500px", borderRadius:"15px"}}
        >
          {/* <img src={imageSrc1} alt="wonder" width="147" height="80" /> */}
         {/*  <img
            style={{ display: 'flex', width: "100%"}}
            src={imageSrc2}
            alt="increase priority"
          /> */}
        </Box>
        <Grid container justifyContent="center" alignItems="center" direction="column" >
  <Grid item xs={12}>
        <Box 
        style={{marginTop: '-450px', width: '600px', borderRadius: '10px',
    
    padding: '20px',
    /* opacity: .75, */
     }}  >
        <Typography variant="h2" align="center" style={{color:'white', fontWeight:'bold'}} >
        {props.alternativeImage ? "Speed up your creativity" : "React Code Generator"}
        </Typography>
        <Typography variant="h5" align="center"  style={{color:'yellow'}}>
        {props.alternativeImage ? "Reduce redundant coding tasks by using ReactEvolve™ Library & AISuite" :
        /* "Fast-track your React Development by using ReactEvolve™ AISuite " */
        <>Create React Components <br/> based on your commands 🧙‍♂️</>
        }
        </Typography>
        <Box textAlign="center" pt={2}>
        {!props.alternativeImage && <img
            src="https://mui.com/static/themes/onepirate/productHeroArrowDown.png"
            alt="arrow down"
            style={{width:'20px'}}
          />}
        </Box>
        <Box textAlign="center" pt={2}>
          <Button variant="contained" color="secondary" size="large" onClick={handleRegister}>
            Generate Component
          </Button>
        </Box>
       {/*  <Typography variant="body2" align="center">
          Discover the experience
        </Typography> */}
        <Box mt={4} textAlign="center">
        {props.alternativeImage && 
        <img
            src="https://mui.com/static/themes/onepirate/productHeroArrowDown.png"
            alt="arrow down"
            style={{width:'20px', rotate:'180deg'}}
          />
        }
        
        </Box>
        </Box>
        </Grid>
        </Grid>
        
      </Container>
      </StyledPaper>
    
  );
};

export default BannerNew;
/* This component used 1442 tokens in total. Creation costs on GPT4: 0.06 $ and on GPT3.5Turbo 0.002884 $ */