/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onSalesPitchResponse = /* GraphQL */ `
  subscription OnSalesPitchResponse($id: ID!) {
    onSalesPitchResponse(id: $id) {
      id
      salesPitch {
        id
        userId
        persona
        prompt
        createdAt
        updatedAt
        __typename
      }
      status
      result
      shortDescription
      fiveQuestionsOpenConversation
      fiveArguments
      fiveQuestionsPains
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTodo = /* GraphQL */ `
  subscription OnCreateTodo($filter: ModelSubscriptionTodoFilterInput) {
    onCreateTodo(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTodo = /* GraphQL */ `
  subscription OnUpdateTodo($filter: ModelSubscriptionTodoFilterInput) {
    onUpdateTodo(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTodo = /* GraphQL */ `
  subscription OnDeleteTodo($filter: ModelSubscriptionTodoFilterInput) {
    onDeleteTodo(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSalesPitchRequest = /* GraphQL */ `
  subscription OnCreateSalesPitchRequest(
    $filter: ModelSubscriptionSalesPitchRequestFilterInput
  ) {
    onCreateSalesPitchRequest(filter: $filter) {
      id
      userId
      persona
      prompt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSalesPitchRequest = /* GraphQL */ `
  subscription OnUpdateSalesPitchRequest(
    $filter: ModelSubscriptionSalesPitchRequestFilterInput
  ) {
    onUpdateSalesPitchRequest(filter: $filter) {
      id
      userId
      persona
      prompt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSalesPitchRequest = /* GraphQL */ `
  subscription OnDeleteSalesPitchRequest(
    $filter: ModelSubscriptionSalesPitchRequestFilterInput
  ) {
    onDeleteSalesPitchRequest(filter: $filter) {
      id
      userId
      persona
      prompt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSalesPitchResponse = /* GraphQL */ `
  subscription OnCreateSalesPitchResponse(
    $filter: ModelSubscriptionSalesPitchResponseFilterInput
  ) {
    onCreateSalesPitchResponse(filter: $filter) {
      id
      salesPitch {
        id
        userId
        persona
        prompt
        createdAt
        updatedAt
        __typename
      }
      status
      result
      shortDescription
      fiveQuestionsOpenConversation
      fiveArguments
      fiveQuestionsPains
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSalesPitchResponse = /* GraphQL */ `
  subscription OnUpdateSalesPitchResponse(
    $filter: ModelSubscriptionSalesPitchResponseFilterInput
  ) {
    onUpdateSalesPitchResponse(filter: $filter) {
      id
      salesPitch {
        id
        userId
        persona
        prompt
        createdAt
        updatedAt
        __typename
      }
      status
      result
      shortDescription
      fiveQuestionsOpenConversation
      fiveArguments
      fiveQuestionsPains
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSalesPitchResponse = /* GraphQL */ `
  subscription OnDeleteSalesPitchResponse(
    $filter: ModelSubscriptionSalesPitchResponseFilterInput
  ) {
    onDeleteSalesPitchResponse(filter: $filter) {
      id
      salesPitch {
        id
        userId
        persona
        prompt
        createdAt
        updatedAt
        __typename
      }
      status
      result
      shortDescription
      fiveQuestionsOpenConversation
      fiveArguments
      fiveQuestionsPains
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateComponents = /* GraphQL */ `
  subscription OnCreateComponents(
    $filter: ModelSubscriptionComponentsFilterInput
    $userId: String
  ) {
    onCreateComponents(filter: $filter, userId: $userId) {
      id
      userId
      componentName
      command
      currentCode
      isAdjust
      html
      css
      fullPromptMessages
      status
      openAiFullResponse
      aiResponseFull
      newImports
      newComponent
      isFavorite
      upvotes
      downvotes
      price
      usage
      purchases
      visibility
      isPublic
      responseReceived
      tags
      systemFavorite
      systemRank
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateComponents = /* GraphQL */ `
  subscription OnUpdateComponents(
    $filter: ModelSubscriptionComponentsFilterInput
    $userId: String
  ) {
    onUpdateComponents(filter: $filter, userId: $userId) {
      id
      userId
      componentName
      command
      currentCode
      isAdjust
      html
      css
      fullPromptMessages
      status
      openAiFullResponse
      aiResponseFull
      newImports
      newComponent
      isFavorite
      upvotes
      downvotes
      price
      usage
      purchases
      visibility
      isPublic
      responseReceived
      tags
      systemFavorite
      systemRank
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteComponents = /* GraphQL */ `
  subscription OnDeleteComponents(
    $filter: ModelSubscriptionComponentsFilterInput
    $userId: String
  ) {
    onDeleteComponents(filter: $filter, userId: $userId) {
      id
      userId
      componentName
      command
      currentCode
      isAdjust
      html
      css
      fullPromptMessages
      status
      openAiFullResponse
      aiResponseFull
      newImports
      newComponent
      isFavorite
      upvotes
      downvotes
      price
      usage
      purchases
      visibility
      isPublic
      responseReceived
      tags
      systemFavorite
      systemRank
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser(
    $filter: ModelSubscriptionUserFilterInput
    $userId: String
  ) {
    onCreateUser(filter: $filter, userId: $userId) {
      id
      userId
      name
      credits
      lastPlanChange
      subscriptionLevel
      subscriptionLevelUpdated
      customCompoenentsLeft
      selectedPlan
      maxOwnComponents
      includedCredits
      email
      dateOfPlanSelection
      paymentHistory
      referral
      referredUsers
      birthdate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser(
    $filter: ModelSubscriptionUserFilterInput
    $userId: String
  ) {
    onUpdateUser(filter: $filter, userId: $userId) {
      id
      userId
      name
      credits
      lastPlanChange
      subscriptionLevel
      subscriptionLevelUpdated
      customCompoenentsLeft
      selectedPlan
      maxOwnComponents
      includedCredits
      email
      dateOfPlanSelection
      paymentHistory
      referral
      referredUsers
      birthdate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser(
    $filter: ModelSubscriptionUserFilterInput
    $userId: String
  ) {
    onDeleteUser(filter: $filter, userId: $userId) {
      id
      userId
      name
      credits
      lastPlanChange
      subscriptionLevel
      subscriptionLevelUpdated
      customCompoenentsLeft
      selectedPlan
      maxOwnComponents
      includedCredits
      email
      dateOfPlanSelection
      paymentHistory
      referral
      referredUsers
      birthdate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFeedback = /* GraphQL */ `
  subscription OnCreateFeedback(
    $filter: ModelSubscriptionFeedbackFilterInput
    $userId: String
  ) {
    onCreateFeedback(filter: $filter, userId: $userId) {
      id
      userId
      feedback
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateFeedback = /* GraphQL */ `
  subscription OnUpdateFeedback(
    $filter: ModelSubscriptionFeedbackFilterInput
    $userId: String
  ) {
    onUpdateFeedback(filter: $filter, userId: $userId) {
      id
      userId
      feedback
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteFeedback = /* GraphQL */ `
  subscription OnDeleteFeedback(
    $filter: ModelSubscriptionFeedbackFilterInput
    $userId: String
  ) {
    onDeleteFeedback(filter: $filter, userId: $userId) {
      id
      userId
      feedback
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateReferal = /* GraphQL */ `
  subscription OnCreateReferal($filter: ModelSubscriptionReferalFilterInput) {
    onCreateReferal(filter: $filter) {
      id
      user
      referredUsers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateReferal = /* GraphQL */ `
  subscription OnUpdateReferal($filter: ModelSubscriptionReferalFilterInput) {
    onUpdateReferal(filter: $filter) {
      id
      user
      referredUsers
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteReferal = /* GraphQL */ `
  subscription OnDeleteReferal($filter: ModelSubscriptionReferalFilterInput) {
    onDeleteReferal(filter: $filter) {
      id
      user
      referredUsers
      createdAt
      updatedAt
      __typename
    }
  }
`;
